import * as React from "react";
import { Helmet } from "react-helmet";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { LoginForm } from "../../forms";
import { Layout, Row, Col, Typography } from "antd";
import { ROUTES } from "../../routes";
import { Logo } from "../../components/Logo";

export interface LoginProps extends RouteComponentProps {
  isAuthenticated: boolean;
}

export const Login = withRouter(
  (props: LoginProps): React.ReactElement => {
    const { isAuthenticated, location } = props;
    const { from } = location.state || { from: { pathname: ROUTES.DASHBOARD } };

    const { Content } = Layout;
    const { Title } = Typography;
    const title: string = `Login`;

    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <Layout>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Content style={{ background: "#fff" }}>
          <Row
            type={`flex`}
            align={`middle`}
            justify={`center`}
            style={{ height: `100vh` }}
          >
            <Col xs={{ span: 20 }} sm={{ span: 5 }}>
              <Logo theme={"dark"} height={55} width={300} />
              <LoginForm />
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
);
