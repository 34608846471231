import * as React from "react";
import { Form, Button, DatePicker, Select, InputNumber, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation, QueryHookResult } from "react-apollo-hooks";
import moment, { Moment } from "moment";
import {
  CreateAccommodationReportDocument,
  CreateAccommodationReportMutation,
  CreateAccommodationReportMutationVariables,
  CurrencyDocument,
  CurrencyQuery,
  CurrencyQueryVariables,
  DefaultCurrencyDocument,
  DefaultCurrencyQuery,
  DefaultCurrencyQueryVariables,
  HotelTypesQuery,
  HotelTypesQueryVariables,
  Notification,
  OrdersQuery,
  OrdersQueryVariables
} from "../../graphql/generated";
import { getValidateStatus } from "../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";

export interface HousingReportFormProps extends FormComponentProps {
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
  hotelTypes: QueryHookResult<HotelTypesQuery, HotelTypesQueryVariables>;
}

export const AccommodationReportForm = Form.create<HousingReportFormProps>({
  name: `TravelCostsReportForm`
})(
  (props: HousingReportFormProps): React.ReactElement => {
    const { form, orders, hotelTypes } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;

    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [getCurrency, currency] = useMutation<
      CurrencyQuery,
      CurrencyQueryVariables
    >(CurrencyDocument);

    const [getDefaultCurrency, defaultCurrency] = useMutation<
      DefaultCurrencyQuery,
      DefaultCurrencyQueryVariables
    >(DefaultCurrencyDocument);

    const [submit] = useMutation<
      CreateAccommodationReportMutation,
      CreateAccommodationReportMutationVariables
    >(CreateAccommodationReportDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateAccommodationReportMutationVariables = {
            input: {
              date: values.date.unix(),
              currency: { id: values.currency },
              invoiceNo: values.invoiceNo,
              order: { id: values.order },
              priceIncVAT: values.priceIncVAT,
              hotelType: { id: values.hotelType },
              nightsCount: values.nightsCount,
              personsCount: values.personsCount
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (
            response &&
            response.data &&
            response.data.createAccommodationReport
          ) {
            response.data.createAccommodationReport.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY`}
              onChange={async (date: Moment | null) => {
                if (date) {
                  orders.refetch({ date: date.unix() });
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order", {
            rules: [
              {
                required: true,
                message: `Vyberte zakázku`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
              onChange={async (id: number) => {
                await getCurrency({ variables: { order: { id } } });
                await getDefaultCurrency({ variables: { order: { id } } });
              }}
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Typ hotelu`}>
          {getFieldDecorator("hotelType", {
            rules: [
              {
                required: true,
                message: `Vyberte typ hotelu`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte typ hotelu`}
              loading={hotelTypes.loading}
              disabled={
                !(hotelTypes.data && hotelTypes.data.hotelTypes.length > 0)
              }
            >
              {hotelTypes.data &&
                hotelTypes.data.hotelTypes.map(hotelType => (
                  <Option key={hotelType.id} value={hotelType.id}>
                    {hotelType.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Počet nocí">
          {getFieldDecorator("nightsCount", {
            rules: [
              {
                required: true,
                message: `Zadejte počet nocí`
              }
            ]
          })(<InputNumber min={1} precision={0} />)}
        </Form.Item>

        <Form.Item label="Počet osob">
          {getFieldDecorator("personsCount", {
            rules: [
              {
                required: true,
                message: `Zadejte počet osob`
              }
            ]
          })(<InputNumber min={1} precision={0} />)}
        </Form.Item>

        <Form.Item label="Číslo faktury">
          {getFieldDecorator("invoiceNo", {
            rules: [
              {
                required: true,
                message: `Zadejte číslo faktury`
              },
              {
                validator: (rule, value, callback) => {
                  if (
                    value &&
                    !(!isNaN(value) && value.toString().length <= 10)
                  ) {
                    callback("Zadejte maximálně 10 místné číslo");
                  } else {
                    callback();
                  }
                }
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Měna`}>
          {getFieldDecorator("currency", {
            initialValue:
              getFieldValue(`order`) &&
              defaultCurrency.data &&
              defaultCurrency.data.defaultCurrency.id,
            rules: [
              {
                required: true,
                message: `Vyberte měnu`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte měnu`}
              loading={currency.loading}
              disabled={
                !(currency.data && currency.data.currency.length > 0) ||
                !getFieldValue(`order`)
              }
            >
              {currency.data &&
                currency.data.currency.map(currency => (
                  <Option key={currency.id} value={currency.id}>
                    {currency.codeName}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Částka vč. DPH">
          {getFieldDecorator("priceIncVAT", {
            rules: [
              {
                required: true,
                message: `Zadejte částku`
              }
            ]
          })(<InputNumber min={0} decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);

// TODO: submit
