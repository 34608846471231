import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables,
  WeatherDocument,
  WeatherQuery,
  WeatherQueryVariables
} from "../../graphql/generated";
import moment from "moment";
import { DailyReportCreateForm } from "../../forms";
export interface DailyReportProps {}

export const DailyReport: React.FC<DailyReportProps> = (
  props: DailyReportProps
): React.ReactElement => {
  const title: string = `Denní výkaz`;
  const { Title } = Typography;
  const [date, setDate] = React.useState<number>(moment().unix());

  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    variables: { date }
  });

  const weather = useQuery<WeatherQuery, WeatherQueryVariables>(
    WeatherDocument
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <DailyReportCreateForm orders={orders} weather={weather} />
          </Col>
        </Row>
      </div>
    </>
  );
};
