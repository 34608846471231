import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Divider, Row, Typography, List, Avatar, Tooltip, Button, Icon, Skeleton } from "antd";
import { CreateTaskForm } from "../../forms";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  DeleteTaskDocument,
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables,
  Notification,
  Task,
  TasksDocument,
  TasksQuery,
  TasksQueryVariables,
  UpdateTaskDocument,
  UpdateTaskMutation,
  UpdateTaskMutationVariables
} from "../../graphql/generated";
import { PopConfirm } from "../../components";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts";
import { createMarkup, linkify } from "../../utils";

interface TaskRow {
  key: number;
  employee: string;
  description: string;
  completed: boolean;
  data: Task;
}

export interface TasksProps {}

export const Tasks: React.FC<TasksProps> = (
  props: TasksProps
): React.ReactElement => {
  const title: string = `Seznam úkolů`;
  const { Title } = Typography;

  const tasks = useQuery<TasksQuery, TasksQueryVariables>(
    TasksDocument
  );

  // const employees = useQuery<EmployeesQuery, EmployeesQueryVariables>(
  //   EmployeesDocument
  // );

  const dataSource: TaskRow[] = [];

  if (tasks.data) {
    tasks.data.tasks.map((item, key) =>
      dataSource.push({
        key,
        // key: item.id,
        employee: item.employee.fullName,
        description: item.description,
        completed: item.isCompleted,
        data: item as Task
      })
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <CreateTaskForm
              onTaskCreate={() => {
                tasks.refetch();
              }}
              // employees={employees}
            />
          </Col>
        </Row>
        <Divider />
        {tasks.loading ? (
          <>
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
          </>
        ) : (
          <TasksTableView
            dataSource={dataSource}
          />
        )}
      </div>
    </>
  );
};

const TasksTableView: React.FC<{
  dataSource: TaskRow[];
}> = props => {
  const { dataSource } = props;

  return (
    <>
      <Row>
        <Col>
          <TasksTable
            dataSource={dataSource}
          />
        </Col>
      </Row>
    </>
  );
};

const TasksTable: React.FC<{
  dataSource: TaskRow[];
}> = props => {
  const { dataSource } = props;
  const [data, setData] = React.useState<TaskRow[]>(dataSource);

  const { Text } = Typography;

  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  const [updateTask] = useMutation<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >(UpdateTaskDocument)

  const [deleteTask] = useMutation<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >(DeleteTaskDocument)

  function Linkify(props: { value: string }) {
    return <div dangerouslySetInnerHTML={createMarkup(linkify(props.value))} />;
  }

  return (
    <List
      dataSource={data}
      renderItem={item => (
        <List.Item
          actions={[
            <Tooltip trigger={`hover`} title={item.completed ? `Označit jako nedokončené ` : `Označit jako dokončené`}>
              <Button
                type={"primary"}
                onClick={async () => {
                  const response = await updateTask({
                    variables: {
                      input: { id: item.data.id, description: item.description, isCompleted: !item.completed }
                    }
                  });

                  if (
                    response &&
                    response.data &&
                    response.data.updateTask
                  ) {
                    if (response.data.updateTask.status) {
                      data[item.key].completed = !item.completed;
                      setData(data);
                      window.location.reload(); // TODO: fixme
                    }

                    response.data.updateTask.notifications.map(
                      (notification: Notification) =>
                        pushNotification(notification)
                    );
                  }
                }}
                size={`small`}
              >
                <Icon type={item.completed ? "close" : "check"} />
              </Button>
            </Tooltip>,
            <PopConfirm
              onConfirm={async () => {
                const response = await deleteTask({
                  variables: {
                    input: { id: item.data.id }
                  }
                });

                if (
                  response &&
                  response.data &&
                  response.data.deleteTask
                ) {
                  if (response.data.deleteTask.status) {
                    data.splice(item.key, 1)
                    setData(data);
                    window.location.reload(); // TODO: fixme
                  }

                  response.data.deleteTask.notifications.map(
                    (notification: Notification) =>
                      pushNotification(notification)
                  );
                }
              }}
            >
              <Tooltip trigger={`hover`} title={`Odstranit`}>
                <Button type={"danger"} size={`small`}>
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </PopConfirm>
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar size="large" icon="user" />}
            title={item.employee}
            description={
              <Text delete={item.completed}>
                <Linkify value={item.description} />
              </Text>
            }
          />
        </List.Item>
      )}
    >
    </List>
  );
};
