import * as React from "react";
import { Layout, Typography } from "antd";
import moment from "moment";

export const Footer: React.FC = (): React.ReactElement => {
  const { Footer } = Layout;
  const { Text } = Typography;
  return (
    <Footer style={{ textAlign: "center" }}>
      <Text>Adam Epox ©{moment().year()}</Text>
    </Footer>
  );
};
