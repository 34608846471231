import * as React from "react";
import { Form, Button, Select, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation, QueryHookResult } from "react-apollo-hooks";
import {
  CreateTravelOrderDocument,
  CreateTravelOrderMutation,
  CreateTravelOrderMutationVariables,
  DefaultTravelOrderPurposeQuery,
  DefaultTravelOrderPurposeQueryVariables,
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables,
  Notification,
  OrdersQuery,
  OrdersQueryVariables,
  VehiclesDocument,
  VehiclesQuery,
  VehiclesQueryVariables
} from "../../../graphql/generated";
import { getValidateStatus } from "../../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../../contexts/NotificationContext";

export interface TravelOrderCreateFormProps extends FormComponentProps {
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
  defaultTravelOrderPurposeDocument: QueryHookResult<
    DefaultTravelOrderPurposeQuery,
    DefaultTravelOrderPurposeQueryVariables
  >;
}

export const TravelOrderCreateForm = Form.create<TravelOrderCreateFormProps>({
  name: `TravelOrderCreateForm`
})(
  (props: TravelOrderCreateFormProps): React.ReactElement => {
    const { form, orders, defaultTravelOrderPurposeDocument } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      resetFields
    } = form;
    const { Option } = Select;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [getEmployees, employees] = useMutation<
      EmployeesQuery,
      EmployeesQueryVariables
    >(EmployeesDocument);

    const [getVehicles, vehicles] = useMutation<
      VehiclesQuery,
      VehiclesQueryVariables
    >(VehiclesDocument);

    const [submit] = useMutation<
      CreateTravelOrderMutation,
      CreateTravelOrderMutationVariables
    >(CreateTravelOrderDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateTravelOrderMutationVariables = {
            input: {
              order: { id: values.order },
              employee: { id: values.employee },
              passengers: values.passengers.map((id: number) => ({ id })),
              vehicle: { id: values.vehicle },
              purpose: values.purpose
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createTravelOrder) {
            response.data.createTravelOrder.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order", {
            rules: [
              {
                required: true,
                message: `Vyberte zakázku`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
              onChange={async (id: number) => {
                await getEmployees({ variables: { orderId: id } });
                await getVehicles({ variables: { order: { id } } });
              }}
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Zaměstnanec`}>
          {getFieldDecorator("employee", {
            rules: [
              {
                required: true,
                message: `Vyberte zaměstnance`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zaměstnance`}
              loading={employees.loading}
              disabled={
                !(employees.data && employees.data.employees.length > 0) ||
                !getFieldValue(`order`)
              }
            >
              {employees.data &&
                employees.data.employees
                  .filter(employee => {
                    const passengersField: number[] = getFieldValue(
                      `passengers`
                    );
                    if (passengersField) {
                      return !passengersField.includes(employee.id);
                    }

                    return true;
                  })
                  .map(employee => (
                    <Option key={employee.id} value={employee.id}>
                      {employee.fullName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Spolucestující`}>
          {getFieldDecorator("passengers")(
            <Select
              mode={`multiple`}
              placeholder={`Vyberte spolucestující`}
              loading={employees.loading}
              disabled={
                !(employees.data && employees.data.employees.length > 0) ||
                !getFieldValue(`order`)
              }
            >
              {employees.data &&
                employees.data.employees
                  .filter(employee => {
                    const employeeField: number = getFieldValue(`employee`);
                    return employeeField !== employee.id;
                  })
                  .map(employee => (
                    <Option key={employee.id} value={employee.id}>
                      {employee.fullName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Vozidlo`}>
          {getFieldDecorator("vehicle", {
            rules: [
              {
                required: true,
                message: `Vyberte vozidlo`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte vozidlo`}
              loading={vehicles.loading}
              disabled={
                !(vehicles.data && vehicles.data.vehicles.length > 0) ||
                !getFieldValue(`order`)
              }
            >
              {vehicles.data &&
                vehicles.data.vehicles.map(vehicle => (
                  <Option key={vehicle.id} value={vehicle.id}>
                    {vehicle.codeName}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Účel a průběh cesty`}>
          {getFieldDecorator("purpose", {
            initialValue:
              defaultTravelOrderPurposeDocument.data &&
              defaultTravelOrderPurposeDocument.data.defaultTravelOrderPurpose,
            rules: [
              {
                required: true,
                message: `Zadejte účel a průběh cesty`
              }
            ]
          })(<Input disabled={defaultTravelOrderPurposeDocument.loading}/>)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
