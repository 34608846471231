import * as React from "react";
import { Form, Button, DatePicker, Select, InputNumber } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation, QueryHookResult } from "react-apollo-hooks";
import moment from "moment";
import {
  CreatePrepaymentDocument,
  CreatePrepaymentMutation,
  CreatePrepaymentMutationVariables,
  CurrencyQuery,
  CurrencyQueryVariables,
  EmployeesQuery,
  EmployeesQueryVariables,
  MaxPrepaymentAmountDocument,
  MaxPrepaymentAmountQuery,
  MaxPrepaymentAmountQueryVariables,
  Notification
} from "../../graphql/generated";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";
import { getValidateStatus } from "../../utils";

export interface PrepaymentFormProps extends FormComponentProps {
  employees: QueryHookResult<EmployeesQuery, EmployeesQueryVariables>;
  currency: QueryHookResult<CurrencyQuery, CurrencyQueryVariables>;
}

// TODO: rename to CreatePrepaymentForm (like CreatePenaltyForm)
export const PrepaymentForm = Form.create<PrepaymentFormProps>({
  name: `PrepaymentFormProps`
})(
  (props: PrepaymentFormProps): React.ReactElement => {
    const { form, employees, currency } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [getMaxPaymentAmount, maxPaymentAmount] = useMutation<
      MaxPrepaymentAmountQuery,
      MaxPrepaymentAmountQueryVariables
    >(MaxPrepaymentAmountDocument);

    const [submit] = useMutation<
      CreatePrepaymentMutation,
      CreatePrepaymentMutationVariables
    >(CreatePrepaymentDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreatePrepaymentMutationVariables = {
            input: {
              date: values.date.unix(),
              employee: { id: values.employee },
              currency: { id: values.currency },
              prepaymentAmount: values.prepaymentAmount
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createPrepayment) {
            response.data.createPrepayment.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(<DatePicker format={`DD.MM.YYYY`} />)}
        </Form.Item>

        <Form.Item label={`Zaměstnanec`}>
          {getFieldDecorator("employee", {
            rules: [
              {
                required: true,
                message: `Vyberte zaměstnance`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zaměstnance`}
              loading={employees.loading}
              disabled={
                !(employees.data && employees.data.employees.length > 0)
              }
              onChange={async (id: number) => {
                const currency = getFieldValue(`currency`);
                if (currency) {
                  await getMaxPaymentAmount({
                    variables: { employee: { id }, currency: { id: currency } }
                  });
                }
              }}
            >
              {employees.data &&
                employees.data.employees
                  .filter(employee => {
                    const passengersField: number[] = getFieldValue(
                      `passengers`
                    );
                    if (passengersField) {
                      return !passengersField.includes(employee.id);
                    }

                    return true;
                  })
                  .map(employee => (
                    <Option key={employee.id} value={employee.id}>
                      {employee.fullName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Měna`}>
          {getFieldDecorator("currency", {
            rules: [
              {
                required: true,
                message: `Vyberte měnu`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte měnu`}
              loading={currency.loading}
              disabled={!(currency.data && currency.data.currency.length > 0)}
              onChange={async (id: number) => {
                const employee = getFieldValue(`employee`);
                if (employee) {
                  await getMaxPaymentAmount({
                    variables: { employee: { id: employee }, currency: { id } }
                  });
                }
              }}
            >
              {currency.data &&
                  // TODO: refaktorovat, aby se měna vybírala na backendu
                  // TODO: pokud bude 1, tak ji vybrat jako default
                currency.data.currency.filter(currency => {
                  return currency.id == 1;
                }).map(currency => (
                  <Option key={currency.id} value={currency.id}>
                    {currency.codeName}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Výše vyplacené zálohy">
          {getFieldDecorator("prepaymentAmount", {
            rules: [
              {
                required: true,
                message: `Zadejte výši vyplacené zálohy`
              }
            ]
          })(
            <InputNumber
              min={0}
              decimalSeparator={`,`}
              precision={2}
              disabled={!maxPaymentAmount.data}
            />
          )}
          {maxPaymentAmount.data &&
          maxPaymentAmount.data.maxPrepaymentAmount &&
          currency.data &&
          getFieldValue(`currency`)
            ? ` (maximálně ${maxPaymentAmount.data.maxPrepaymentAmount} ${currency.data.currency[currency.data.currency.map(item => item.id).indexOf(getFieldValue(`currency`))].code})`
            : ``}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);

// TODO: submit
