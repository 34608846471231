import * as React from "react";
import { Form, Button, DatePicker, Select, InputNumber, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation, QueryHookResult } from "react-apollo-hooks";
import moment, { Moment } from "moment";
import {
  CreateTravelCostReportDocument,
  CreateTravelCostReportMutation,
  CreateTravelCostReportMutationVariables,
  CurrencyDocument,
  CurrencyQuery,
  CurrencyQueryVariables,
  DefaultCurrencyDocument,
  DefaultCurrencyQuery,
  DefaultCurrencyQueryVariables,
  Notification,
  OrdersQuery,
  OrdersQueryVariables,
  TravelCostSupplierGroupsQuery,
  TravelCostSupplierGroupsQueryVariables,
  TravelCostTypesQuery,
  TravelCostTypesQueryVariables
} from "../../graphql/generated";
import { getValidateStatus } from "../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";

export interface TravelCostReportFormProps extends FormComponentProps {
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
  travelCostTypes: QueryHookResult<
    TravelCostTypesQuery,
    TravelCostTypesQueryVariables
  >;
  travelCostSupplierGroups: QueryHookResult<
    TravelCostSupplierGroupsQuery,
    TravelCostSupplierGroupsQueryVariables
  >;
}

export const TravelCostReportForm = Form.create<TravelCostReportFormProps>({
  name: `TravelCostReportForm`
})(
  (props: TravelCostReportFormProps): React.ReactElement => {
    const { form, orders, travelCostTypes, travelCostSupplierGroups } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option, OptGroup } = Select;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [getCurrency, currency] = useMutation<
      CurrencyQuery,
      CurrencyQueryVariables
    >(CurrencyDocument);

    const [getDefaultCurrency, defaultCurrency] = useMutation<
      DefaultCurrencyQuery,
      DefaultCurrencyQueryVariables
    >(DefaultCurrencyDocument);

    const [submit] = useMutation<
      CreateTravelCostReportMutation,
      CreateTravelCostReportMutationVariables
    >(CreateTravelCostReportDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateTravelCostReportMutationVariables = {
            input: {
              date: values.date.unix(),
              order: { id: values.order },
              currency: { id: values.currency },
              travelCostType: { id: values.travelCostType },
              invoiceNo: values.invoiceNo,
              priceIncVAT: values.priceIncVAT,
              travelCostSupplier: { id: values.travelCostSupplier }
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (
            response &&
            response.data &&
            response.data.createTravelCostReport
          ) {
            response.data.createTravelCostReport.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY`}
              onChange={async (date: Moment | null) => {
                if (date) {
                  orders.refetch({ date: date.unix() });
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order", {
            rules: [
              {
                required: true,
                message: `Vyberte zakázku`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
              onChange={async (id: number) => {
                await getCurrency({ variables: { order: { id } } });
                await getDefaultCurrency({ variables: { order: { id } } });
              }}
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Typ`}>
          {getFieldDecorator("travelCostType", {
            rules: [
              {
                required: true,
                message: `Vyberte typ`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte typ`}
              loading={travelCostTypes.loading}
              disabled={
                !(
                  travelCostTypes.data &&
                  travelCostTypes.data.travelCostTypes.length > 0
                )
              }
            >
              {travelCostTypes.data &&
                travelCostTypes.data.travelCostTypes.map(travelCostType => (
                  <Option key={travelCostType.id} value={travelCostType.id}>
                    {travelCostType.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Dodavatel`}>
          {getFieldDecorator("travelCostSupplier", {
            rules: [
              {
                required: true,
                message: `Vyberte dodavatele`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte dodavatele`}
              loading={travelCostSupplierGroups.loading}
              disabled={
                !(
                  travelCostSupplierGroups.data &&
                  travelCostSupplierGroups.data.travelCostSupplierGroups
                    .length > 0
                )
              }
            >
              {travelCostSupplierGroups.data &&
                travelCostSupplierGroups.data.travelCostSupplierGroups.map(
                  group => (
                    <OptGroup key={group.id} label={group.name}>
                      {group.travelCostSuppliers.map(supplier => (
                        <Option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )
                )}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Číslo faktury">
          {getFieldDecorator("invoiceNo", {
            rules: [
              {
                required: true,
                message: `Zadejte číslo faktury`
              },
              {
                validator: (rule, value, callback) => {
                  if (
                    value &&
                    !(!isNaN(value) && value.toString().length <= 10)
                  ) {
                    callback("Zadejte maximálně 10 místné číslo");
                  } else {
                    callback();
                  }
                }
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Měna`}>
          {getFieldDecorator("currency", {
            initialValue:
              getFieldValue(`order`) &&
              defaultCurrency.data &&
              defaultCurrency.data.defaultCurrency.id,
            rules: [
              {
                required: true,
                message: `Vyberte měnu`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte měnu`}
              loading={currency.loading}
              disabled={
                !(currency.data && currency.data.currency.length > 0) ||
                !getFieldValue(`order`)
              }
            >
              {currency.data &&
                currency.data.currency.map(currency => (
                  <Option key={currency.id} value={currency.id}>
                    {currency.codeName}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Částka vč. DPH">
          {getFieldDecorator("priceIncVAT", {
            rules: [
              {
                required: true,
                message: `Zadejte částku`
              }
            ]
          })(<InputNumber min={0} decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);

// TODO: submit
