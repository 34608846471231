import * as React from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Divider, Icon, Row, Skeleton, Table, Tooltip, Typography } from "antd";
import { PrepaymentsFilterForm } from "../../../forms";
import { useQuery } from "react-apollo-hooks";
import { PopConfirm } from "../../../components";
import {
  Prepayment,
  PrepaymentsDocument,
  PrepaymentsQuery,
  PrepaymentsQueryVariables
} from "../../../graphql/generated";
import moment, { Moment } from "moment";

interface PrepaymentRow {
  key: number;
  employee: string;
  amount: string;
  data: Prepayment;
}

export interface PrepaymentsProps {}

// TODO: ListPrepaymentsView? a přesunout jinam
export const ListPrepaymentsView: React.FC<PrepaymentsProps> = (
  props: PrepaymentsProps
): React.ReactElement => {
  const title: string = `Výpis záloh`;
  const { Title } = Typography;

  const [date, setDate] = React.useState<number>(moment().unix());

  const prepayments = useQuery<
    PrepaymentsQuery,
    PrepaymentsQueryVariables
  >(PrepaymentsDocument, {
    variables: { date: date },
    fetchPolicy: `no-cache`
  });

  const dataSource: PrepaymentRow[] = [];

  if (prepayments.data) {
    prepayments.data.prepayments.map((item, key) =>
      dataSource.push({
        // key,
        key: item.employee.id, // TODO: use ID from prepayments
        employee: item.employee.fullName,
        amount: `${item.amount} ${item.currency.code}`, // TODO: potom odebrat nepotřebné atributy z graphql query
        data: item as Prepayment
      })
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <PrepaymentsFilterForm
              onDateChange={(date: Moment | null) => {
                if (date) {
                  setDate(date.unix());
                  prepayments.refetch({ date: date.unix() });
                }
              }}
            />
          </Col>
        </Row>
        {prepayments.loading ? (
          <>
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
          </>
        ) : (
          <PrepaymentsTableView
            dataSource={dataSource}
          />
        )}
      </div>
    </>
  );
};

const PrepaymentsTableView: React.FC<{
  dataSource: PrepaymentRow[];
}> = props => {
  const { dataSource } = props;

  return (
    <>
      <Row>
        <Col>
          <PrepaymentsTable
            dataSource={dataSource}
          />
        </Col>
      </Row>
    </>
  );
};

const PrepaymentsTable: React.FC<{
  dataSource: PrepaymentRow[];
}> = props => {
  const { dataSource } = props;
  const [data, setData] = React.useState<PrepaymentRow[]>(dataSource);

  const columns = [
    {
      title: "Zaměstnanec",
      dataIndex: "employee",
      id: "employee"
    },
    {
      title: "Výše zálohy",
      dataIndex: "amount",
      id: "amount"
    },
    {
      title: "Akce",
      key: "action",
      align: `center` as "center",
      render: (record: Prepayment) => { // PrepaymentRow?
        return (
          <span>
            {/*TODO: <span></span> => <></> */}
            <PopConfirm
              onConfirm={async () => {}}
            >
              <Tooltip trigger={`hover`} title={`Odstranit`}>
                <Button type={"danger"} size={`small`}>
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </PopConfirm>
          </span>
        );
      }
    }
  ];

  return <Table dataSource={data} columns={columns} scroll={{ x: true }} />;
};
