import * as React from "react";
import {
  Row,
  Col,
  Typography,
  Skeleton,
  Divider,
  Table,
  Tooltip,
  Button,
  Icon,
  Descriptions
} from "antd";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  NotificationContext,
  NotificationContextState
} from "../../../contexts/NotificationContext";
import {
  DailyReport,
  DailyReportDocument,
  DailyReportQuery,
  DailyReportQueryVariables,
  DeleteAccommodationReportDocument,
  DeleteAccommodationReportMutation,
  DeleteAccommodationReportMutationVariables,
  DeleteMaterialReportDocument,
  DeleteMaterialReportMutation,
  DeleteMaterialReportMutationVariables,
  DeleteTravelCostReportDocument,
  DeleteTravelCostReportMutation,
  DeleteTravelCostReportMutationVariables,
  DeleteWorkDescriptionReportDocument,
  DeleteWorkDescriptionReportMutation,
  DeleteWorkDescriptionReportMutationVariables,
  Notification
} from "../../../graphql/generated";
import { getHoursAndMinutesFromNumber } from "../../../utils";
import { PopConfirm } from "../../../components/PopConfirm";

export interface DailyReportEditFormProps {
  dailyReport: DailyReport;
  date: number;
}

interface MaterialReportRow {
  id: number;
  materialSupplier: string;
  invoiceNo: string;
  priceIncVAT: string;
}

interface TravelCostReportRow {
  id: number;
  travelCostType: string;
  travelCostSupplier: string;
  invoiceNo: string;
  priceIncVAT: string;
}

interface AccommodationReportRow {
  id: number;
  hotelType: string;
  nightsCount: number;
  personsCount: number;
  invoiceNo: string;
  priceIncVAT: string;
}

interface WorkDescriptionReportRow {
  id: number;
  description: string;
}

export const DailyReportEditForm: React.FC<DailyReportEditFormProps> = (
  props: DailyReportEditFormProps
): React.ReactElement => {
  const { dailyReport, date } = props;
  const { Text, Title } = Typography;
  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );
  let materialReport:
    | { dataSource: MaterialReportRow[]; columns: any[] }
    | undefined;
  let travelCostReports:
    | { dataSource: TravelCostReportRow[]; columns: any[] }
    | undefined;
  let accommodationReports:
    | { dataSource: AccommodationReportRow[]; columns: any[] }
    | undefined;
  let workDescriptionReports:
    | { dataSource: WorkDescriptionReportRow[]; columns: any[] }
    | undefined;

  const { data, loading, refetch } = useQuery<
    DailyReportQuery,
    DailyReportQueryVariables
  >(DailyReportDocument, {
    variables: { date, order: { id: dailyReport.order.id } }
  });

  const [deleteMaterialReport] = useMutation<
    DeleteMaterialReportMutation,
    DeleteMaterialReportMutationVariables
  >(DeleteMaterialReportDocument);

  const [deleteTravelCostReport] = useMutation<
    DeleteTravelCostReportMutation,
    DeleteTravelCostReportMutationVariables
  >(DeleteTravelCostReportDocument);

  const [deleteAccommodationReport] = useMutation<
    DeleteAccommodationReportMutation,
    DeleteAccommodationReportMutationVariables
  >(DeleteAccommodationReportDocument);

  const [deleteWorkDescriptionReport] = useMutation<
    DeleteWorkDescriptionReportMutation,
    DeleteWorkDescriptionReportMutationVariables
  >(DeleteWorkDescriptionReportDocument);

  if (data && data.dailyReport) {
    materialReport = {
      dataSource: data.dailyReport.materialReports.map(item => ({
        id: item.id,
        materialSupplier: item.materialSupplier.name,
        invoiceNo: item.invoiceNo,
        priceIncVAT: `${item.priceIncVAT} ${item.currency.code}`
      })),
      columns: [
        {
          title: "Dodavatel",
          dataIndex: "materialSupplier",
          id: "materialSupplier"
        },
        {
          title: "Číslo faktury",
          dataIndex: "invoiceNo",
          id: "invoiceNo"
        },
        {
          title: "Částka vč. DPH",
          dataIndex: "priceIncVAT",
          id: "priceIncVAT"
        },
        {
          title: "Akce",
          key: "action",
          align: `right`,
          className: `column__text--right`,
          render: (text: string, record: any) => {
            return (
              <span>
                <PopConfirm
                  onConfirm={async () => {
                    const response = await deleteMaterialReport({
                      variables: {
                        input: {
                          id: record.id
                        }
                      }
                    });

                    if (
                      response &&
                      response.data &&
                      response.data.deleteMaterialReport
                    ) {
                      if (response.data.deleteMaterialReport.status) {
                        refetch();
                      }

                      response.data.deleteMaterialReport.notifications.map(
                        (notification: Notification) =>
                          pushNotification(notification)
                      );
                    }
                  }}
                >
                  <Tooltip trigger={`hover`} title={`Odstranit`}>
                    <Button type={"danger"} size={`small`}>
                      <Icon type="delete" />
                    </Button>
                  </Tooltip>
                </PopConfirm>
              </span>
            );
          }
        }
      ]
    };
    travelCostReports = {
      dataSource: data.dailyReport.travelCostReports.map(item => ({
        id: item.id,
        travelCostType: item.travelCostType.name,
        travelCostSupplier: item.travelCostSupplier.name,
        invoiceNo: item.invoiceNo,
        priceIncVAT: `${item.priceIncVAT} ${item.currency.code}`
      })),
      columns: [
        {
          title: "Typ",
          dataIndex: "travelCostType",
          id: "travelCostType"
        },
        {
          title: "Dodavatel",
          dataIndex: "travelCostSupplier",
          id: "travelCostSupplier"
        },
        {
          title: "Číslo faktury",
          dataIndex: "invoiceNo",
          id: "invoiceNo"
        },
        {
          title: "Částka vč. DPH",
          dataIndex: "priceIncVAT",
          id: "priceIncVAT"
        },
        {
          title: "Akce",
          key: "action",
          align: `right`,
          className: `column__text--right`,
          render: (text: string, record: any) => {
            return (
              <span>
                <PopConfirm
                  onConfirm={async () => {
                    const response = await deleteTravelCostReport({
                      variables: {
                        input: {
                          id: record.id
                        }
                      }
                    });

                    if (
                      response &&
                      response.data &&
                      response.data.deleteTravelCostReport
                    ) {
                      if (response.data.deleteTravelCostReport.status) {
                        refetch();
                      }

                      response.data.deleteTravelCostReport.notifications.map(
                        (notification: Notification) =>
                          pushNotification(notification)
                      );
                    }
                  }}
                >
                  <Tooltip trigger={`hover`} title={`Odstranit`}>
                    <Button type={"danger"} size={`small`}>
                      <Icon type="delete" />
                    </Button>
                  </Tooltip>
                </PopConfirm>
              </span>
            );
          }
        }
      ]
    };
    accommodationReports = {
      dataSource: data.dailyReport.accommodationReports.map(item => ({
        id: item.id,
        hotelType: item.hotelType.name,
        nightsCount: item.nightsCount,
        personsCount: item.personsCount,
        invoiceNo: item.invoiceNo,
        priceIncVAT: `${item.priceIncVAT} ${item.currency.code}`
      })),
      columns: [
        {
          title: "Typ hotelu",
          dataIndex: "hotelType",
          id: "hotelType"
        },
        {
          title: "Počet nocí",
          dataIndex: "nightsCount",
          id: "nightsCount"
        },
        {
          title: "Počet osob",
          dataIndex: "personsCount",
          id: "personsCount"
        },
        {
          title: "Číslo faktury",
          dataIndex: "invoiceNo",
          id: "invoiceNo"
        },
        {
          title: "Částka vč. DPH",
          dataIndex: "priceIncVAT",
          id: "priceIncVAT"
        },
        {
          title: "Akce",
          key: "action",
          align: `right`,
          className: `column__text--right`,
          render: (text: string, record: any) => {
            return (
              <span>
                <PopConfirm
                  onConfirm={async () => {
                    const response = await deleteAccommodationReport({
                      variables: {
                        input: {
                          id: record.id
                        }
                      }
                    });

                    if (
                      response &&
                      response.data &&
                      response.data.deleteAccommodationReport
                    ) {
                      if (response.data.deleteAccommodationReport.status) {
                        refetch();
                      }

                      response.data.deleteAccommodationReport.notifications.map(
                        (notification: Notification) =>
                          pushNotification(notification)
                      );
                    }
                  }}
                >
                  <Tooltip trigger={`hover`} title={`Odstranit`}>
                    <Button type={"danger"} size={`small`}>
                      <Icon type="delete" />
                    </Button>
                  </Tooltip>
                </PopConfirm>
              </span>
            );
          }
        }
      ]
    };
    workDescriptionReports = {
      dataSource: data.dailyReport.workDescriptionReports.map(item => ({
        id: item.id,
        description: item.description
      })),
      columns: [
        {
          title: "Popis",
          dataIndex: "description",
          id: "description"
        },
        {
          title: "Akce",
          key: "action",
          align: `right`,
          className: `column__text--right`,
          render: (text: string, record: any) => {
            return (
              <span>
                <PopConfirm
                  onConfirm={async () => {
                    const response = await deleteWorkDescriptionReport({
                      variables: {
                        input: {
                          id: record.id
                        }
                      }
                    });

                    if (
                      response &&
                      response.data &&
                      response.data.deleteWorkDescriptionReport
                    ) {
                      if (response.data.deleteWorkDescriptionReport.status) {
                        refetch();
                      }

                      response.data.deleteWorkDescriptionReport.notifications.map(
                        (notification: Notification) =>
                          pushNotification(notification)
                      );
                    }
                  }}
                >
                  <Tooltip trigger={`hover`} title={`Odstranit`}>
                    <Button type={"danger"} size={`small`}>
                      <Icon type="delete" />
                    </Button>
                  </Tooltip>
                </PopConfirm>
              </span>
            );
          }
        }
      ]
    };
  }

  return (
    <>
      <>
        <Row>
          <Col>
            <Descriptions column={{ lg: 4, xs: 2 }}>
              <Descriptions.Item label={<Text strong>{`Zakázka`}</Text>}>
                <Text>{dailyReport.order.name}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Teplota`}</Text>}>
                <Text>{`${dailyReport.temperature} °C`}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Počasí`}</Text>}>
                <Text>{dailyReport.weather.name}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Počet lidí`}</Text>}>
                <Text>{dailyReport.personsCount}</Text>
              </Descriptions.Item>
              <Descriptions.Item
                label={<Text strong>{`Pracovní doba od`}</Text>}
              >
                <Text>
                  {getHoursAndMinutesFromNumber(dailyReport.workHoursFrom)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item
                label={<Text strong>{`Pracovní doba do`}</Text>}
              >
                <Text>
                  {getHoursAndMinutesFromNumber(dailyReport.workHoursTo)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Doprava`}</Text>}>
                <Text>{`${dailyReport.distance} km`}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </>

      <Divider />

      <Row>
        <Col>
          <Title level={4}>{`Výkazy materiálu`}</Title>
          {loading && <Skeleton active loading />}
          {materialReport && (
            <Table
              dataSource={materialReport.dataSource}
              columns={materialReport.columns}
              scroll={{ x: true }}
              pagination={false}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Title level={4}>{`Výkazy cestovních nákladů`}</Title>
          {loading && <Skeleton active loading />}
          {travelCostReports && (
            <Table
              dataSource={travelCostReports.dataSource}
              columns={travelCostReports.columns}
              scroll={{ x: true }}
              pagination={false}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Title level={4}>{`Výkazy ubytování`}</Title>
          {loading && <Skeleton active loading />}
          {accommodationReports && (
            <Table
              dataSource={accommodationReports.dataSource}
              columns={accommodationReports.columns}
              scroll={{ x: true }}
              pagination={false}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Title level={4}>{`Popisy práce`}</Title>
          {loading && <Skeleton active loading />}
          {workDescriptionReports && (
            <Table
              dataSource={workDescriptionReports.dataSource}
              columns={workDescriptionReports.columns}
              scroll={{ x: true }}
              pagination={false}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
