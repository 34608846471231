import * as React from "react";
import { Form, DatePicker, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import {
  OrdersQuery,
  OrdersQueryVariables,
  UserHoursQuery,
  UserHoursQueryVariables
} from "../../../graphql/generated";
import { QueryHookResult } from "react-apollo-hooks";
import moment, { Moment } from "moment";
import { getValidateStatus } from "../../../utils";

export interface DailyListingFormProps extends FormComponentProps {
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
  userHours: QueryHookResult<UserHoursQuery, UserHoursQueryVariables>;
}

export const DailyListingFilterForm = Form.create<DailyListingFormProps>({
  name: `DailyListing`
})(
  (props: DailyListingFormProps): React.ReactElement => {
    const { form, userHours, orders } = props;
    const { Option } = Select;
    const { getFieldDecorator, getFieldValue } = form;

    return (
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY`}
              onChange={(date: Moment | null) => {
                if (date) {
                  orders.refetch({ date: date.unix() });
                  userHours.refetch({ date: date.unix() });
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order")(
            <Select
              mode="multiple"
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
              onChange={(orders: number[]) => {
                const date: Moment = getFieldValue(`date`);
                userHours.refetch({
                  date: date.unix(),
                  orders: orders.map((id: number) => ({
                    id
                  }))
                });
              }}
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    );
  }
);
