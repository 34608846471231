import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Row,
  Col,
  Table,
  Button,
  Icon,
  Drawer,
  Tooltip,
  Divider,
  Skeleton
} from "antd";
import { TravelOrderEditForm, TravelOrderFilterForm } from "../../forms";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  DeleteTravelOrderDocument,
  DeleteTravelOrderMutation,
  DeleteTravelOrderMutationVariables,
  Notification,
  TravelOrder,
  TravelOrdersListDocument,
  TravelOrdersListQuery,
  TravelOrdersListQueryVariables
} from "../../graphql/generated";
import moment, { Moment } from "moment";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";
import { ThemeContext, ThemeContextState } from "../../contexts/ThemeContext";
import { PopConfirm } from "../../components/PopConfirm";
// TODO: simplify imports

interface TravelOrderRow {
  key: number;
  label: string;
  cashReceipt: string;
  employee: string;
  passengers: string;
  formation: string;
  vehicle: string;
  vehicleMode: string;
  data: TravelOrder;
}

export interface TravelOrdersProps {}

export const TravelOrders: React.FC<TravelOrdersProps> = (
  props: TravelOrdersProps
): React.ReactElement => {
  const title: string = `Výpis cestovních příkazů`;
  const { Title } = Typography;

  const [date, setDate] = React.useState<number>(moment().unix());

  const travelOrders = useQuery<
    TravelOrdersListQuery,
    TravelOrdersListQueryVariables
  >(TravelOrdersListDocument, {
    variables: { monthTimestamp: date },
    fetchPolicy: `no-cache`
  });

  const dataSource: TravelOrderRow[] = [];

  if (travelOrders.data) {
    travelOrders.data.travelOrders.map((item, key) =>
      dataSource.push({
        key: item.id,
        label: item.label,
        cashReceipt: item.cashReceipt,
        employee: item.employee.fullName,
        formation: item.formation,
        passengers: `${item.passengers
          .map(passenger => passenger.fullName)
          .join(`, `)}`,
        vehicleMode: item.vehicle.vehicleMode.name,
        vehicle: item.vehicle.codeName,
        data: item as TravelOrder
      })
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <TravelOrderFilterForm
              onDateChange={(date: Moment | null) => {
                if (date) {
                  setDate(date.unix());
                  travelOrders.refetch({ monthTimestamp: date.unix() });
                }
              }}
            />
          </Col>
        </Row>
        {travelOrders.loading ? (
          <>
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
          </>
        ) : (
          <TravelOrdersTableView
            dataSource={dataSource}
            refetch={travelOrders.refetch}
            date={date}
          />
        )}
      </div>
    </>
  );
};

const TravelOrdersTableView: React.FC<{
  dataSource: Array<TravelOrderRow>;
  refetch: () => void;
  date: number;
}> = props => {
  const { dataSource, refetch, date } = props;

  const [isDrawerVisible, setDrawerVisibility] = React.useState<boolean>(false);
  const [activeItem, setActiveItem] = React.useState<TravelOrder>();
  const { isBroken } = React.useContext<ThemeContextState>(ThemeContext);

  const onClose = () => {
    setDrawerVisibility(false);
    setTimeout(() => setActiveItem(undefined), 500);
  };

  return (
    <>
      {dataSource.length > 0 && (
        <Row>
          <Col>
            <TravelOrdersTable
              dataSource={dataSource}
              onEditClick={(item: TravelOrder) => {
                setActiveItem(item);
                setDrawerVisibility(true);
              }}
            />
          </Col>
        </Row>
      )}
      <Drawer
        title="Detail cestovního příkazu"
        placement="right"
        closable
        onClose={onClose}
        visible={isDrawerVisible}
        width={isBroken ? window.innerWidth : 1344}
      >
        {activeItem && <TravelOrderEditForm travelOrder={activeItem} />}
      </Drawer>
    </>
  );
};

const TravelOrdersTable: React.FC<{
  dataSource: TravelOrderRow[];
  onEditClick: (item: TravelOrder) => void;
}> = props => {
  const { dataSource, onEditClick } = props;
  const [data, setData] = React.useState<TravelOrderRow[]>(dataSource);

  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  const [deleteTravelOrder] = useMutation<
    DeleteTravelOrderMutation,
    DeleteTravelOrderMutationVariables
  >(DeleteTravelOrderDocument);

  const columns = [
    {
      title: "Označení",
      dataIndex: "label",
      id: "label"
    },
    {
      title: "Pokladní doklad",
      dataIndex: "cashReceipt",
      id: "cashReceipt"
    },
    {
      title: "Zaměstnanec",
      dataIndex: "employee",
      id: "employee"
    },
    {
      title: "Spolucestující",
      dataIndex: "passengers",
      id: "passengers"
    },
    {
      title: "Útvar",
      dataIndex: "formation",
      id: "formation"
    },
    {
      title: "Dopravní prostředek",
      dataIndex: "vehicle",
      id: "vehicle"
    },
    {
      title: "Režim",
      dataIndex: "vehicleMode",
      id: "vehicleMode"
    },
    {
      title: "Akce",
      key: "action",
      align: `center` as "center",
      render: (record: TravelOrderRow) => {
        return (
          <span>
            <Tooltip trigger={`hover`} title={`Stáhnout`}>
              <Button
                size={`small`}
                type={"link"}
                href={`/documents/travel_orders/get/${record.data.id}`}
                target={`_blank`}
              >
                <Icon type="download" />
              </Button>
            </Tooltip>

            <Divider type={"vertical"} />
            <Tooltip trigger={`hover`} title={`Editovat`}>
              <Button
                type={"primary"}
                onClick={() => onEditClick(record.data)}
                size={`small`}
              >
                <Icon type="edit" />
              </Button>
            </Tooltip>
            <Divider type={`vertical`} />

            <PopConfirm
              onConfirm={async () => {
                const response = await deleteTravelOrder({
                  variables: {
                    input: { id: record.key }
                  }
                });

                if (
                  response &&
                  response.data &&
                  response.data.deleteTravelOrder
                ) {
                  if (response.data.deleteTravelOrder.status) {
                    data.splice(record.key, 1);
                    setData(data);
                  }

                  response.data.deleteTravelOrder.notifications.map(
                    (notification: Notification) =>
                      pushNotification(notification)
                  );
                }
              }}
            >
              <Tooltip trigger={`hover`} title={`Odstranit`}>
                <Button type={"danger"} size={`small`}>
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </PopConfirm>
          </span>
        );
      }
    }
  ];

  return <Table dataSource={data} columns={columns} scroll={{ x: true }} />;
};
