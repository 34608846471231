import * as React from "react";
import { Form, Button, DatePicker, Select, InputNumber, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment";
import { getValidateStatus } from "../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";
import {
  CreateEmployeeLoanDocument,
  CreateEmployeeLoanMutation,
  CreateEmployeeLoanMutationVariables,
  EmployeesQuery,
  EmployeesQueryVariables,
  Notification
} from "../../graphql/generated";
import { QueryHookResult, useMutation } from "react-apollo-hooks";

export interface EmployeesLoansFormProps extends FormComponentProps {
  employees: QueryHookResult<EmployeesQuery, EmployeesQueryVariables>;
}

export const CreateEmployeeLoanForm = Form.create<EmployeesLoansFormProps>({
  name: `EmployeesLoansForm`
})(
  (props: EmployeesLoansFormProps): React.ReactElement => {
    const { form, employees } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      CreateEmployeeLoanMutation,
      CreateEmployeeLoanMutationVariables
    >(CreateEmployeeLoanDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateEmployeeLoanMutationVariables = {
            input: {
              loanDate: values.loanDate.unix(),
              employee: { id: values.employee },
              amount: values.amount,
              monthlyPayment: values.monthlyPayment,
              name: values.name,
              repaymentDate: values.repaymentDate.unix()
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createEmployeeLoan) {
            response.data.createEmployeeLoan.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label={`Zaměstnanec`}>
          {getFieldDecorator("employee", {
            rules: [
              {
                required: true,
                message: `Vyberte zaměstnance`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zaměstnance`}
              loading={employees.loading}
              disabled={
                !(employees.data && employees.data.employees.length > 0)
              }
            >
              {employees.data &&
                employees.data.employees
                  .filter(employee => {
                    const passengersField: number[] = getFieldValue(
                      `passengers`
                    );
                    if (passengersField) {
                      return !passengersField.includes(employee.id);
                    }

                    return true;
                  })
                  .map(employee => (
                    <Option key={employee.id} value={employee.id}>
                      {employee.fullName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Název`}>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: `Vyplňte název`
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Výše (Kč)`}>
          {getFieldDecorator("amount", {
            rules: [
              {
                required: true,
                message: `Zadejte výši`
              }
            ]
          })(<InputNumber decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item label="Datum zápůjčky">
          {getFieldDecorator("loanDate", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum zápůjčky"
              }
            ]
          })(<DatePicker format={`DD.MM.YYYY`} />)}
        </Form.Item>

        <Form.Item label={`Výše měsíční splátky (Kč)`}>
          {getFieldDecorator("monthlyPayment", {
            rules: [
              {
                required: true,
                message: `Vyplňte výši měsíční splátky`
              }
            ]
          })(<InputNumber decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item label="Datum doplacení">
          {getFieldDecorator("repaymentDate", {
            rules: [
              {
                required: true,
                message: "Zadejte datum doplacení"
              }
            ]
          })(
            <DatePicker format={`DD.MM.YYYY`} placeholder={`Vyberte datum`} />
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
