import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Row,
  Col,
  Table,
  Button,
  Icon,
  Drawer,
  Tooltip,
  Divider,
  Skeleton
} from "antd";
import { DailyListingEditForm, DailyListingFilterForm } from "../../forms";
import { useMutation, useQuery } from "react-apollo"; // TODO: from "react-apollo-hooks";?
import {
  DeleteUserHoursDocument,
  DeleteUserHoursMutation,
  DeleteUserHoursMutationVariables,
  Notification,
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables,
  UserHoursDocument,
  UserHoursQuery,
  UserHoursQueryVariables,
  UserHours
} from "../../graphql/generated";
import moment from "moment";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";
import { getHoursAndMinutesFromNumber } from "../../utils";
import { ThemeContext, ThemeContextState } from "../../contexts/ThemeContext";
import { PopConfirm } from "../../components/PopConfirm";

interface UserHoursRow {
  key: number;
  id: number;
  hoursCount: number | null | undefined;
  employee: string;
  order: string;
  rate: string;
  hoursFrom: string;
  hoursTo: string | null | undefined;
  data: UserHours;
}

export interface DailyListingProps {}

export const DailyListing: React.FC<DailyListingProps> = (
  props: DailyListingProps
): React.ReactElement => {
  const title: string = `Denní výpis`;
  const { Title } = Typography;

  const [date, setDate] = React.useState<number>(moment().unix());

  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    variables: { date }
  });

  const userHours = useQuery<UserHoursQuery, UserHoursQueryVariables>(
    UserHoursDocument,
    {
      variables: { date }
    }
  );

  const dataSource: Array<UserHoursRow> = [];

  if (userHours.data) {
    userHours.data.userHours.map((item, key) =>
      dataSource.push({
        key: item.id,
        id: item.id,
        employee: item.employee.fullName,
        hoursCount: item.hoursCount,
        hoursFrom: getHoursAndMinutesFromNumber(item.hoursFrom),
        hoursTo: item.hoursTo
          ? getHoursAndMinutesFromNumber(item.hoursTo)
          : null,
        order: item.order.name,
        rate: item.rate.name,
        data: item as UserHours
      })
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <DailyListingFilterForm orders={orders} userHours={userHours} />
          </Col>
        </Row>

        {userHours.loading ? (
          <>
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
          </>
        ) : (
          <DailyListingTableView
            dataSource={dataSource}
            refetch={userHours.refetch}
          />
        )}
      </div>
    </>
  );
};

const DailyListingTableView: React.FC<{
  dataSource: Array<UserHoursRow>;
  refetch: () => void;
}> = (props: { dataSource: Array<UserHoursRow>; refetch: () => void }) => {
  const { dataSource, refetch } = props;
  const [isDrawerVisible, setDrawerVisibility] = React.useState<boolean>(false);
  const [activeItem, setActiveItem] = React.useState<UserHours>();
  const { isBroken } = React.useContext<ThemeContextState>(ThemeContext);

  const onClose = () => {
    setDrawerVisibility(false);

    setTimeout(() => setActiveItem(undefined), 500);
  };

  return (
    <>
      {dataSource.length > 0 && (
        <Row>
          <Col>
            <DailyListingTable
              dataSource={dataSource}
              key={JSON.stringify(dataSource)}
              refetch={refetch}
              onEditClick={(item: UserHours) => {
                setActiveItem(item);
                setDrawerVisibility(true);
              }}
            />
          </Col>
        </Row>
      )}

      <Drawer
        title="Editace denního výpisu"
        placement="right"
        closable
        onClose={onClose}
        visible={isDrawerVisible}
        zIndex={1050}
        width={isBroken ? window.innerWidth : 500}
      >
        {activeItem && (
          <DailyListingEditForm
            userHours={activeItem}
            onSubmit={() => {
              refetch();
              onClose();
            }}
          />
        )}
      </Drawer>
    </>
  );
};

const DailyListingTable: React.FC<{
  dataSource: UserHoursRow[];
  onEditClick: (item: UserHours) => void;
  refetch: () => void;
}> = (props: {
  dataSource: UserHoursRow[];
  onEditClick: (item: UserHours) => void;
  refetch: () => void;
}) => {
  const { dataSource, onEditClick, refetch } = props;
  const [data, setData] = React.useState<UserHoursRow[]>(dataSource);

  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  const [deleteUserHours] = useMutation<
    DeleteUserHoursMutation,
    DeleteUserHoursMutationVariables
  >(DeleteUserHoursDocument);

  const columns = [
    {
      title: "Zakázka",
      dataIndex: "order",
      id: "order"
    },
    {
      title: "Zaměstnanec",
      dataIndex: "employee",
      id: "employee"
    },
    {
      title: "Tarif",
      dataIndex: "rate",
      id: "rate"
    },
    {
      title: "Hodiny od",
      dataIndex: "hoursFrom",
      id: "hoursFrom"
    },
    {
      title: "Hodiny do",
      dataIndex: "hoursTo",
      id: "hoursTo"
    },
    {
      title: "Počet hodin",
      dataIndex: "hoursCount",
      id: "hoursCount"
    },
    {
      title: "Akce",
      key: "action",
      align: `center` as "center",
      render: (text: string, record: UserHoursRow) => {
        return (
          <>
            <Tooltip trigger={`hover`} title={`Editovat`}>
              <Button
                type={"primary"}
                onClick={() => onEditClick(record.data)}
                size={`small`}
              >
                <Icon type="edit" />
              </Button>
            </Tooltip>

            <Divider type={`vertical`} />

            <PopConfirm
              onConfirm={async () => {
                const response = await deleteUserHours({
                  variables: { input: { id: record.id } }
                });

                if (
                  response &&
                  response.data &&
                  response.data.deleteUserHours
                ) {
                  if (response.data.deleteUserHours.status) {
                    data.splice(record.key, 1);
                    setData(data);
                    refetch();
                  }

                  response.data.deleteUserHours.notifications.map(
                    (notification: Notification) =>
                      pushNotification(notification)
                  );
                }
              }}
            >
              <Tooltip trigger={`hover`} title={`Odstranit`}>
                <Button type={"danger"} size={`small`}>
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </PopConfirm>
          </>
        );
      }
    }
  ];

  return <Table dataSource={data} columns={columns} scroll={{ x: true }} />;
};
