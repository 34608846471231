import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  DefaultTravelOrderPurposeDocument,
  DefaultTravelOrderPurposeQuery,
  DefaultTravelOrderPurposeQueryVariables,
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables
} from "../../../graphql/generated";
import { TravelOrderCreateForm } from "../../../forms";
export interface TravelOrderProps {}

export const CreateTravelOrderView: React.FC<TravelOrderProps> = (
  props: TravelOrderProps
): React.ReactElement => {
  const title: string = `Nový cestovní příkaz`;
  const { Title } = Typography;
  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument);

  const defaultTravelOrderPurposeDocument = useQuery<
    DefaultTravelOrderPurposeQuery,
    DefaultTravelOrderPurposeQueryVariables
  >(DefaultTravelOrderPurposeDocument);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <TravelOrderCreateForm
              orders={orders}
              defaultTravelOrderPurposeDocument={
                defaultTravelOrderPurposeDocument
              }
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
