import * as React from "react";
import { Notification } from "../../graphql/generated";

export interface ThemeContextState {
  isBroken: boolean;
  setBroken: (isBroken: boolean) => void;
}

const defaultValue: ThemeContextState = {
  isBroken: false,
  setBroken: () => null
};

export const ThemeContext = React.createContext<ThemeContextState>(
  defaultValue
);

export const ThemeContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = (props: React.PropsWithChildren<unknown>): React.ReactElement => {
  const { children } = props;

  const [isBroken, setBroken] = React.useState<boolean>(defaultValue.isBroken);

  return (
    <ThemeContext.Provider
      value={{
        isBroken,
        setBroken
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
