import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Row,
  Col,
  Table,
  Button,
  Icon,
  Drawer,
  Tooltip,
  Divider,
  Skeleton
} from "antd";
import { DailyReportFilterForm } from "../../forms";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  DailyReport,
  DailyReportsDocument,
  DailyReportsQuery,
  DailyReportsQueryVariables,
  DeleteDailyReportDocument,
  DeleteDailyReportMutation,
  DeleteDailyReportMutationVariables,
  Notification,
  Order
} from "../../graphql/generated";
import moment, { Moment } from "moment";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";
import { getHoursAndMinutesFromNumber } from "../../utils";
import { ThemeContext, ThemeContextState } from "../../contexts/ThemeContext";
import { DailyReportEditForm } from "../../forms/DailyReport/Edit";
import { PopConfirm } from "../../components/PopConfirm";

interface DailyReportRow {
  key: number;
  order: Order;
  orderName: string;
  date: number;
  weather: string;
  temperature: string;
  personsCount: number;
  workHoursFrom: string;
  workHoursTo: string;
  distance: string;
  data: DailyReport;
}

export interface DailyReportsProps {}

export const DailyReports: React.FC<DailyReportsProps> = (
  props: DailyReportsProps
): React.ReactElement => {
  const title: string = `Výpis výkazů`;
  const { Title } = Typography;

  const [date, setDate] = React.useState<number>(moment().unix());

  const dailyReports = useQuery<DailyReportsQuery, DailyReportsQueryVariables>(
    DailyReportsDocument,
    {
      // TODO: tady by měly jít i parametry zakázky?
      variables: { date }
    }
  );

  const dataSource: DailyReportRow[] = [];

  if (dailyReports.data) {
    dailyReports.data.dailyReports.map((item, key) =>
      dataSource.push({
        key,
        distance: `${item.distance} km`,
        date: item.date,
        order: item.order,
        orderName: item.order.name,
        personsCount: item.personsCount,
        temperature: `${item.temperature} °C`,
        weather: item.weather.name,
        workHoursFrom: getHoursAndMinutesFromNumber(item.workHoursFrom),
        workHoursTo: getHoursAndMinutesFromNumber(item.workHoursTo),
        data: item as DailyReport
      })
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <DailyReportFilterForm
              onDateChange={(date: Moment | null) => {
                if (date) {
                  setDate(date.unix());
                  dailyReports.refetch({ date: date.unix() });
                }
              }}
            />
          </Col>
        </Row>

        {dailyReports.loading ? (
          <>
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
          </>
        ) : (
          <DailyReportsTableView
            dataSource={dataSource}
            refetch={dailyReports.refetch}
            date={date}
          />
        )}
      </div>
    </>
  );
};

const DailyReportsTableView: React.FC<{
  dataSource: Array<DailyReportRow>;
  refetch: () => void;
  date: number;
}> = props => {
  const { dataSource, refetch, date } = props;
  const [isDrawerVisible, setDrawerVisibility] = React.useState<boolean>(false);
  const [activeItem, setActiveItem] = React.useState<DailyReport>();
  const { isBroken } = React.useContext<ThemeContextState>(ThemeContext);

  const onClose = (): void => {
    setDrawerVisibility(false);
    setTimeout(() => setActiveItem(undefined), 500);
  };

  return (
    <>
      {dataSource.length > 0 && (
        <Row>
          <Col>
            <DailyReportsTable
              dataSource={dataSource}
              onEditClick={(item: DailyReport) => {
                setActiveItem(item);
                setDrawerVisibility(true);
              }}
            />
          </Col>
        </Row>
      )}
      <Drawer
        title="Detail výkazu"
        placement="right"
        closable
        onClose={onClose}
        visible={isDrawerVisible}
        width={isBroken ? window.innerWidth : 900}
      >
        {activeItem && (
          <DailyReportEditForm dailyReport={activeItem} date={date} />
        )}
      </Drawer>
    </>
  );
};

const DailyReportsTable: React.FC<{
  dataSource: DailyReportRow[];
  onEditClick: (item: DailyReport) => void;
}> = (props: {
  dataSource: DailyReportRow[];
  onEditClick: (item: DailyReport) => void;
}) => {
  const { dataSource, onEditClick } = props;
  const [data, setData] = React.useState<DailyReportRow[]>(dataSource);

  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  const [deleteDailyReport] = useMutation<
    DeleteDailyReportMutation,
    DeleteDailyReportMutationVariables
  >(DeleteDailyReportDocument);

  const columns = [
    {
      title: "Zakázka",
      dataIndex: "orderName",
      id: "order"
    },
    {
      title: "Teplota",
      dataIndex: "temperature",
      id: "temperature"
    },
    {
      title: "Počasí",
      dataIndex: "weather",
      id: "weather"
    },
    {
      title: "Počet lidí",
      dataIndex: "personsCount",
      id: "personsCount"
    },
    {
      title: "Pracovní doba od",
      dataIndex: "workHoursFrom",
      id: "workHoursFrom"
    },
    {
      title: "Pracovní doba do",
      dataIndex: "workHoursTo",
      id: "workHoursTo"
    },
    {
      title: "Doprava",
      dataIndex: "distance",
      id: "distance"
    },
    {
      title: "Akce",
      key: "action",
      align: `center` as "center",
      render: (text: string, record: DailyReportRow) => {
        return (
          <span>
            <Tooltip trigger={`hover`} title={`Editovat`}>
              <Button
                type={"primary"}
                onClick={() => onEditClick(record.data)}
                size={`small`}
              >
                <Icon type="edit" />
              </Button>
            </Tooltip>

            <Divider type={`vertical`} />

            <PopConfirm
              onConfirm={async () => {
                const response = await deleteDailyReport({
                  variables: {
                    input: { order: { id: record.order.id }, date: record.date }
                  }
                });

                if (
                  response &&
                  response.data &&
                  response.data.deleteDailyReport
                ) {
                  if (response.data.deleteDailyReport.status) {
                    data.splice(record.key, 1);
                    setData(data);
                  }

                  response.data.deleteDailyReport.notifications.map(
                    (notification: Notification) =>
                      pushNotification(notification)
                  );
                }
              }}
            >
              <Tooltip trigger={`hover`} title={`Odstranit`}>
                <Button type={"danger"} size={`small`}>
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </PopConfirm>
          </span>
        );
      }
    }
  ];

  return <Table dataSource={data} columns={columns} scroll={{ x: true }} />;
};
