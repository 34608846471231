import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Row,
  Col,
  Table,
  Divider,
  Skeleton,
  Icon,
  Button,
  Input,
  Tooltip
} from "antd";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  DefaultExchangeRateEurczkDocument,
  DefaultExchangeRateEurczkQuery,
  DefaultExchangeRateEurczkQueryVariables,
  EmployeeInput,
  Maybe,
  Notification,
  PayoutsTableDocument,
  PayoutsTableQuery,
  PayoutsTableQueryVariables,
  PayoutsTableRow,
  Scalars,
  UpdatePayoutsTableDocument,
  UpdatePayoutsTableMutation,
  UpdatePayoutsTableMutationVariables
} from "../../graphql/generated";
import moment, { Moment } from "moment";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";
import { PayoutsFilterForm } from "../../forms/Payouts/Filter";

// TODO: cleanup unused imports

interface PayoutsRow {
  key: number;
  employee: string;
  hoursCR?: number | null;
  hoursEU?: number | null;
  payoutCR?: number | null;
  payoutEU?: number | null;
  bonusCR?: number | null;
  depositCR?: number | null;
  levies?: number | null;
  flatRate?: number | null;
  previousRest?: number | null;
  repayment?: number | null;
  penaltyCR?: number | null;
  penaltyNotesCR?: string | null;
  distraintsCount?: number | null;
  distraintsTax?: number | null;
  finalSurcharge?: number | null;
  paid?: number | null;
  note: string;
  data: PayoutsTableRow;
}

export interface PayoutsProps {}

/* TODO: sjednotit PayoutsRow -> PayoutsTableRow, EconomicsRow -> EconomicsTableRow */
export const Payouts: React.FC<PayoutsProps> = (
  props: PayoutsProps
): React.ReactElement => {
  const title: string = `Tabulka`;
  const { Title } = Typography;

  const [date, setDate] = React.useState<number>(moment().unix());

  const defaultExchangeRate = useQuery<
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables
  >(DefaultExchangeRateEurczkDocument);

  const payouts = useQuery<PayoutsTableQuery, PayoutsTableQueryVariables>(
    PayoutsTableDocument,
    {
      variables: {
        monthTimestamp: date,
        exchangeRateEURCZK: defaultExchangeRate.data
          ? defaultExchangeRate.data.defaultExchangeRateEURCZK
          : 25 // TODO: tady nemít fixně hodnotu
      },
      fetchPolicy: `no-cache`,
      skip: !defaultExchangeRate.data
    }
  );

  const dataSource: PayoutsRow[] = [];
  const columns = [
    {
      title: "Zaměstnanec",
      dataIndex: "employee",
      id: "employee",
      width: 107,
    },
    {
      title: "Hodiny ČR",
      dataIndex: "hoursCR",
      id: "hoursCR",
      width: 64
    },
    {
      title: "Hodiny EU",
      dataIndex: "hoursEU",
      id: "hoursEU",
      width: 64
    },
    {
      title: "Výplata ČR (Kč)",
      dataIndex: "payoutCR",
      id: "payoutCR",
      width: 67
    },
    {
      title: "Výplata EU (€)",
      dataIndex: "payoutEU",
      id: "payoutEU",
      width: 67
    },
    {
      title: "Prémie ČR (Kč)",
      dataIndex: "bonusCR",
      id: "bonusCR",
      width: 63,
      onCell: (record: PayoutsRow) => ({
        record,
        name: "bonusCR",
        editable: true,
        value: record.bonusCR,
        refetch: payouts.refetch
      })
    },
    {
      // TODO: rename to "Zálohy (Kč)"
      title: "Zálohy ČR (Kč)",
      // TODO: rename to "deposit"
      dataIndex: "depositCR",
      id: "depositCR",
      width: 62
    },
    {
      title: "Odvody",
      dataIndex: "levies",
      id: "levies",
      width: 69,
      onCell: (record: PayoutsRow) => ({
        record,
        name: "levies",
        editable: true,
        value: record.levies,
        refetch: payouts.refetch
      })
    },
    {
      title: "Paušál",
      dataIndex: "flatRate",
      id: "flatRate",
      width: 61,
      onCell: (record: PayoutsRow) => ({
        record,
        name: "flatRate",
        editable: true,
        value: record.flatRate,
        refetch: payouts.refetch
      })
    },
    {
      title: "Rest minulý",
      dataIndex: "previousRest",
      id: "previousRest",
      width: 72,
      onCell: (record: PayoutsRow) => ({
        record,
        name: "previousRest",
        editable: true,
        value: record.previousRest,
        refetch: payouts.refetch
      })
    },
    {
      title: "Splátka",
      dataIndex: "repayment",
      id: "repayment",
      width: 72,
      onCell: (record: PayoutsRow) => ({
        record,
        name: "repayment",
        editable: true,
        value: record.repayment,
        refetch: payouts.refetch
      })
    },
    {
      title: "Pokuty ČR (Kč)",
      dataIndex: "penaltyCR",
      id: "penaltyCR",
      width: 63,
      // TODO: odstranit <td></td>
      render: (value: string, record: PayoutsRow) => {
        return (
          <Tooltip
            trigger={`hover`}
            title={record.penaltyNotesCR}
          >
            <td>{value}</td>
          </Tooltip>
        );
      }
    },
    {
      title: "Exekuce",
      dataIndex: "distraintsCount",
      id: "distraintsCount",
      width: 73,
      onCell: (record: PayoutsRow) => ({
        record,
        name: "distraintsCount",
        editable: true,
        value: record.distraintsCount,
        refetch: payouts.refetch
      })
    },
    {
      title: "Poplatek exekuce",
      dataIndex: "distraintsTax",
      id: "distraintsTax",
      width: 75
    },
    {
      title: "Finální doplatek",
      dataIndex: "finalSurcharge",
      id: "finalSurcharge",
      width: 75,
      className: "alt"
    },
    {
      title: "Vyplaceno",
      dataIndex: "paid",
      id: "paid",
      width: 87,
      className: "alt",
      onCell: (record: PayoutsRow) => ({
        record,
        name: "paid",
        editable: true,
        value: record.paid,
        refetch: payouts.refetch
      })
    },
    {
      title: "Poznámka",
      dataIndex: "note",
      id: "note",
      width: 234,
      onCell: (record: PayoutsRow) => ({
        record,
        name: "note",
        editable: true,
        value: record.note,
        refetch: payouts.refetch
      })
    }
  ];

  if (payouts.data) {
    payouts.data.payoutsTable.map((item, key) =>
      dataSource.push({
        key,
        employee: item.employee.fullName,
        hoursCR: item.hoursCR,
        hoursEU: item.hoursEU,
        payoutCR: item.payoutCR,
        payoutEU: item.payoutEU,
        bonusCR: item.bonusCR,
        depositCR: item.depositCR,
        levies: item.levies,
        flatRate: item.flatRate,
        previousRest: item.previousRest,
        repayment: item.repayment,
        penaltyCR: item.penaltyCR,
        penaltyNotesCR: item.penaltyNotesCR,
        distraintsCount: item.distraintsCount,
        distraintsTax: item.distraintsTax,
        finalSurcharge: item.finalSurcharge,
        paid: item.paid,
        note: item.note,
        data: item as PayoutsTableRow
      })
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <PayoutsFilterForm
              setDate={setDate}
              defaultExchangeRate={defaultExchangeRate}
              refetch={payouts.refetch}
            />
          </Col>
        </Row>

        {!payouts.data ? (
          <>
            <Skeleton active loading />
            <Skeleton active loading />
          </>
        ) : (
          <PayoutsTableView
            dataSource={dataSource}
            refetch={payouts.refetch}
            date={date}
            columns={columns}
          />
        )}
      </div>
    </>
  );
};

const PayoutsTableView: React.FC<{
  dataSource: Array<PayoutsRow>;
  refetch: () => void;
  date: number;
  columns: any[];
}> = props => {
  const { dataSource, refetch, date, columns } = props;

  return (
    <>
      {dataSource.length > 0 && (
        <Row>
          <Col>
            <PayoutsTable dataSource={dataSource} key={JSON.stringify(dataSource)} date={date} columns={columns} />
          </Col>
        </Row>
      )}
    </>
  );
};

const PayoutsTable: React.FC<{
  dataSource: PayoutsRow[];
  date: number;
  columns: any[];
}> = props => {
  const { dataSource, date, columns } = props;
  const [data, setData] = React.useState<PayoutsRow[]>(dataSource);

  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  const finalSurchargeSum: number = data.reduce((sum: number, row: PayoutsRow): number => {
    const finalSurcharge = row.finalSurcharge != null && row.finalSurcharge > 0 ? row.finalSurcharge : 0;
    return sum + finalSurcharge - (row.paid || 0)
  }, 0);
  // TODO: tohle vytahovat z backendu

  return (
    <Table
      size={`small`}
      bordered
      pagination={false}
      components={{
        body: {
          // TODO: vylepšit podle editu https://ant.design/components/typography/
          cell: props => <EditableCell key={props.value} {...props} date={date} />
        }
      }}
      dataSource={data}
      columns={columns}
      scroll={{ x: true }} // TODO: - 64px - 69px - 80px - 54px - 2 * 20px
        // Client: enable horizontal scroll in Payouts table -> Client: enable vertical scroll in Payouts table
      footer={() => 'Celkový finální doplatek: ' + finalSurchargeSum + ' Kč'} // TODO: nahradit všechny '' za ``?
    />
  );
};

interface EditableCellProps {
  editable?: boolean;
  name: string;
  value: string | number | null | undefined;
  record: PayoutsRow;
  refetch: () => void;
  date: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = (
  props: React.PropsWithChildren<EditableCellProps>
) => {
  const { children, editable, record, name, refetch, date } = props;
  const [isEditing, setEditing] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string | number | null | undefined>(
    props.value
  );

  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  const [submit] = useMutation<
    UpdatePayoutsTableMutation,
    UpdatePayoutsTableMutationVariables
  >(UpdatePayoutsTableDocument);

  if (!editable) {
    return <td>{children}</td>;
  }

  if (isEditing) {
    return (
      <td>
        <Input
          value={value ? value : ``}
          onChange={e => setValue(e.target.value)}
          onBlur={() => setEditing(false)}
          onPressEnter={async () => {
            setEditing(false);

            const input = {
              date: date,
              bonusCR: record.bonusCR,
              distraintsCount: record.distraintsCount,
              employee: {
                id: record.data.employee.id
              },
              flatRate: record.flatRate,
              levies: record.levies,
              note: record.note,
              paid: record.paid,
              previousRest: record.previousRest,
              repayment: record.repayment
            };

            const response = await submit({
              variables: {
                input: {
                  ...input,
                  [name]: name === "note" ? value : parseInt(value as string)
                }
              }
            });
            if (response && response.data && response.data.updatePayoutsTable) {
              if (response.data.updatePayoutsTable.status) {
                refetch();
              }

              response.data.updatePayoutsTable.notifications.map(
                (notification: Notification) => pushNotification(notification)
              );
            }
          }}
          autoFocus
        />
      </td>
    );
  }

  return (
    <td>
      <span style={{ marginRight: 10 }}>{children}</span>
      <span>
        <Button size={`small`} onClick={() => setEditing(true)}>
          <Icon type={`edit`} />
        </Button>
      </span>
    </td>
  );
};
