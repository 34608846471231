import * as React from "react";
import { FormComponentProps } from "antd/lib/form";
import {Form, DatePicker, Input, Button, Select} from "antd";
import moment, { Moment } from "moment";
import { useMutation } from "react-apollo-hooks";
import {
  UpdateEventDocument,
  UpdateEventMutation,
  UpdateEventMutationVariables,
  Notification
} from "../../graphql/generated";
import {AuthContext, AuthContextState, NotificationContext, NotificationContextState} from "../../contexts";

export interface UpdateCalendarEventFormProps extends FormComponentProps {
  item: any;
}

export const UpdateCalendarEventForm = Form.create<UpdateCalendarEventFormProps>({
  name: `UpdateCalendarEventForm`
})(
  (props: UpdateCalendarEventFormProps): React.ReactElement => {
    const { form, item } = props;
    // const { getFieldDecorator } = form;
    const { Option } = Select;
    const {
      getFieldDecorator,
      validateFields,
      resetFields
    } = form;
    const { setAuthenticated, setToken, user } = React.useContext<
      AuthContextState
    >(AuthContext);

    console.log(item);

    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const employees = [
      {'id': 1, 'fullName': 'Pavel Adam', 'email': 'pavel.adam@adam-epox.cz'},
      {'id': 80, 'fullName': 'Marek Hanuš', 'email': 'mne@marekhanus.cz'},
      {'id': 81, 'fullName': 'Barbora Ševčíková', 'email': 'barbora.sevcikova@adam-epox.cz'},
      {'id': 120, 'fullName': 'Pavla Grigarová', 'email': 'pavla.grigarova@adam-epox.cz'},  // TODO: fix 121 -> 120
    ];

    const [submit] = useMutation<
      UpdateEventMutation,
      UpdateEventMutationVariables
    >(UpdateEventDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: UpdateEventMutationVariables = {
            input: {
              id: item.id,
              dateTimeFrom: values.dateTimeFrom.unix(),
              dateTimeTo: values.dateTimeTo.unix(), // TODO: rename
              description: values.description,
              // assignments: values.employee, // TODO: rename to guests
              guests: values.employee && values.employee.map((i: any, x: any) => {return {
                id: i,
              }}) || []
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.updateEvent) {
            response.data.updateEvent.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
            window.location.reload(); // TODO: fixme
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
      >
        <Form.Item label="Datum a čas od">
          {getFieldDecorator("dateTimeFrom", {
            initialValue: moment.unix(item.dateTimeFrom),
            rules: [
              {
                required: true,
                message: "Zadejte datum od"
              }
            ]
          })(<DatePicker format={`DD.MM.YYYY HH:mm`} showTime />)}
        </Form.Item>

        <Form.Item label="Datum a čas do">
          {getFieldDecorator("dateTimeTo", {
            // initialValue: moment(moment.unix(date).format("L")),
            initialValue: moment.unix(item.dateTimeTo),
            rules: [
              {
                required: true,
                message: "Zadejte datum do"
              }
            ]
          })(<DatePicker format={`DD.MM.YYYY HH:mm`} showTime />)}
        </Form.Item>

        <Form.Item label={`Název`}>
          {getFieldDecorator("description", {
            initialValue: item.description,
            rules: [
              {
                required: true,
                message: `Vyplňte název`
              }
            ]
          })(<Input />)}
        </Form.Item>

          {/*defaultValue ???*/}
        <Form.Item label={`Hosté`}>
          {getFieldDecorator("employee", {
            initialValue: item.guests.map((g: any) => {return g.id}),
            // rules: [
            //   {
            //     required: true,
            //     message: `Vyberte zaměstnance`
            //   }
            // ]
          })(
            <Select
              mode="multiple"
              allowClear
              placeholder={`Vyberte hosty`}
              // loading={employees.loading}
              // disabled={
              //   !(employees.data && employees.data.employees.length > 0)
              // }
              style={{ width: 200 }} // TODO: formátování exportovat IDEA code style XML/.editorconfig
            >
              {/*{employees.data &&*/}
              {/*  employees.data.employees*/}
              {/*    .filter(employee => {*/}
              {/*      const employeesWithTasksPermission = [1, 80, 81, 120];*/}
              {/*      return employeesWithTasksPermission.includes(employee.id);*/}
              {/*      return [1, 80, 81, 120].includes(employee.id);*/}
              {/*    })*/}
              {employees.filter(employee => {
                return user && user.email != employee.email;
              }).map(employee => (
                <Option key={employee.id} value={employee.id}>
                  {employee.fullName}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
