import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Row,
  Col,
  Table,
  Divider,
  // Skeleton,
  // Icon
} from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  EconomicsTableDocument,
  EconomicsTableQuery,
  EconomicsTableQueryVariables,
  EconomicsTableRow,
  OrdersQuery,
  OrdersQueryVariables,
  OrdersDocument,
  DefaultExchangeRateEurczkQuery,
  DefaultExchangeRateEurczkQueryVariables,
  DefaultExchangeRateEurczkDocument
} from "../../graphql/generated";
import { EconomicsFilterForm } from "../../forms";
import { formatLargeNumbers } from "../../utils";

interface EconomicsRow {
  key: number;
  type: string;
  count?: string | null;
  measurementUnit?: string | null;
  costs?: string | null;
  customer?: string | null;
  data: EconomicsTableRow;
}

// TODO: EconomicsProps
export const Economics: React.FC = (): React.ReactElement => {
  const title: string = `Ekonomie staveb`;
  const { Title } = Typography;
  const [isSubmitted, setSubmitted] = React.useState<boolean>(false);

  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument);

  const defaultExchangeRate = useQuery<
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables
  >(DefaultExchangeRateEurczkDocument);

  const economics = useQuery<EconomicsTableQuery, EconomicsTableQueryVariables>(
    EconomicsTableDocument,
    {
      fetchPolicy: `no-cache`,
      skip: !isSubmitted
    }
  );

  const dataSource: EconomicsRow[] = [];
  const columns = [
    {
      title: "Položky",
      dataIndex: "type",
      id: "type",
    },
    {
      title: "Počet",
      dataIndex: "count",
      id: "count",
      align: "right"
    },
    {
      title: "MJ",
      dataIndex: "measurementUnit",
      id: "measurementUnit"
    },
    {
      title: "Náklad (Kč)",
      dataIndex: "costs",
      id: "costs",
      align: "right"
    },
    {
      title: "Zákazník bez DPH (Kč)",
      dataIndex: "customer",
      id: "customer",
      align: "right"
      // TODO: rename customer -> customerWithoutTax
    }
  ];

  if (economics.data) {
    economics.data.economicsTable.map((item, key) =>
      dataSource.push({
        key,
        type: item.type,
        count: item.count ? formatLargeNumbers(item.count, 1) : null,
        measurementUnit: item.measurementUnit,
        costs: item.costs ? formatLargeNumbers(item.costs, 2) : null,
        customer: item.customer ? formatLargeNumbers(item.customer, 2) : null,
        data: item as EconomicsTableRow
      })
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <EconomicsFilterForm
              orders={orders}
              defaultExchangeRate={defaultExchangeRate}
              refetch={economics.refetch}
              setSubmitted={() => setSubmitted(true)}
            />
          </Col>
        </Row>
        {!economics.data ? (
          <>
          </>
        ) : (
          <EconomicsTableView
            dataSource={dataSource}
            columns={columns}
          />
        )}
      </div>
    </>
  );
};

const EconomicsTableView: React.FC<{
  dataSource: Array<EconomicsRow>; // TODO: nahradit všude zápisem dataSource: EconomicsRow[]; ?
  columns: any[];
}> = props => {
  const { dataSource, columns } = props;

  return (
    <>
      {dataSource.length > 0 && (
        <Row>
          <Col>
            <EconomicsTable dataSource={dataSource} key={JSON.stringify(dataSource)} columns={columns} />
          </Col>
        </Row>
      )}
    </>
  );
};

const EconomicsTable: React.FC<{
  dataSource: EconomicsRow[];
  columns: any[];
}> = props => {
  const { dataSource, columns } = props;
  const [data, setData] = React.useState<EconomicsRow[]>(dataSource);

  const profit: number = data.reduce((sum: number, row: EconomicsRow): number => {
    if (row.key === 5 && row.type === 'Celkem') {
      return (row.data.customer || 0) - (row.data.costs || 0)
    }

    return 0
  }, 0);

  return (
    <Table
      size={`small`}
      bordered
      pagination={false}
      dataSource={data}
      columns={columns}
      // TODO: sync graphql queries FE with BE
      // TODO: scroll={{ x: true, y: true }}
      // TODO: zisk počítat na straně server API
      footer={() => 'Zisk: ' + formatLargeNumbers(profit, 2) + ' Kč'}
    />
  );
};
