import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  CurrencyDocument,
  CurrencyQuery,
  CurrencyQueryVariables,
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables
} from "../../../graphql/generated";
import { OperatingExpenditureForm, PrepaymentForm } from "../../../forms";
export interface OperatingExpeditureProps {}

export const CreateOperatingExpenditureView: React.FC<
  OperatingExpeditureProps
> = (props: OperatingExpeditureProps): React.ReactElement => {
  const title: string = `Vyplacený provozní výdaj`;
  const { Title } = Typography;

  const employees = useQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument
  );

  const currency = useQuery<CurrencyQuery, CurrencyQueryVariables>(
    CurrencyDocument
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <OperatingExpenditureForm
              employees={employees}
              currency={currency}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
