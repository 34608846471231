import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import { PrepaymentForm } from "../../../forms";
import {
  CurrencyDocument,
  CurrencyQuery,
  CurrencyQueryVariables,
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables
} from "../../../graphql/generated";
export interface PrepaymentProps {}

export const CreatePrepaymentView: React.FC<PrepaymentProps> = (
  props: PrepaymentProps
): React.ReactElement => {
  const title: string = `Vyplacená záloha`;
  const { Title } = Typography;

  const employees = useQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument
  );

  const currency = useQuery<CurrencyQuery, CurrencyQueryVariables>(
    CurrencyDocument
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <PrepaymentForm employees={employees} currency={currency} />
          </Col>
        </Row>
      </div>
    </>
  );
};
