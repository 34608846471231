import * as React from "react";
import { Form, Button, DatePicker, Select, InputNumber, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { getValidateStatus } from "../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";
import {
  CreateEmployeePenaltyDocument,
  CreateEmployeePenaltyMutation,
  CreateEmployeePenaltyMutationVariables,
  CurrencyQuery,
  CurrencyQueryVariables,
  EmployeesQuery,
  EmployeesQueryVariables,
  Notification
} from "../../graphql/generated";
import { QueryHookResult, useMutation } from "react-apollo-hooks";
import moment from "moment";

export interface PenaltyFormProps extends FormComponentProps {
  employees: QueryHookResult<EmployeesQuery, EmployeesQueryVariables>;
  currency: QueryHookResult<CurrencyQuery, CurrencyQueryVariables>;
}

export const CreatePenaltyForm = Form.create<PenaltyFormProps>({
  name: `PenaltyForm`
})(
  (props: PenaltyFormProps): React.ReactElement => {
    const { form, employees, currency } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );
    const [submit] = useMutation<
      CreateEmployeePenaltyMutation,
      CreateEmployeePenaltyMutationVariables
    >(CreateEmployeePenaltyDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateEmployeePenaltyMutationVariables = {
            input: {
              date: values.date.unix(),
              employee: { id: values.employee },
              currency: { id: values.currency },
              reason: values.reason,
              amount: values.amount
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (
            response &&
            response.data &&
            response.data.createEmployeePenalty
          ) {
            response.data.createEmployeePenalty.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(<DatePicker format={`DD.MM.YYYY`} />)}
        </Form.Item>

        <Form.Item label={`Zaměstnanec`}>
          {getFieldDecorator("employee", {
            rules: [
              {
                required: true,
                message: `Vyberte zaměstnance`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zaměstnance`}
              loading={employees.loading}
              disabled={
                !(employees.data && employees.data.employees.length > 0)
              }
            >
              {employees.data &&
                employees.data.employees
                  .filter(employee => {
                    const passengersField: number[] = getFieldValue(
                      `passengers`
                    );
                    if (passengersField) {
                      return !passengersField.includes(employee.id);
                    }

                    return true;
                  })
                  .map(employee => (
                    <Option key={employee.id} value={employee.id}>
                      {employee.fullName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Měna`}>
          {getFieldDecorator("currency", {
            rules: [
              {
                required: true,
                message: `Vyberte měnu`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte měnu`}
              loading={currency.loading}
              disabled={!(currency.data && currency.data.currency.length > 0)}
            >
              {currency.data &&
                currency.data.currency.filter(currency => {
                  return currency.id == 1;
                }).map(currency => (
                  <Option key={currency.id} value={currency.id}>
                    {currency.codeName}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Výše pokuty`}>
          {getFieldDecorator("amount", {
            rules: [
              {
                required: true,
                message: `Vyplňte výši pokuty`
              }
            ]
          })(<InputNumber decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item label={`Důvod`}>
          {getFieldDecorator("reason", {
            rules: [
              {
                required: true,
                message: `Zadejte důvod`
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
