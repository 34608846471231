import * as React from "react";
import { Form, Icon, Input, Button, Checkbox, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import {
  TokenAuthDocument,
  TokenAuthMutation,
  TokenAuthMutationVariables
} from "../../graphql/generated";
import { useMutation } from "react-apollo-hooks";
import {
  AuthContext,
  AuthContextState,
  NotificationContext,
  NotificationContextState
} from "../../contexts";

export interface LoginFormProps extends FormComponentProps {}

export const LoginForm = Form.create({ name: `login` })(
  (props: LoginFormProps): React.ReactElement => {
    const { form } = props;
    const { getFieldDecorator, validateFields } = form;
    const [error, setError] = React.useState<string | null>(null);
    const { setUser, setToken, setAuthenticated } = React.useContext<AuthContextState>(
      AuthContext
    );
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [tokenAuth] = useMutation<
      TokenAuthMutation,
      TokenAuthMutationVariables
    >(TokenAuthDocument, {
      update: (proxy, result) => {
        if (result.data && result.data.tokenAuth) {
          if (result.data.tokenAuth.status && result.data.tokenAuth.token) {
            localStorage.setItem(`token`, result.data.tokenAuth.token);
            setUser(result.data.tokenAuth.user);
            setToken(result.data.tokenAuth.token);
            setAuthenticated(true);
          } else {
            pushNotification(result.data.tokenAuth.notification);
          }
        }
      }
    });

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, variables: TokenAuthMutationVariables) => {
        if (!err) {
          await tokenAuth({ variables });
        }
      });
    };

    return (
      <Form onSubmit={onSubmit}>
        {error && (
          <Form.Item>
            <Alert
              showIcon
              closable
              message={error}
              type={`error`}
              onClose={() => setError(null)}
            />
          </Form.Item>
        )}
        <Form.Item label={`E-mail`} hasFeedback>
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: "Zadejte validní E-mail"
              },
              {
                required: true,
                message: "Zadejte E-mail"
              }
            ]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="E-mail"
            />
          )}
        </Form.Item>
        <Form.Item label={`Heslo`}>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Zadejte heslo" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Heslo"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true
          })(<Checkbox>Přihlásit na 31 dní</Checkbox>)}

          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Přihlásit`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
