import * as React from "react";
import Dark from "../../images/Logo/Dark.png";
import Light from "../../images/Logo/Light.png";

export type LogoTheme = "light" | "dark";

export interface LogoProps {
  theme: LogoTheme;
  width: number;
  height: number;
}
export const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  const { theme, height, width } = props;

  return (
    <img
      src={theme === "light" ? Light : Dark}
      alt={`Logo - Adam Epox`}
      width={width}
      height={height}
    />
  );
};
