import * as React from "react";
import {
  Form,
  Button,
  DatePicker,
  Select,
  TimePicker,
  InputNumber
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { QueryHookResult, useMutation } from "react-apollo-hooks";
import moment, { Moment } from "moment";
import {
  CreateDailyReportDocument,
  CreateDailyReportMutation,
  CreateDailyReportMutationVariables,
  Notification,
  OrdersQuery,
  OrdersQueryVariables,
  WeatherQuery,
  WeatherQueryVariables
} from "../../../graphql/generated";
import { getNumberFromMomentHours, getValidateStatus } from "../../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../../contexts/NotificationContext";

export interface DailyReportFormProps extends FormComponentProps {
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
  weather: QueryHookResult<WeatherQuery, WeatherQueryVariables>;
}

export const DailyReportCreateForm = Form.create<DailyReportFormProps>({
  name: `DailyReportForm`
})(
  (props: DailyReportFormProps): React.ReactElement => {
    const { form, orders, weather } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      CreateDailyReportMutation,
      CreateDailyReportMutationVariables
    >(CreateDailyReportDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateDailyReportMutationVariables = {
            input: {
              date: values.date.unix(),
              order: {
                id: values.order
              },
              workHoursFrom: getNumberFromMomentHours(values.workHoursFrom),
              workHoursTo: getNumberFromMomentHours(values.workHoursTo),
              personsCount: values.personsCount,
              temperature: values.temperature,
              weather: {
                id: values.weather
              },
              distance: values.distance
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createDailyReport) {
            response.data.createDailyReport.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );
            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY`}
              onChange={async (date: Moment | null) => {
                if (date) {
                  orders.refetch({ date: date.unix() });
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order", {
            rules: [
              {
                required: true,
                message: `Vyberte zakázku`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Teplota (°C)">
          {getFieldDecorator("temperature", {
            rules: [
              {
                required: true,
                message: `Zadejte teplotu`
              }
            ]
          })(<InputNumber decimalSeparator={`,`} />)}
        </Form.Item>

        <Form.Item label={`Počasí`}>
          {getFieldDecorator("weather", {
            rules: [
              {
                required: true,
                message: `Vyberte počasí`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte počasí`}
              loading={weather.loading}
              disabled={!(weather.data && weather.data.weather.length > 0)}
            >
              {weather.data &&
                weather.data.weather.map(weather => (
                  <Option key={weather.id} value={weather.id}>
                    {weather.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Počet lidí">
          {getFieldDecorator("personsCount", {
            rules: [
              {
                required: true,
                message: `Zadejte počet lidí`
              }
            ]
          })(<InputNumber min={1} precision={0} />)}
        </Form.Item>

        <Form.Item label="Pracovní doba od">
          {getFieldDecorator("workHoursFrom", {
            rules: [
              {
                required: true,
                message: `Zadejte čas`
              }
            ]
          })(<TimePicker format={`HH:mm`} placeholder={`Zadejte čas`} />)}
        </Form.Item>

        <Form.Item label="Pracovní doba do">
          {getFieldDecorator("workHoursTo", {
            rules: [
              {
                required: true,
                message: `Zadejte čas`
              }
            ]
          })(<TimePicker format={`HH:mm`} placeholder={`Zadejte čas`} />)}
        </Form.Item>

        <Form.Item label="Doprava (km)">
          {getFieldDecorator("distance", {
            rules: [
              {
                required: true,
                message: `Zadejte dopravu`
              }
            ]
          })(<InputNumber min={0} precision={0} />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);

// TODO: submit
