import * as React from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Select,
  DatePicker,
  InputNumber
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import {
  useMutation,
  useQuery,
  QueryHookOptions,
  QueryHookResult
} from "react-apollo-hooks";
import moment from "moment";
import {
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  CurrencyQuery,
  CurrencyQueryVariables,
  EmployeesQuery,
  EmployeesQueryVariables,
  Notification,
  StatesQuery,
  StatesQueryVariables,
  SubscribersQuery,
  SubscribersQueryVariables,
  VehiclesQuery,
  VehiclesQueryVariables
} from "../../graphql/generated";
import { getValidateStatus } from "../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";

export interface CreateOrderFormProps extends FormComponentProps {
  employees: QueryHookResult<EmployeesQuery, EmployeesQueryVariables>;
  currency: QueryHookResult<CurrencyQuery, CurrencyQueryVariables>;
  states: QueryHookResult<StatesQuery, StatesQueryVariables>;
  subscribers: QueryHookResult<SubscribersQuery, SubscribersQueryVariables>;
  vehicles: QueryHookResult<VehiclesQuery, VehiclesQueryVariables>;
}

export const CreateOrderForm = Form.create<CreateOrderFormProps>({
  name: `CreateOrderForm`
})(
  (props: CreateOrderFormProps): React.ReactElement => {
    const { form, employees, currency, states, subscribers, vehicles } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      resetFields
    } = form;
    const { Option } = Select;

    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      CreateOrderMutation,
      CreateOrderMutationVariables
    >(CreateOrderDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateOrderMutationVariables = {
            input: {
              constructionManagerOrDriver: {
                id: values.constructionManagerOrDriver
              },
              endDate: values.endDate && values.endDate.unix(),
              location: values.location,
              minimalSalaryEU: values.minimalSalaryEU,
              name: values.name,
              passengers: values.passengers
                ? values.passengers.map((id: number) => ({
                    id
                  }))
                : [],
              payerOfVAT: !!values.payerOfVAT,
              priceOffer: values.priceOffer,
              primaryCurrency: { id: values.primaryCurrency },
              secondaryCurrencies: values.secondaryCurrencies
                ? values.secondaryCurrencies.map((id: number) => ({
                    id
                  }))
                : [],
              startDate: values.startDate && values.startDate.unix(),
              state: { id: values.state },
              subscriber: { id: values.subscriber },
              travelOrderCode: values.travelOrderCode,
              department: values.department,
              invoiceable: values.invoiceable,
              vehicles: values.vehicles
                ? values.vehicles.map((id: number) => ({
                    id
                  }))
                : []
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createOrder) {
            response.data.createOrder.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Název zakázky">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: `Zadejte název zakázky`
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Cenová nabídka">
          {getFieldDecorator("priceOffer", {
            rules: [
              {
                required: true,
                message: `Zadejte cenovou nabídku`
              }
            ]
          })(<InputNumber precision={0} />)}
        </Form.Item>

        <Form.Item label={`Fakturuje se`}>
          {getFieldDecorator("invoiceable", {
            rules: [
              {
                required: true,
                message: `Povinné pole`
              }
            ]
          })(
            <Select placeholder={`Vyberte hodnotu`}>
              <Option key={1} value={1}>
                {`Ano`}
              </Option>
              <Option key={0} value={0}>
                {`Ne`}
              </Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Primární měna`}>
          {getFieldDecorator("primaryCurrency", {
            rules: [
              {
                required: true,
                message: `Vyberte měnu`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte měnu`}
              loading={currency.loading}
              disabled={!(currency.data && currency.data.currency.length > 0)}
            >
              {currency.data &&
                currency.data.currency
                  .filter(currency => {
                    const secondaryCurrenciesField: number[] = getFieldValue(
                      `secondaryCurrencies`
                    );
                    if (secondaryCurrenciesField) {
                      return !secondaryCurrenciesField.includes(currency.id);
                    }

                    return true;
                  })
                  .map(currency => (
                    <Option key={currency.id} value={currency.id}>
                      {currency.codeName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Sekundární měny`}>
          {getFieldDecorator("secondaryCurrencies")(
            <Select
              mode={"multiple"}
              placeholder={`Vyberte měnu`}
              loading={currency.loading}
              disabled={!(currency.data && currency.data.currency.length > 0)}
            >
              {currency.data &&
                currency.data.currency
                  .filter(currency => {
                    const currencyField: number = getFieldValue(
                      `primaryCurrency`
                    );
                    return currencyField !== currency.id;
                  })
                  .map(currency => (
                    <Option key={currency.id} value={currency.id}>
                      {currency.codeName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Plátce DPH">
          {getFieldDecorator("payerOfVAT", { initialValue: true })(
            <Checkbox defaultChecked />
          )}
        </Form.Item>

        <Form.Item label={`Odběratel`}>
          {getFieldDecorator("subscriber", {
            rules: [
              {
                required: true,
                message: `Vyberte odběratele`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte odběratele`}
              loading={states.loading}
              disabled={
                !(subscribers.data && subscribers.data.subscribers.length > 0)
              }
            >
              {subscribers.data &&
                subscribers.data.subscribers.map(state => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Třímistný kód cestovního příkazu">
          {getFieldDecorator("travelOrderCode", {
            rules: [
              {
                required: true,
                message: `Zadejte třímistný kód cestovního příkazu`
              },
              {
                validator: (rule, value, callback) => {
                  if (value && !(value.length === 3)) {
                    callback(
                      "Třímistný kód cestovního příkazu musí obsahovat 3 znaky"
                    );
                  } else {
                    callback();
                  }
                }
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Útvar">
          {getFieldDecorator("department", {
            rules: [
              {
                required: true,
                message: `Zadejte útvar`
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Začátek zakázky">
          {getFieldDecorator("startDate", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum začátku zakázky"
              }
            ]
          })(
            <DatePicker format={`DD.MM.YYYY`} placeholder={`Vyberte datum`} />
          )}
        </Form.Item>

        <Form.Item label="Konec zakázky">
          {getFieldDecorator("endDate")(
            <DatePicker format={`DD.MM.YYYY`} placeholder={`Vyberte datum`} />
          )}
        </Form.Item>

        <Form.Item label="Místo realizace">
          {getFieldDecorator("location", {
            rules: [
              {
                required: true,
                message: `Zadejte místo realizace`
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Stát realizace`}>
          {getFieldDecorator("state", {
            rules: [
              {
                required: true,
                message: `Vyberte stát realizace`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte stát realizace`}
              loading={states.loading}
              disabled={!(states.data && states.data.states.length > 0)}
            >
              {states.data &&
                states.data.states.map(state => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Minimální mzda EU`}>
          {getFieldDecorator("minimalSalaryEU", {
            rules: [
              {
                required: true,
                message: `Povinné pole`
              }
            ]
          })(
            <Select placeholder={`Vyberte hodnotu`}>
              <Option key={1} value={1}>
                {`Ano`}
              </Option>
              <Option key={0} value={0}>
                {`Ne`}
              </Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Stavbyvedoucí/řidič`}>
          {getFieldDecorator("constructionManagerOrDriver", {
            rules: [
              {
                required: true,
                message: `Vyberte stavbyvedoucího/řidiče`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte stavbyvedoucího/řidiče`}
              loading={employees.loading}
              disabled={
                !(employees.data && employees.data.employees.length > 0)
              }
            >
              {employees.data &&
                employees.data.employees
                  .filter(employee => {
                    const passengersField: number[] = getFieldValue(
                      `passengers`
                    );
                    if (passengersField) {
                      return !passengersField.includes(employee.id);
                    }

                    return true;
                  })
                  .map(employee => (
                    <Option key={employee.id} value={employee.id}>
                      {employee.fullName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Spolucestující`}>
          {getFieldDecorator("passengers")(
            <Select
              mode={`multiple`}
              placeholder={`Vyberte spolucestující`}
              loading={employees.loading}
              disabled={
                !(employees.data && employees.data.employees.length > 0)
              }
            >
              {employees.data &&
                employees.data.employees
                  .filter(employee => {
                    const employeeField: number = getFieldValue(
                      `constructionManagerOrDriver`
                    );
                    return employeeField !== employee.id;
                  })
                  .map(employee => (
                    <Option key={employee.id} value={employee.id}>
                      {employee.fullName}
                    </Option>
                  ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Vozidlo`}>
          {getFieldDecorator("vehicles")(
            <Select
              placeholder={`Vyberte vozidlo`}
              mode={`multiple`}
              loading={vehicles.loading}
              disabled={!(vehicles.data && vehicles.data.vehicles.length > 0)}
            >
              {vehicles.data &&
                vehicles.data.vehicles.map(vehicle => (
                  <Option key={vehicle.id} value={vehicle.id}>
                    {vehicle.codeName}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
