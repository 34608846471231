import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables
} from "../../../graphql/generated";
import moment from "moment";
import { WorkDescriptionForm } from "../../../forms/WorkDescriptionForm";
export interface CreateWorkDescriptionViewProps {}

export const CreateWorkDescriptionReportView: React.FC<
  CreateWorkDescriptionViewProps
> = (props: CreateWorkDescriptionViewProps): React.ReactElement => {
  const title: string = `Popis práce`;
  const { Title } = Typography;
  const [date, setDate] = React.useState<number>(moment().unix());

  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    variables: { date }
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <WorkDescriptionForm orders={orders} />
          </Col>
        </Row>
      </div>
    </>
  );
};
