import * as React from "react";
import { Form, DatePicker, InputNumber, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment, { Moment } from "moment";
import {
  DefaultExchangeRateEurczkQuery,
  DefaultExchangeRateEurczkQueryVariables,
  Notification,
  PayoutsTableQueryVariables
} from "../../../graphql/generated";
import { QueryHookResult } from "react-apollo-hooks";

export interface PayoutsFilterFormProps extends FormComponentProps {
  setDate: (date: number) => void;
  refetch: (variables: PayoutsTableQueryVariables) => void;
  defaultExchangeRate: QueryHookResult<
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables
  >;
}

export const PayoutsFilterForm = Form.create<PayoutsFilterFormProps>({
  name: `PayoutsFilterForm`
})(
  (props: PayoutsFilterFormProps): React.ReactElement => {
    const { form, setDate, refetch, defaultExchangeRate } = props;
    const { getFieldDecorator, validateFields } = form;
    const { MonthPicker } = DatePicker;

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: PayoutsTableQueryVariables = {
            exchangeRateEURCZK: values.exchangeRateEURCZK,
            monthTimestamp: values.monthTimestamp ? values.monthTimestamp.unix() : null
          };

          setDate(variables.monthTimestamp);
          refetch(variables);
        }
      });
    };

    return (
      // TODO: set labelCol & wrapperCol as default
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
        onSubmit={onSubmit}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("monthTimestamp", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(<MonthPicker format={`MM.YYYY (MMMM)`} />)}
        </Form.Item>

        <Form.Item label={`Kurz CZK/EUR`}>
          {getFieldDecorator("exchangeRateEURCZK", {
            initialValue:
              defaultExchangeRate.data &&
              defaultExchangeRate.data.defaultExchangeRateEURCZK,
            rules: [
              {
                required: true,
                message: `Vyplňte kurz`
              }
            ]
          })(
            <InputNumber
              decimalSeparator={`,`}
              disabled={!defaultExchangeRate.data}
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Vypsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
