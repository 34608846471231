import * as React from "react";
import {
  Form,
  DatePicker,
  InputNumber,
  Button,
  Select
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import {
  DefaultExchangeRateEurczkQuery,
  DefaultExchangeRateEurczkQueryVariables,
  EconomicsTableQueryVariables,
  OrdersQuery,
  OrdersQueryVariables
} from "../../../graphql/generated";
import { QueryHookResult } from "react-apollo-hooks";
import moment, { Moment } from "moment";

export interface EconomicsFilterFormProps extends FormComponentProps {
  refetch: (variables: EconomicsTableQueryVariables) => void;
  setSubmitted: () => void;
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
  defaultExchangeRate: QueryHookResult<
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables
  >;
}

export const EconomicsFilterForm = Form.create<EconomicsFilterFormProps>({
  name: `EconomicsFilterForm`
})(
  (props: EconomicsFilterFormProps): React.ReactElement => {
    const { form, refetch, setSubmitted, orders, defaultExchangeRate } = props;
    const { getFieldDecorator, validateFields } = form;
    const { MonthPicker, WeekPicker } = DatePicker;
    const { Option } = Select;

    const [isMonthTimestampDisabled, setMonthTimestampDisabled] = React.useState<boolean>(false);
    const [isWeekTimestampDisabled, setWeekTimestampDisabled] = React.useState<boolean>(false);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: EconomicsTableQueryVariables = {
            order: {
                id: values.order,
            },
            monthTimestamp: values.month ? values.month.unix() : null,
            weekTimestamp: values.week ? values.week.unix() : null,
            // TODO: rename week -> weekTimestamp, month -> monthTimestamp
            margin: values.margin,
            transportCostPerUnit: values.transportCostPerUnit,
            transportCustomerPerUnit: values.transportCustomerPerUnit,
            customerHourlyRate: values.customerHourlyRate,
            exchangeRateEURCZK: values.exchangeRateEURCZK,
          };

          refetch(variables);
          setSubmitted();
        }
      });
    };

    return (
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
        onSubmit={onSubmit}
      >
        {/* TODO: use RangePicker and implement it on server API */}
        <Form.Item label="Měsíc">
          {getFieldDecorator("month")(
            <MonthPicker
              format={`Mo [měsíc] YYYY`}
              disabled={isMonthTimestampDisabled}
              onChange={async (date: Moment | null) => {
                setWeekTimestampDisabled(!!date);
                orders.refetch({ date: date ? date.unix() : null });
              }}
            />
          )}
        </Form.Item>

        <Form.Item label="Týden">
          {getFieldDecorator("week")(
            <WeekPicker
              format={`wo [týden] YYYY`}
              disabled={isWeekTimestampDisabled}
              onChange={async (date: Moment | null) => {
                setMonthTimestampDisabled(!!date);
                orders.refetch({ date: date ? date.unix() : null });
              }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order")(
            <Select
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                  typeof option.props.children === 'string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Marže %`}>
          {getFieldDecorator("margin", {
            initialValue: 10, // TODO: configurable on server API
            rules: [
              {
                required: true,
                message: `Zadejte marži`
              }
            ]
          })(
            <InputNumber
              decimalSeparator={`,`}
            />
          )}
        </Form.Item>

        <Form.Item label={`Doprava náklad Kč/km`}>
          {getFieldDecorator("transportCostPerUnit", {
            initialValue: 8, // TODO: configurable on server API
            rules: [
              {
                required: true,
                message: `Zadejte vlastní náklad dopravy`
              }
            ]
          })(
            <InputNumber
              decimalSeparator={`,`}
            />
          )}
        </Form.Item>

        <Form.Item label={`Doprava zákazník Kč/km`}>
          {getFieldDecorator("transportCustomerPerUnit", {
            initialValue: 14, // TODO: configurable on server API
            rules: [
              {
                required: true,
                message: `Zadejte cenu dopravy účtovanou zákazníkovi`
              }
            ]
          })(
            <InputNumber
              decimalSeparator={`,`}
            />
          )}
        </Form.Item>

        <Form.Item label={`Mzda zákazník Kč/hod`}>
          {getFieldDecorator("customerHourlyRate", {
            initialValue: 320, // TODO: configurable on server API
            rules: [
              {
                required: true,
                message: `Zadejte hodinovou sazbu účtovanou zákazníkovi`
              }
            ]
          })(
            <InputNumber
              decimalSeparator={`,`}
            />
          )}
        </Form.Item>

        <Form.Item label={`Kurz CZK/EUR`}>
          {getFieldDecorator("exchangeRateEURCZK", {
            initialValue:
              defaultExchangeRate.data &&
              defaultExchangeRate.data.defaultExchangeRateEURCZK,
            rules: [
              {
                required: true,
                message: `Vyplňte kurz`
              }
            ]
          })(
            <InputNumber
              decimalSeparator={`,`}
              disabled={!defaultExchangeRate.data}
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button no-print"
            style={{ marginRight: '3%' }}
          >
            {`Vypsat`}
          </Button>

          <Button
            type="default"
            htmlType="submit"
            className="login-form-button no-print"
            onClick={() => window.print()}
          >
            {`Vytvořit tiskovou sestavu`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
