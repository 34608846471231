import * as React from "react";
import { Icon, Layout, Menu, Badge, Spin } from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { RouteComponentProps, withRouter } from "react-router";
import { SiderTheme } from "antd/lib/layout/Sider";
import { ThemeContext, ThemeContextState } from "../../contexts/ThemeContext";
import { AuthContext, AuthContextState } from "../../contexts/AuthContext";
import { Permission } from "../../graphql/generated";

interface MainMenuProps extends RouteComponentProps {}

export const MainMenu = withRouter(
  (props: MainMenuProps): React.ReactElement => {
    const { location } = props;
    const { pathname } = location;
    const { Sider } = Layout;
    const { SubMenu } = Menu;
    const theme: SiderTheme = `dark`;
    const { isBroken, setBroken } = React.useContext<ThemeContextState>(
      ThemeContext
    );
    const { dashboardNotifications, user } = React.useContext<AuthContextState>(
      AuthContext
    );

    const [isCollapsed, setCollapsed] = React.useState<boolean>(false);

    return (
      <Sider
        theme={theme}
        collapsible
        style={{
          height: `100vh`,
          position: `fixed`,
          top: 64,
          zIndex: 999
        }}
        breakpoint={`lg`}
        collapsedWidth={isBroken ? 0 : 80}
        onBreakpoint={(broken: boolean) => {
          setBroken(broken);
        }}
        className={`MainMenu no-print`}
        width={isBroken ? window.innerWidth : 250}
        onCollapse={(isCollapsed: boolean) => setCollapsed(isCollapsed)}
        collapsed={isCollapsed}
      >
        <div className="logo" />
        {user ? (
          <Menu
            theme={theme}
            mode="inline"
            selectedKeys={[pathname]}
            onClick={() => {
              if (isBroken) {
                setCollapsed(true);
              }
            }}
          >
            <Menu.Item
              key={ROUTES.DASHBOARD}
              hidden={!user.role.permissions.includes(Permission.Dashboard)}
            >
              <Link to={ROUTES.DASHBOARD}>
                {isCollapsed ? (
                  <>
                    <Icon type="home" />
                    <span>{`Úvodní stránka`}</span>
                  </>
                ) : (
                  <Badge
                    count={dashboardNotifications.length}
                    offset={[80, 5]}
                    style={{ boxShadow: `0 0 0 0 #fff` }}
                    overflowCount={9}
                  >
                    <Icon type="home" />
                    <span>{`Úvodní stránka`}</span>
                  </Badge>
                )}
              </Link>
            </Menu.Item>
            {(user.role.permissions.includes(Permission.HoursWriting) ||
              user.role.permissions.includes(Permission.DailyListing)) && (
              <SubMenu
                key="2"
                title={
                  <span>
                    <Icon type="clock-circle" />
                    <span>{`Hodiny`}</span>
                  </span>
                }
              >
                <Menu.Item
                  key={ROUTES.HOURS_WRITING}
                  hidden={
                    !user.role.permissions.includes(Permission.HoursWriting)
                  }
                >
                  <Link to={ROUTES.HOURS_WRITING}>{`Zápis hodin`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.DAILY_LISTING}
                  hidden={
                    !user.role.permissions.includes(Permission.DailyListing)
                  }
                >
                  <Link to={ROUTES.DAILY_LISTING}>{`Denní výpis`}</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {(user.role.permissions.includes(Permission.DailyReport) ||
              user.role.permissions.includes(Permission.MaterialReport) ||
              user.role.permissions.includes(Permission.TravelCostsReport) ||
              user.role.permissions.includes(Permission.AccommodationReport) ||
              user.role.permissions.includes(Permission.WorkDescription) ||
              user.role.permissions.includes(Permission.DailyReports)) && (
              <SubMenu
                key="5"
                title={
                  <span>
                    <Icon type="solution" />
                    <span>{`Výkazy`}</span>
                  </span>
                }
              >
                <Menu.Item
                  key={ROUTES.DAILY_REPORT}
                  hidden={
                    !user.role.permissions.includes(Permission.DailyReport)
                  }
                >
                  <Link to={ROUTES.DAILY_REPORT}>{`Denní výkaz`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.MATERIAL_REPORT}
                  hidden={
                    !user.role.permissions.includes(Permission.MaterialReport)
                  }
                >
                  <Link to={ROUTES.MATERIAL_REPORT}>{`Výkaz materiálů`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.TRAVEL_COSTS_REPORT}
                  hidden={
                    !user.role.permissions.includes(
                      Permission.TravelCostsReport
                    )
                  }
                >
                  <Link
                    to={ROUTES.TRAVEL_COSTS_REPORT}
                  >{`Výkaz cestovních nákladů`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.ACCOMMODATION_REPORT}
                  hidden={
                    !user.role.permissions.includes(
                      Permission.AccommodationReport
                    )
                  }
                >
                  <Link
                    to={ROUTES.ACCOMMODATION_REPORT}
                  >{`Výkaz ubytování`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.WORK_DESCRIPTION}
                  hidden={
                    !user.role.permissions.includes(Permission.WorkDescription)
                  }
                >
                  <Link to={ROUTES.WORK_DESCRIPTION}>{`Popis práce`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.DAILY_REPORTS}
                  hidden={
                    !user.role.permissions.includes(Permission.DailyReports)
                  }
                >
                  <Link to={ROUTES.DAILY_REPORTS}>{`Výpis výkazů`}</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {(user.role.permissions.includes(Permission.TravelOrder) ||
              user.role.permissions.includes(Permission.TravelOrderRoute) ||
              user.role.permissions.includes(Permission.TravelOrders)) && (
              <SubMenu
                key="10"
                title={
                  <span>
                    <Icon type="car" />
                    <span>Kniha jízd</span>
                  </span>
                }
              >
                <Menu.Item
                  key={ROUTES.TRAVEL_ORDER}
                  hidden={
                    !user.role.permissions.includes(Permission.TravelOrder)
                  }
                >
                  <Link to={ROUTES.TRAVEL_ORDER}>{`Nový cestovní příkaz`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.TRAVEL_ORDER_ROUTE}
                  hidden={
                    !user.role.permissions.includes(Permission.TravelOrderRoute)
                  }
                >
                  <Link to={ROUTES.TRAVEL_ORDER_ROUTE}>{`Zápis cesty`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.TRAVEL_ORDERS}
                  hidden={
                    !user.role.permissions.includes(Permission.TravelOrders)
                  }
                >
                  <Link
                    to={ROUTES.TRAVEL_ORDERS}
                  >{`Výpis cestovních příkazů`}</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {(user.role.permissions.includes(Permission.Tasks) ||
              user.role.permissions.includes(Permission.Calendar) ||
              user.role.permissions.includes(Permission.ActivityHistory)) && (
              <SubMenu
                key="22"
                title={
                  <span>
                    <Icon type="carry-out" />
                    <span>{`Organizace`}</span>
                  </span>
                }
              >
                <Menu.Item
                  key={ROUTES.TASKS}
                  hidden={
                    !user.role.permissions.includes(Permission.Tasks)
                  }
                >
                  <Link to={ROUTES.TASKS}>{`Seznam úkolů`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.CALENDAR}
                  hidden={
                    !user.role.permissions.includes(Permission.Calendar)
                  }
                >
                  <Link to={ROUTES.CALENDAR}>{`Kalendář`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.ACTIVITY_HISTORY}
                  hidden={
                    !user.role.permissions.includes(Permission.ActivityHistory)
                  }
                >
                  <Link to={ROUTES.ACTIVITY_HISTORY}>{`Log aktivit`}</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {(user.role.permissions.includes(Permission.Prepayment) ||
              user.role.permissions.includes(
                Permission.OperatingExpenditure
              )) && (
              <SubMenu
                key="13"
                title={
                  <span>
                    <Icon type="dollar" />
                    <span>{`Zálohy/provoz`}</span>
                  </span>
                }
              >
                <Menu.Item
                  key={ROUTES.PREPAYMENT}
                  hidden={
                    !user.role.permissions.includes(Permission.Prepayment)
                  }
                >
                  <Link to={ROUTES.PREPAYMENT}>{`Vyplacená záloha`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.PREPAYMENTS}
                  hidden={
                    !user.role.permissions.includes(Permission.Prepayments)
                  }
                >
                  <Link to={ROUTES.PREPAYMENTS}>{`Výpis záloh`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.OPERATING_EXPENDITURE}
                  hidden={
                    !user.role.permissions.includes(
                      Permission.OperatingExpenditure
                    )
                  }
                >
                  <Link
                    to={ROUTES.OPERATING_EXPENDITURE}
                  >{`Vyplacený provozní výdaj`}</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {(user.role.permissions.includes(Permission.CreateOrder) ||
              user.role.permissions.includes(
                Permission.CreateMaterialSupplier
              ) ||
              user.role.permissions.includes(Permission.CreateSubscriber) ||
              user.role.permissions.includes(Permission.EmployeesLoans) ||
              user.role.permissions.includes(Permission.Penalty)) && (
              <SubMenu
                key="16"
                title={
                  <span>
                    <Icon type="setting" />
                    <span>Administrace/Vkládání</span>
                  </span>
                }
              >
                <Menu.Item
                  key={ROUTES.CREATE_ORDER}
                  hidden={
                    !user.role.permissions.includes(Permission.CreateOrder)
                  }
                >
                  <Link to={ROUTES.CREATE_ORDER}>{`Vložení zakázky`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.CREATE_MATERIAL_SUPPLIER}
                  hidden={
                    !user.role.permissions.includes(
                      Permission.CreateMaterialSupplier
                    )
                  }
                >
                  <Link to={ROUTES.CREATE_MATERIAL_SUPPLIER}>
                    {`Vložení dodavatele materiálu`}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.CREATE_SUBSCRIBER}
                  hidden={
                    !user.role.permissions.includes(Permission.CreateSubscriber)
                  }
                >
                  <Link
                    to={ROUTES.CREATE_SUBSCRIBER}
                  >{`Vložení odběratele`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.EMPLOYEES_LOANS}
                  hidden={
                    !user.role.permissions.includes(Permission.EmployeesLoans)
                  }
                >
                  <Link
                    to={ROUTES.EMPLOYEES_LOANS}
                  >{`Půjčky zaměstnancům`}</Link>
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.PENALTY}
                  hidden={!user.role.permissions.includes(Permission.Penalty)}
                >
                  <Link to={ROUTES.PENALTY}>{`Pokuty`}</Link>
                </Menu.Item>
              </SubMenu>
            )}
            <Menu.Item
              key={ROUTES.PAVELS}
              hidden={!user.role.permissions.includes(Permission.Pavels)}
            >
              <Link to={ROUTES.PAVELS}>
                <Icon type="table" />
                <span>{`Pavlovka`}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.PAYOUTS}
              hidden={!user.role.permissions.includes(Permission.Payouts)}
            >
              <Link to={ROUTES.PAYOUTS}>
                <Icon type="table" />
                <span>{`Tabulka`}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.ECONOMICS}
              hidden={!user.role.permissions.includes(Permission.Economics)}
            >
              <Link to={ROUTES.ECONOMICS}>
                <Icon type="stock" />
                <span>{`Ekonomie staveb`}</span>
              </Link>
            </Menu.Item>
          </Menu>
        ) : (
          <Menu theme={theme} mode="inline">
            <Menu.Item>
              <Spin style={{ marginRight: 10 }} />
              {`Načítání...`}
            </Menu.Item>
          </Menu>
        )}
      </Sider>
    );
  }
);
