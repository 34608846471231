import * as React from "react";
import { Form, Button, DatePicker, Select, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { QueryHookResult, useMutation } from "react-apollo-hooks";
import moment, { Moment } from "moment";
import {
  CreateWorkDescriptionReportDocument,
  CreateWorkDescriptionReportMutation,
  CreateWorkDescriptionReportMutationVariables,
  Notification,
  OrdersQuery,
  OrdersQueryVariables
} from "../../graphql/generated";
import { getValidateStatus } from "../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";

export interface WorkDescriptionFormProps extends FormComponentProps {
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
}

export const WorkDescriptionForm = Form.create<WorkDescriptionFormProps>({
  name: `WorkDescriptionForm`
})(
  (props: WorkDescriptionFormProps): React.ReactElement => {
    const { form, orders } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;
    const { TextArea } = Input;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      CreateWorkDescriptionReportMutation,
      CreateWorkDescriptionReportMutationVariables
    >(CreateWorkDescriptionReportDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateWorkDescriptionReportMutationVariables = {
            input: {
              date: values.date.unix(),
              order: { id: values.order },
              description: values.description
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (
            response &&
            response.data &&
            response.data.createWorkDescriptionReport
          ) {
            response.data.createWorkDescriptionReport.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY`}
              onChange={async (date: Moment | null) => {
                if (date) {
                  orders.refetch({ date: date.unix() });
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order", {
            rules: [
              {
                required: true,
                message: `Vyberte zakázku`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
              onChange={async (id: number) => {
                // await getCurrency({ variables: { order: { id } } });
              }}
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Popis`}>
          {getFieldDecorator("description", {
            rules: [
              {
                required: true,
                message: `Vyplňte popis`
              }
            ]
          })(<TextArea rows={6} />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
