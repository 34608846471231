import * as React from "react";
import { Form, Input, Button, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { QueryHookResult, useMutation } from "react-apollo-hooks";
import {
  CreateTaskMutation,
  CreateTaskMutationVariables,
  CreateTaskDocument,
  Notification, EmployeesQuery, EmployeesQueryVariables
} from "../../graphql/generated";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts";

export interface CreateTaskFormProps extends FormComponentProps {
  onTaskCreate: () => void;
  // employees: QueryHookResult<EmployeesQuery, EmployeesQueryVariables>;
}

export const CreateTaskForm = Form.create<CreateTaskFormProps>({
  name: `CreateTaskForm`
})(
  (props: CreateTaskFormProps): React.ReactElement => {
    // const { form, onTaskCreate, employees } = props;
    const { form, onTaskCreate } = props;
    const { Option } = Select;
    const {
      getFieldDecorator,
      validateFields,
      resetFields
    } = form;

    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const employees = [
      {'id': 1, 'fullName': 'Pavel Adam'},
      {'id': 80, 'fullName': 'Marek Hanuš'},
      {'id': 81, 'fullName': 'Barbora Ševčíková'},
      {'id': 120, 'fullName': 'Pavla Grigarová'},  // TODO: fix 121 -> 120
    ];

    const [submit] = useMutation<
      CreateTaskMutation,
      CreateTaskMutationVariables
    >(CreateTaskDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateTaskMutationVariables = {
            input: {
              employee: {
                id: values.employee[0]
              },
              description: values.description,
              // assignments: values.employee,
              assignments: values.employee.map((i: any, x: any) => {return {
                  id: i,
              }})
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createTask) {
            response.data.createTask.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
            onTaskCreate();
            window.location.reload(); // TODO: fixme
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        layout="inline"
      >
        <Form.Item label="Přidat úkol">
          {getFieldDecorator("description", {
            rules: [
              {
                required: true,
                validator: (rule, value, callback) => {
                  if (!(value && value.trim().length > 0)) {
                    callback("Zadejte popis úkolu");
                  } else {
                    callback();
                  }
                }
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Zaměstnanci`}>
          {getFieldDecorator("employee", {
            rules: [
              {
                required: true,
                message: `Vyberte zaměstnance`
              }
            ]
          })(
            <Select
              mode="multiple"
              allowClear
              placeholder={`Vyberte zaměstnance`}
              // loading={employees.loading}
              // disabled={
              //   !(employees.data && employees.data.employees.length > 0)
              // }
              style={{ width: 200 }} // TODO: formátování exportovat IDEA code style XML/.editorconfig
            >
              {/*{employees.data &&*/}
              {/*  employees.data.employees*/}
              {/*    .filter(employee => {*/}
              {/*      const employeesWithTasksPermission = [1, 80, 81, 120];*/}
              {/*      return employeesWithTasksPermission.includes(employee.id);*/}
              {/*      return [1, 80, 81, 120].includes(employee.id);*/}
              {/*    })*/}
              {employees.map(employee => (
                <Option key={employee.id} value={employee.id}>
                  {employee.fullName}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
