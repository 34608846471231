import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  CurrencyDocument,
  CurrencyQuery,
  CurrencyQueryVariables,
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables,
  StatesDocument,
  StatesQuery,
  StatesQueryVariables,
  SubscribersDocument,
  SubscribersQuery,
  SubscribersQueryVariables,
  VehiclesDocument,
  VehiclesQuery,
  VehiclesQueryVariables
} from "../../../graphql/generated";
import { CreateOrderForm } from "../../../forms";

export interface CreateOrderViewProps {}

export const CreateOrderView: React.FC<CreateOrderViewProps> = (
  props: CreateOrderViewProps
): React.ReactElement => {
  const title: string = `Vložení zakázky`;
  const { Title } = Typography;

  const employees = useQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument
  );

  const currency = useQuery<CurrencyQuery, CurrencyQueryVariables>(
    CurrencyDocument
  );

  const states = useQuery<StatesQuery, StatesQueryVariables>(StatesDocument);

  const subscribers = useQuery<SubscribersQuery, SubscribersQueryVariables>(
    SubscribersDocument
  );

  const vehicles = useQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <CreateOrderForm
              employees={employees}
              currency={currency}
              states={states}
              subscribers={subscribers}
              vehicles={vehicles}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
