import * as React from "react";
import { Notification, NotificationType } from "../../graphql/generated";
import { message } from "antd";
export interface NotificationContextState {
  pushNotification: (notification: Notification) => void;
}

const defaultValue: NotificationContextState = {
  pushNotification: () => null
};

const DURATION: number = 5;

export const NotificationContext = React.createContext<
  NotificationContextState
>(defaultValue);

export const NotificationContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = (props: React.PropsWithChildren<unknown>): React.ReactElement => {
  const { children } = props;
  message.config({ top: 64 });
  const pushNotification = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.Success:
        return message.success(notification.message, DURATION);
      case NotificationType.Info:
        return message.info(notification.message, DURATION);
      case NotificationType.Error:
        return message.error(notification.message, DURATION);
      case NotificationType.Warning:
        return message.warning(notification.message, DURATION);
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        pushNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
