import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { HoursWritingForm } from "../../forms/HoursWritingForm";
import { useQuery } from "react-apollo";
import {
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables,
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables
} from "../../graphql/generated";
import moment from "moment";
export interface HoursWritingProps {}

export const HoursWriting: React.FC<HoursWritingProps> = (
  props: HoursWritingProps
): React.ReactElement => {
  const title: string = `Zápis hodin`;
  const { Title } = Typography;
  const [date, setDate] = React.useState<number>(moment().unix());
  // const orderId = null;

  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    variables: { date }
  });

  // const employees = useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, {
  //   variables: { date, orderId }
  // });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            {/*<HoursWritingForm orders={orders} employees={employees} />*/}
            <HoursWritingForm orders={orders} />
          </Col>
        </Row>
      </div>
    </>
  );
};
