import * as React from "react";
import { Form, Button, InputNumber, TimePicker } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation } from "react-apollo-hooks";
import {
  NotificationContext,
  NotificationContextState
} from "../../../contexts/NotificationContext";
import {
  Notification,
  UpdateUserHoursDocument,
  UpdateUserHoursMutation,
  UpdateUserHoursMutationVariables,
  UserHours
} from "../../../graphql/generated";
import moment, { Moment } from "moment";
import {
  getHoursAndMinutesFromNumber,
  getNumberFromMomentHours
} from "../../../utils";

export interface DailyListingEditFormProps extends FormComponentProps {
  userHours: UserHours;
  onSubmit: () => void;
}

export const DailyListingEditForm = Form.create<DailyListingEditFormProps>({
  name: `DailyListingEditForm`
})(
  (props: DailyListingEditFormProps): React.ReactElement => {
    const { form, userHours } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;

    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      UpdateUserHoursMutation,
      UpdateUserHoursMutationVariables
    >(UpdateUserHoursDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const hoursTo = values.to && getNumberFromMomentHours(values.to);

          const variables: UpdateUserHoursMutationVariables = {
            input: {
              id: userHours.id,
              hoursTo,
              hoursCount: values.hoursCount
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.updateUserHours) {
            response.data.updateUserHours.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );
          }

          props.onSubmit();
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Zakázka">
          <span className="ant-form-text">{userHours.order.name}</span>
        </Form.Item>

        <Form.Item label="Zaměstnanec">
          <span className="ant-form-text">{userHours.employee.fullName}</span>
        </Form.Item>

        <Form.Item label="Tarif">
          <span className="ant-form-text">{userHours.rate.name}</span>
        </Form.Item>

        <Form.Item label="Hodiny od">
          <span className="ant-form-text">
            {getHoursAndMinutesFromNumber(userHours.hoursFrom)}
          </span>
        </Form.Item>

        <Form.Item label="Počet hodin">
          {getFieldDecorator("hoursCount", {
            initialValue: userHours.hoursCount
          })(
            <InputNumber
              min={0}
              max={24}
              step={0.5}
              decimalSeparator={`,`}
              precision={1}
              onChange={value => {
                const duration = moment.duration(value, `hours`);
                const from: Moment = moment(
                  getHoursAndMinutesFromNumber(userHours.hoursFrom),
                  `HH:mm`
                );
                const to: Moment = moment(
                  `${duration.hours()}:${duration.minutes()}`,
                  `HH:mm`
                );
                const fromHours: number = from.hours();
                const fromMinutes: number = from.minutes();
                const toHours: number = to.hours();
                const toMinutes: number = to.minutes();

                const hours: number = fromHours + toHours;
                const minutes: number = fromMinutes + toMinutes;
                if (
                  value &&
                  value < 24 &&
                  hours >= 0 &&
                  hours < 24 &&
                  minutes >= 0 &&
                  minutes <= 60
                ) {
                  if (minutes === 60) {
                    setFieldsValue({
                      to: moment(`${hours + 1}:00`, `HH:mm`)
                    });
                  } else {
                    setFieldsValue({
                      to: moment(`${hours}:${minutes}`, `HH:mm`)
                    });
                  }
                } else {
                  setFieldsValue({
                    to: null
                  });
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item label="Hodiny do">
          {getFieldDecorator("to", {
            initialValue:
              userHours.hoursTo &&
              moment(getHoursAndMinutesFromNumber(userHours.hoursTo), `HH:mm`)
          })(
            <TimePicker
              minuteStep={30}
              format={`HH:mm`}
              placeholder={`Zadejte čas`}
              onChange={time => {
                let fromHours: number = 0;
                let fromMinutes: number = 0;
                let toHours: number = 0;
                let toMinutes: number = 0;

                const fromVal = getFieldValue(`from`);

                if (fromVal) {
                  fromHours = parseInt(fromVal.format(`HH`));
                  fromMinutes = parseInt(fromVal.format(`mm`));
                }
                if (time) {
                  toHours = parseInt(time.format(`HH`));
                  toMinutes = parseInt(time.format(`mm`));
                }

                const hours = toHours - fromHours;
                const minutes = toMinutes - fromMinutes;

                if (hours > 0 || minutes > 0) {
                  const val = moment.duration(`${hours}:${minutes}`);

                  setFieldsValue({
                    [`hoursCount`]: val.asHours()
                  });
                } else {
                  resetFields([`hoursCount`]);
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
