import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { App, ROUTES } from "./App";
import { ApolloClient, InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider as ApolloProviderHooks } from "react-apollo-hooks";
import {
  AuthContextProvider,
  NotificationContextProvider,
  ThemeContextProvider
} from "./contexts";
import "moment/locale/cs";
import { LocaleProvider } from "antd";
import cs_CZ from "antd/lib/locale-provider/cs_CZ";
import moment from "moment";
moment.locale("cs");

const container = document.getElementById("root");

const token = localStorage.getItem(`token`);

// docker run -v "$PWD":/app -w /app node:alpine sh -c 'yarn install'
// docker run -v "$PWD":/app -w /app node:alpine yarn install
const link = createHttpLink({
  // TODO: DEPLOY + make backup
  // uri: `http://server.adam-epox.marekhanus.cz/graphql`,
  // uri: `//dev.server.adam-epox.marekhanus.cz/graphql`,
  uri: `//server.adam-epox.marekhanus.cz/graphql`, // production
  // uri: `http://ozalfa-pipelines.atlascon.cz:5001/graphql`,
  // uri: `http://pydev.atlascon.cz:5001/graphql`,
  // uri: `http://192.168.77.202:5000/graphql`,
  headers: {
    Authorization: `Bearer ${token}`,
    [`user-agent`]: `JS GraphQL`
  }
});
const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache"
    },
    watchQuery: { fetchPolicy: "no-cache" }
  }
});

window.onerror = () => {
  if (
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1"
  ) {
    window.location.replace(`${ROUTES.ERROR}`);
    localStorage.clear();
  }
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloProviderHooks client={client}>
      <LocaleProvider locale={cs_CZ}>
        <ThemeContextProvider>
          <AuthContextProvider>
            <NotificationContextProvider>
              <App />
            </NotificationContextProvider>
          </AuthContextProvider>
        </ThemeContextProvider>
      </LocaleProvider>
    </ApolloProviderHooks>
  </ApolloProvider>,
  container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
