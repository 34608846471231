import * as React from "react";
import { Popconfirm } from "antd";

export interface PopConfirmProps {
  onConfirm: () => void;
  onCancel?: () => void;
}

export const PopConfirm: React.FC<React.PropsWithChildren<PopConfirmProps>> = (
  props: React.PropsWithChildren<PopConfirmProps>
) => {
  const { children, onConfirm, onCancel } = props;

  return (
    <Popconfirm
      title={`Opravdu chcete odstranit tento záznam?`}
      cancelText={`Ne`}
      okText={`Ano`}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      {children}
    </Popconfirm>
  );
};
