import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { CreateMaterialSupplierForm } from "../../../forms/CreateMaterialSupplierForm";
export interface CreateSupplierProps {}

export const CreateMaterialSupplierView: React.FC<CreateSupplierProps> = (
  props: CreateSupplierProps
): React.ReactElement => {
  const title: string = `Vložení dodavatele materiálu`;
  const { Title } = Typography;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <CreateMaterialSupplierForm />
          </Col>
        </Row>
      </div>
    </>
  );
};
