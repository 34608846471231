import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Col,
  Row,
  Typography,
  Divider,
  Alert,
  Result,
  Icon,
  Spin,
  Timeline,
  Checkbox,
  List,
  Avatar,
  DatePicker
} from "antd";
import {
  Event as EventAttribute,
  EventsDocument,
  EventsQuery,
  EventsQueryVariables,
  NotificationType,
  Task,
  MyTasksDocument,
  MyTasksQuery,
  MyTasksQueryVariables,
  User,
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
  UpdateTaskDocument,
  Notification
} from "../../graphql/generated";
import { AuthContext, AuthContextState } from "../../contexts/AuthContext";
import { useMutation, useQuery } from "react-apollo-hooks";
import { createMarkup, linkify } from "../../utils";
import moment, { Moment } from "moment";
export interface DashboardProps {
  user: User | null | undefined;
  notificationsLoading: boolean;
}

export const Dashboard: React.FC<DashboardProps> = (
  props: DashboardProps
): React.ReactElement => {
  const { user, notificationsLoading } = props;
  const title: string = `Úvodní stránka`;
  const { Title } = Typography;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            {user ? (
              <Title>{`Vítejte, ${user.fullName}.`} </Title>
            ) : (
              <Title>
                <Spin style={{ marginRight: 15 }} />
                {`Načítání uživatele...`}
              </Title>
            )}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <TasksWidget />
          </Col>
          <Col span={12}>
            <CalendarWidget />
          </Col>
          <Col span={12}>
            <NotificationsWidget notificationsLoading={notificationsLoading} />
          </Col>
          <Col span={12}>
            {/* TODO: 1 */}
            {/*<ActivityHistoryWidget />*/}
          </Col>
        </Row>
      </div>
    </>
  );
};

// po upgrade ant designu rozdělit na 2 sloupce
const NotificationsWidget: React.FC<{
  notificationsLoading: boolean;
}> = props => {
  const { notificationsLoading } = props;
  const { Title } = Typography;
  const { dashboardNotifications } = React.useContext<AuthContextState>(
    AuthContext
  );

  return (
    <>
      <Title level={3}>Notifikace</Title>
      {notificationsLoading ? (
        <Result
          status={`info`}
          icon={<Spin />}
          title="Načítání notifikací..."
        />
      ) : dashboardNotifications.length ? (
        <Result
          status="warning"
          title={`Počet nevyřízených notifikací: ${dashboardNotifications.length}`}
          extra={
            <>
              {dashboardNotifications.map((notification, key) => {
                let message: string = ``;
                switch (notification.type) {
                  case NotificationType.Success:
                    message = `Úspěch`;
                    break;
                  case NotificationType.Info:
                    message = `Informace`;
                    break;
                  case NotificationType.Warning:
                    message = `Varování`;
                    break;
                  case NotificationType.Error:
                    message = `Chyba`;
                    break;
                }

                return (
                  <Row
                    key={key}
                    style={{ marginBottom: 15, textAlign: `left` }}
                    type={`flex`}
                    justify={`center`}
                    align={`middle`}
                  >
                    <Col lg={{ span: 14 }} xs={{ span: 68 }}>
                      <Alert
                        key={key}
                        showIcon
                        message={message}
                        description={notification.message}
                        type={
                          notification.type.toLowerCase() as
                            | "success"
                            | "info"
                            | "warning"
                            | "error"
                        }
                      />
                    </Col>
                  </Row>
                );
              })}
            </>
          }
        />
      ) : (
        <Result
          status="success"
          icon={<Icon type="smile" />}
          title="Nemáte žádné notifikace"
        />
      )}
    </>
  );
};

interface EventRow {
  key: number;
  dateTimeFrom: number;
  dateTimeTo: number;
  description: string;
  data: EventAttribute;
}

const CalendarWidget: React.FC = () => {
  const { Title } = Typography;

  const events = useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    {
      variables: { monthTimestamp: moment().unix() },
      fetchPolicy: `no-cache`
    }
  );

  const dataSource: EventRow[] = [];

  if (events.data) {
    events.data.events.map((item, key) => {
      let guests
      if (item.guests.length > 0) {
        guests = ' (' + item.guests.map((g: any) => {return g.firstName}).join(',') + ')'
      } else {
        guests = ''
      }

      dataSource.push({
        key,
        // key: item.id,
        dateTimeFrom: item.dateTimeFrom,
        dateTimeTo: item.dateTimeTo,
        description: item.description + guests,
        data: item as EventAttribute
      })
    });
  }

  // Client: rename title to description attribute in Dashboard view
  const data = [
    {
      description: '18.8.2020 Daňové přiznání',
    },
    {
      description: '22.8.2020 Poslat fakturu za Adam epox IS SLA',
    },
    {
      description: '23.8.2020 8.00–9.00 Plánovaný výpadek O2',
    },
    {
      description: '18.9.2020 Přehled o příjmech na sociální a zdravotní pojištění',
    },
    {
      description: '28.9.2020 Den české státnosti',
    },
    {
      description: '20.12.2020 18.00 Vánoční večírek',
    },
  ]

      //
      //   dateTimeFrom: item.dateTimeFrom,
      //   dateTimeTo: item.dateTimeTo,
  return (
    <>
      <Title level={3}>Kalendář – agenda</Title>
      <Timeline>
        {dataSource.map(item => {
          // TODO: sem přidat i datum/čas
          if (moment.unix(item.dateTimeFrom).format('l') == moment.unix(item.dateTimeTo).format('l'))
            return <Timeline.Item>{moment.unix(item.dateTimeFrom).format('LLL')}–{moment.unix(item.dateTimeTo).format('LT')} {item.description}</Timeline.Item>
          else if (moment.unix(item.dateTimeFrom).format('LT') == '1:00' && moment.unix(item.dateTimeTo).format('LT') == '1:00')
            return <Timeline.Item>{moment.unix(item.dateTimeFrom).utc(true).format('l')} {item.description}</Timeline.Item>
          // return <Timeline.Item>{moment.unix(item.dateTimeTo).format('LT')} {item.description}</Timeline.Item>


          return <div />
        })}
      </Timeline>
    </>
  );
};

interface TaskRow {
  key: number;
  description: string;
  completed: boolean;
  data: Task;
}

const TasksWidget: React.FC = () => {
  const { Title } = Typography;

  const myTasks = useQuery<MyTasksQuery, MyTasksQueryVariables>(
    MyTasksDocument
  );

  const [updateTask] = useMutation<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >(UpdateTaskDocument)

  const dataSource: TaskRow[] = [];

  if (myTasks.data) {
    myTasks.data.myTasks.map((item, key) =>
      dataSource.push({
        key,
        // key: item.id,
        description: item.description,
        completed: item.isCompleted,
        data: item as Task
      })
    );
  }

  // const
  // function Linkify(props: { value: string }) {
  const Linkify = (props: { value: string })=> {
    return <span dangerouslySetInnerHTML={createMarkup(linkify(props.value))} />;
  }

  return (
    <>
      <Title level={3}>Úkoly</Title>
      {dataSource.map(item => (
        <>
          <Checkbox
              // TODO: move to widgets
            checked={item.completed}
            // onChange={async (checkedValues) => {
            onChange={async () => {
              // console.log('checked = ', checkedValues);
              const response = await updateTask({
                variables: {
                  // TODO: input: { id: item.key, isCompleted: !item.completed } }
                  input: { id: item.data.id, description: item.description, isCompleted: !item.completed }
                }
              });

              if (
                response &&
                response.data &&
                response.data.updateTask
              ) {
                if (response.data.updateTask.status) {
                  // data[item.key].completed = !item.completed;
                  // setData(data);
                  // data.splice(record.key, 1);
                  // setData(data);
                  // refetch();

                  window.location.reload(); // TODO: fixme
                }

                // response.data.updateTask.notifications.map(
                //   (notification: Notification) =>
                //     pushNotification(notification)
                // );
              }
            }}
          ><Linkify value={item.description} /></Checkbox><br />
        </>
      ))}
    </>
  );
};

const ActivityHistoryWidget: React.FC = () => {
  const { Title } = Typography;

  const data = [
    {
      employee: 'Marek Hanuš',
      description: 'Zapsal(a) docházku 8 h zaměstnanci Adam Pavel za 22.8.2020.',
    },
    {
      employee: 'Marek Hanuš',
      description: 'Zapsal(a) denní výkaz stavby Skvrňov za 22.8.2020.',
    },
    {
      employee: 'Marek Hanuš',
      description: 'Zapsal(a) odjezd 22.8.2020 v 12.21 do cesťáku NĚM-2019/06-01.',
    },
  ];

  return (
    <>
      <Title level={3}>Poslední aktivita</Title>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
              title={<a href="https://ant.design">{item.employee}</a>}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </>
  );
};
