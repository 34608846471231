import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/react-common";
import * as React from "react";
import * as ApolloReactComponents from "@apollo/react-components";
import * as ApolloReactHoc from "@apollo/react-hoc";
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccommodationReport = {
  __typename?: "AccommodationReport";
  currency: Currency;
  date: Scalars["Int"];
  hotelType: HotelType;
  id: Scalars["Int"];
  invoiceNo: Scalars["String"];
  nightsCount: Scalars["Int"];
  order: Order;
  personsCount: Scalars["Int"];
  priceIncVAT: Scalars["Float"];
};

export type Activity = {
  __typename?: "Activity";
  date: Scalars["Int"];
  description: Scalars["String"];
  employee?: Maybe<Employee>;
};

export type CloseTravelOrderInput = {
  id: Scalars["Int"];
};

export type CreateAccommodationReportInput = {
  currency: CurrencyInput;
  date: Scalars["Int"];
  hotelType: HotelTypeInput;
  invoiceNo: Scalars["String"];
  nightsCount: Scalars["Int"];
  order: OrderInput;
  personsCount: Scalars["Int"];
  priceIncVAT: Scalars["Float"];
};

export type CreateDailyReportInput = {
  date: Scalars["Int"];
  distance: Scalars["Int"];
  order: OrderInput;
  personsCount: Scalars["Int"];
  temperature: Scalars["Int"];
  weather: WeatherInput;
  workHoursFrom: Scalars["Float"];
  workHoursTo: Scalars["Float"];
};

export type CreateEmployeeLoanInput = {
  amount: Scalars["Int"];
  employee: EmployeeInput;
  loanDate: Scalars["Int"];
  monthlyPayment: Scalars["Int"];
  name: Scalars["String"];
  repaymentDate: Scalars["Int"];
};

export type CreateEmployeePenaltyInput = {
  amount: Scalars["Float"];
  currency: CurrencyInput;
  date: Scalars["Int"];
  employee: EmployeeInput;
  reason: Scalars["String"];
};

export type CreateEventInput = {
  dateTimeFrom: Scalars["Int"];
  dateTimeTo: Scalars["Int"];
  description: Scalars["String"];
  guests: Array<EmployeeInput>;
};

export type CreateMaterialReportInput = {
  currency: CurrencyInput;
  date: Scalars["Int"];
  invoiceNo: Scalars["String"];
  materialSupplier: MaterialSupplierInput;
  order: OrderInput;
  priceIncVAT: Scalars["Float"];
};

export type CreateMaterialSupplierInput = {
  name: Scalars["String"];
  payerOfVAT: Scalars["Boolean"];
};

export type CreateOperatingExpenditureInput = {
  currency: CurrencyInput;
  date: Scalars["Int"];
  employee: EmployeeInput;
  operatingExpenditureAmount: Scalars["Float"];
};

export type CreateOrderInput = {
  code?: Maybe<Scalars["String"]>;
  constructionManagerOrDriver: EmployeeInput;
  department: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  invoiceable: Scalars["Boolean"];
  location: Scalars["String"];
  minimalSalaryEU: Scalars["Boolean"];
  name: Scalars["String"];
  passengers: Array<EmployeeInput>;
  payerOfVAT: Scalars["Boolean"];
  priceOffer: Scalars["String"];
  primaryCurrency: CurrencyInput;
  secondaryCurrencies: Array<CurrencyInput>;
  startDate: Scalars["Int"];
  state: StateInput;
  subscriber: SubscriberInput;
  travelOrderCode: Scalars["String"];
  vehicles: Array<VehicleInput>;
};

export type CreatePrepaymentInput = {
  currency: CurrencyInput;
  date: Scalars["Int"];
  employee: EmployeeInput;
  prepaymentAmount: Scalars["Float"];
};

export type CreateSubscriberInput = {
  name: Scalars["String"];
  payerOfVAT: Scalars["Boolean"];
};

export type CreateTaskInput = {
  description: Scalars["String"];
  employee?: Maybe<EmployeeInput>;
  assignments: Array<EmployeeInput>;
};

export type CreateTravelCostReportInput = {
  currency: CurrencyInput;
  date: Scalars["Int"];
  invoiceNo: Scalars["String"];
  order: OrderInput;
  priceIncVAT: Scalars["Float"];
  travelCostSupplier: TravelCostSupplierInput;
  travelCostType: TravelCostTypeInput;
};

export type CreateTravelOrderInput = {
  employee: EmployeeInput;
  order: OrderInput;
  passengers: Array<EmployeeInput>;
  purpose: Scalars["String"];
  vehicle: VehicleInput;
};

export type CreateTravelOrderRouteInput = {
  currentLocation: Scalars["String"];
  dateTime: Scalars["Int"];
  mileage: Scalars["Int"];
  passThrough?: Maybe<Scalars["String"]>;
  travelOrder: TravelOrderInput;
  travelOrderRouteOperation: TravelOrderRouteOperationInput;
  travelOrderRouteType: TravelOrderRouteTypeInput;
};

export type CreateUserHoursInput = {
  date: Scalars["Int"];
  employee: EmployeeInput;
  hoursCount?: Maybe<Scalars["Float"]>;
  hoursFrom: Scalars["Float"];
  hoursTo?: Maybe<Scalars["Float"]>;
  order: OrderInput;
  rate: RateInput;
};

export type CreateWorkDescriptionReportInput = {
  date: Scalars["Int"];
  description: Scalars["String"];
  order: OrderInput;
};

export type Currency = {
  __typename?: "Currency";
  code: Scalars["String"];
  codeName: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type CurrencyInput = {
  id: Scalars["Int"];
};

export type DailyReport = {
  __typename?: "DailyReport";
  accommodationReports: Array<AccommodationReport>;
  date: Scalars["Int"];
  distance: Scalars["Int"];
  materialReports: Array<MaterialReport>;
  order: Order;
  personsCount: Scalars["Int"];
  temperature: Scalars["Int"];
  travelCostReports: Array<TravelCostReport>;
  weather: Weather;
  workDescriptionReports: Array<WorkDescriptionReport>;
  workHoursFrom: Scalars["Float"];
  workHoursTo: Scalars["Float"];
};

export type DashboardNotification = {
  __typename?: "DashboardNotification";
  message: Scalars["String"];
  title: Scalars["String"];
  type: NotificationType;
};

export type DeleteAccommodationReportInput = {
  id: Scalars["Int"];
};

export type DeleteDailyReportInput = {
  date: Scalars["Int"];
  order: OrderInput;
};

export type DeleteEventInput = {
  id: Scalars["Int"];
};

export type DeleteMaterialReportInput = {
  id: Scalars["Int"];
};

export type DeleteTaskInput = {
  id: Scalars["Int"];
};

export type DeleteTravelCostReportInput = {
  id: Scalars["Int"];
};

export type DeleteTravelOrderInput = {
  id: Scalars["Int"];
};

export type DeleteTravelOrderRouteInput = {
  id: Scalars["Int"];
};

export type DeleteUserHoursInput = {
  id: Scalars["Int"];
};

export type DeleteWorkDescriptionReportInput = {
  id: Scalars["Int"];
};

export type EconomicsTableRow = {
  __typename?: "EconomicsTableRow";
  type: Scalars["String"];
  count?: Maybe<Scalars["Float"]>;
  measurementUnit?: Maybe<Scalars["String"]>;
  costs?: Maybe<Scalars["Float"]>;
  customer?: Maybe<Scalars["Float"]>;
};

export type Employee = {
  __typename?: "Employee";
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
};

export type EmployeeInput = {
  id: Scalars["Int"];
};

export type Event = {
  __typename?: "Event";
  guests: Array<Employee>;
  dateTimeFrom: Scalars["Int"];
  dateTimeTo: Scalars["Int"];
  description: Scalars["String"];
  id: Scalars["Int"];
};

export type FormResponse = {
  __typename?: "FormResponse";
  notifications: Array<Notification>;
  status: Scalars["Boolean"];
};

export type HotelType = ListItem & {
  __typename?: "HotelType";
  id: Scalars["Int"];
  name: Scalars["String"];
  payerOfVAT: Scalars["Boolean"];
};

export type HotelTypeInput = {
  id: Scalars["Int"];
};

export type ListItem = {
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type MaterialReport = {
  __typename?: "MaterialReport";
  currency: Currency;
  date: Scalars["Int"];
  id: Scalars["Int"];
  invoiceNo: Scalars["String"];
  materialSupplier: MaterialSupplier;
  order: Order;
  priceIncVAT: Scalars["Float"];
};

export type MaterialSupplier = ListItem & {
  __typename?: "MaterialSupplier";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type MaterialSupplierGroup = ListItem & {
  __typename?: "MaterialSupplierGroup";
  id: Scalars["Int"];
  materialSuppliers: Array<MaterialSupplier>;
  name: Scalars["String"];
};

export type MaterialSupplierInput = {
  id: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  closeTravelOrder?: Maybe<FormResponse>;
  createAccommodationReport?: Maybe<FormResponse>;
  createDailyReport?: Maybe<FormResponse>;
  createEmployeeLoan?: Maybe<FormResponse>;
  createEmployeePenalty?: Maybe<FormResponse>;
  createEvent?: Maybe<FormResponse>;
  createMaterialReport?: Maybe<FormResponse>;
  createMaterialSupplier?: Maybe<FormResponse>;
  createOperatingExpenditure?: Maybe<FormResponse>;
  createOrder?: Maybe<FormResponse>;
  createTask?: Maybe<FormResponse>;
  createPrepayment?: Maybe<FormResponse>;
  createSubscriber?: Maybe<FormResponse>;
  createTravelCostReport?: Maybe<FormResponse>;
  createTravelOrder?: Maybe<FormResponse>;
  createTravelOrderRoute?: Maybe<FormResponse>;
  createUserHours?: Maybe<FormResponse>;
  createWorkDescriptionReport?: Maybe<FormResponse>;
  deleteAccommodationReport?: Maybe<FormResponse>;
  deleteDailyReport?: Maybe<FormResponse>;
  deleteEvent?: Maybe<FormResponse>;
  deleteMaterialReport?: Maybe<FormResponse>;
  deleteTask?: Maybe<FormResponse>;
  deleteTravelCostReport?: Maybe<FormResponse>;
  deleteTravelOrder?: Maybe<FormResponse>;
  deleteTravelOrderRoute?: Maybe<FormResponse>;
  deleteUserHours?: Maybe<FormResponse>;
  deleteWorkDescriptionReport?: Maybe<FormResponse>;
  /** Authentication */
  tokenAuth?: Maybe<TokenAuth>;
  updatePayoutsTable?: Maybe<FormResponse>;
  updateTask?: Maybe<FormResponse>;
  updateTravelOrderRoute?: Maybe<FormResponse>;
  updateUserHours?: Maybe<FormResponse>;
  updateEvent?: Maybe<FormResponse>;
};

export type MutationCloseTravelOrderArgs = {
  input: CloseTravelOrderInput;
};

export type MutationCreateAccommodationReportArgs = {
  input: CreateAccommodationReportInput;
};

export type MutationCreateDailyReportArgs = {
  input: CreateDailyReportInput;
};

export type MutationCreateEmployeeLoanArgs = {
  input: CreateEmployeeLoanInput;
};

export type MutationCreateEmployeePenaltyArgs = {
  input: CreateEmployeePenaltyInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateMaterialReportArgs = {
  input: CreateMaterialReportInput;
};

export type MutationCreateMaterialSupplierArgs = {
  input: CreateMaterialSupplierInput;
};

export type MutationCreateOperatingExpenditureArgs = {
  input: CreateOperatingExpenditureInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreatePrepaymentArgs = {
  input: CreatePrepaymentInput;
};

export type MutationCreateSubscriberArgs = {
  input: CreateSubscriberInput;
};

export type MutationCreateTravelCostReportArgs = {
  input: CreateTravelCostReportInput;
};

export type MutationCreateTravelOrderArgs = {
  input: CreateTravelOrderInput;
};

export type MutationCreateTravelOrderRouteArgs = {
  input: CreateTravelOrderRouteInput;
};

export type MutationCreateUserHoursArgs = {
  input: CreateUserHoursInput;
};

export type MutationCreateWorkDescriptionReportArgs = {
  input: CreateWorkDescriptionReportInput;
};

export type MutationDeleteAccommodationReportArgs = {
  input: DeleteAccommodationReportInput;
};

export type MutationDeleteDailyReportArgs = {
  input: DeleteDailyReportInput;
};

export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};

export type MutationDeleteMaterialReportArgs = {
  input: DeleteMaterialReportInput;
};

export type MutationDeleteTaskArgs = {
  input: DeleteTaskInput;
};

export type MutationDeleteTravelCostReportArgs = {
  input: DeleteTravelCostReportInput;
};

export type MutationDeleteTravelOrderArgs = {
  input: DeleteTravelOrderInput;
};

export type MutationDeleteTravelOrderRouteArgs = {
  input: DeleteTravelOrderRouteInput;
};

export type MutationDeleteUserHoursArgs = {
  input: DeleteUserHoursInput;
};

export type MutationDeleteWorkDescriptionReportArgs = {
  input: DeleteWorkDescriptionReportInput;
};

export type MutationTokenAuthArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  remember: Scalars["Boolean"];
};

export type MutationUpdatePayoutsTableArgs = {
  input: UpdatePayoutsTableInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateTravelOrderRouteArgs = {
  input: UpdateTravelOrderRouteInput;
};

export type MutationUpdateUserHoursArgs = {
  input: UpdateUserHoursInput;
};

export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

export type Notification = {
  __typename?: "Notification";
  message: Scalars["String"];
  type: NotificationType;
};

export enum NotificationType {
  Error = "ERROR",
  Info = "INFO",
  Success = "SUCCESS",
  Warning = "WARNING"
}

export type Order = ListItem & {
  __typename?: "Order";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type OrderInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type PavelsTableRow = {
  __typename?: "PavelsTableRow";
  currency: Currency;
  depositSum?: Maybe<Scalars["Float"]>;
  employee: Employee;
  totalHours: Scalars["Float"];
  userHours: Array<UserHours>;
};

export type PayoutsTableRow = {
  __typename?: "PayoutsTableRow";
  bonusCR?: Maybe<Scalars["Float"]>;
  depositCR?: Maybe<Scalars["Float"]>;
  distraintsCount?: Maybe<Scalars["Int"]>;
  distraintsTax?: Maybe<Scalars["Float"]>;
  employee: Employee;
  finalSurcharge?: Maybe<Scalars["Float"]>;
  flatRate?: Maybe<Scalars["Float"]>;
  hoursCR?: Maybe<Scalars["Float"]>;
  hoursEU?: Maybe<Scalars["Float"]>;
  levies?: Maybe<Scalars["Float"]>;
  note: Scalars["String"];
  paid?: Maybe<Scalars["Float"]>;
  payoutCR?: Maybe<Scalars["Float"]>;
  payoutEU?: Maybe<Scalars["Float"]>;
  payoutsCR?: Maybe<Scalars["Float"]>;
  payoutsEU?: Maybe<Scalars["Float"]>;
  penaltyCR?: Maybe<Scalars["Float"]>;
  penaltyNotesCR: Scalars["String"];
  previousRest?: Maybe<Scalars["Float"]>;
  repayment?: Maybe<Scalars["Float"]>;
};

export enum Permission {
  AccommodationReport = "ACCOMMODATION_REPORT",
  ActivityHistory = "ACTIVITY_HISTORY",
  Calendar = "CALENDAR",
  CreateMaterialSupplier = "CREATE_MATERIAL_SUPPLIER",
  CreateOrder = "CREATE_ORDER",
  CreateSubscriber = "CREATE_SUBSCRIBER",
  DailyListing = "DAILY_LISTING",
  DailyReport = "DAILY_REPORT",
  DailyReports = "DAILY_REPORTS",
  Dashboard = "DASHBOARD",
  Economics = "ECONOMICS",
  EmployeesLoans = "EMPLOYEES_LOANS",
  Error = "ERROR",
  HoursWriting = "HOURS_WRITING",
  Login = "LOGIN",
  MaterialReport = "MATERIAL_REPORT",
  OperatingExpenditure = "OPERATING_EXPENDITURE",
  Pavels = "PAVELS",
  Payouts = "PAYOUTS",
  Penalty = "PENALTY",
  Prepayment = "PREPAYMENT",
  Prepayments = "PREPAYMENTS",
  Tasks = "TASKS",
  TravelCostsReport = "TRAVEL_COSTS_REPORT",
  TravelOrder = "TRAVEL_ORDER",
  TravelOrders = "TRAVEL_ORDERS",
  TravelOrderRoute = "TRAVEL_ORDER_ROUTE",
  TravelOrderRouteWithParams = "TRAVEL_ORDER_ROUTE_WITH_PARAMS",
  WorkDescription = "WORK_DESCRIPTION"
}

export type Prepayment = {
  __typename?: "Prepayment";
  amount: Scalars["Float"];
  currency: Currency;
  employee: Employee;
};

export type Query = {
  __typename?: "Query";
  activityHistory: Array<Activity>;
  currency: Array<Currency>;
  currentLoggedUser: User;
  dailyReport?: Maybe<DailyReport>;
  dailyReports: Array<DailyReport>;
  dashboardNotifications: Array<DashboardNotification>;
  defaultCurrency: Currency;
  defaultExchangeRateEURCZK: Scalars["Float"];
  defaultRate: Rate;
  defaultTravelOrderPurpose: Scalars["String"];
  defaultTravelOrderRouteType: TravelOrderRouteType;
  economicsTable: Array<EconomicsTableRow>;
  employees: Array<Employee>;
  events: Array<Event>;
  hotelTypes: Array<HotelType>;
  materialSupplierGroups: Array<MaterialSupplierGroup>;
  materialSuppliers: Array<MaterialSupplier>;
  maxPrepaymentAmount?: Maybe<Scalars["Float"]>;
  orders: Array<Order>;
  pavelsTable: Array<PavelsTableRow>;
  payoutsTable: Array<PayoutsTableRow>;
  prepayments: Array<Prepayment>;
  rates: Array<Rate>;
  states: Array<State>;
  subscribers: Array<Subscriber>;
  tasks: Array<Task>;
  myTasks: Array<Task>;
  travelCostSupplierGroups: Array<TravelCostSupplierGroup>;
  travelCostSuppliers: Array<TravelCostSupplier>;
  travelCostTypes: Array<TravelCostType>;
  travelOrder?: Maybe<TravelOrder>;
  travelOrderRouteOperations: Array<TravelOrderRouteOperation>;
  travelOrderRouteTypes: Array<TravelOrderRouteType>;
  travelOrders: Array<TravelOrder>;
  userHours: Array<UserHours>;
  vehicles: Array<Vehicle>;
  weather: Array<Weather>;
};

export type QueryCurrencyArgs = {
  order?: Maybe<OrderInput>;
};

export type QueryDailyReportArgs = {
  date: Scalars["Int"];
  order: OrderInput;
};

export type QueryDailyReportsArgs = {
  date?: Maybe<Scalars["Int"]>;
};

export type QueryDefaultCurrencyArgs = {
  order?: Maybe<OrderInput>;
};

export type QueryDefaultRateArgs = {
  employeeId: Scalars["Int"];
};

export type QueryEconomicsTableArgs = {
  order: OrderInput;
  monthTimestamp?: Maybe<Scalars["Int"]>;
  weekTimestamp?: Maybe<Scalars["Int"]>;
  margin?: Maybe<Scalars["Int"]>;
  transportCostPerUnit?: Maybe<Scalars["Int"]>;
  transportCustomerPerUnit?: Maybe<Scalars["Int"]>;
  customerHourlyRate?: Maybe<Scalars["Int"]>;
  exchangeRateEURCZK?: Maybe<Scalars["Float"]>;
};

export type QueryEmployeesArgs = {
  date?: Maybe<Scalars["Int"]>;
  orderId?: Maybe<Scalars["Int"]>;
};

export type QueryEventsArgs = {
  monthTimestamp: Scalars["Int"];
};

export type QueryMaxPrepaymentAmountArgs = {
  currency: CurrencyInput;
  employee: EmployeeInput;
};

export type QueryOrdersArgs = {
  date?: Maybe<Scalars["Int"]>;
};

export type QueryPavelsTableArgs = {
  monthTimestamp: Scalars["Int"];
};

export type QueryPayoutsTableArgs = {
  exchangeRateEURCZK: Scalars["Float"];
  monthTimestamp: Scalars["Int"];
};

export type QueryPrepaymentsArgs = {
  date: Scalars["Int"];
};

export type QueryRatesArgs = {
  employeeId?: Maybe<Scalars["Int"]>;
};

export type QueryTasksArgs = {
  employee?: Maybe<EmployeeInput>;
};

export type QueryTravelOrderArgs = {
  id: Scalars["Int"];
};

export type QueryTravelOrdersArgs = {
  monthTimestamp: Scalars["Int"];
};

export type QueryUserHoursArgs = {
  date: Scalars["Int"];
  orders?: Maybe<Array<OrderInput>>;
};

export type QueryVehiclesArgs = {
  order?: Maybe<OrderInput>;
};

export type Rate = ListItem & {
  __typename?: "Rate";
  id: Scalars["Int"];
  name: Scalars["String"];
  writeHours: Scalars["Boolean"];
};

export type RateInput = {
  id: Scalars["Int"];
};

export type Role = ListItem & {
  __typename?: "Role";
  id: Scalars["Int"];
  name: Scalars["String"];
  permissions: Array<Permission>;
};

export type State = ListItem & {
  __typename?: "State";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type StateInput = {
  id: Scalars["Int"];
};

export type Subscriber = ListItem & {
  __typename?: "Subscriber";
  id: Scalars["Int"];
  name: Scalars["String"];
  payerOfVAT: Scalars["Boolean"];
};

export type SubscriberInput = {
  id: Scalars["Int"];
};

export type Task = {
  __typename?: "Task";
  description: Scalars["String"];
  employee: Employee;
  id: Scalars["Int"];
  isCompleted: Scalars["Boolean"];
};

export type TokenAuth = {
  __typename?: "TokenAuth";
  notification: Notification;
  status: Scalars["Boolean"];
  token?: Maybe<Scalars["String"]>;
  user: User;
};

export type TravelCostReport = {
  __typename?: "TravelCostReport";
  currency: Currency;
  date: Scalars["Int"];
  id: Scalars["Int"];
  invoiceNo: Scalars["String"];
  order: Order;
  priceIncVAT: Scalars["Float"];
  travelCostSupplier: TravelCostSupplier;
  travelCostType: TravelCostType;
};

export type TravelCostSupplier = ListItem & {
  __typename?: "TravelCostSupplier";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type TravelCostSupplierGroup = ListItem & {
  __typename?: "TravelCostSupplierGroup";
  id: Scalars["Int"];
  name: Scalars["String"];
  travelCostSuppliers: Array<TravelCostSupplier>;
};

export type TravelCostSupplierInput = {
  id: Scalars["Int"];
};

export type TravelCostType = ListItem & {
  __typename?: "TravelCostType";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type TravelCostTypeInput = {
  id: Scalars["Int"];
};

export type TravelOrder = {
  __typename?: "TravelOrder";
  cashReceipt: Scalars["String"];
  employee: Employee;
  formation: Scalars["String"];
  id: Scalars["Int"];
  label: Scalars["String"];
  name: Scalars["String"];
  passengers: Array<Employee>;
  travelOrderRoutes: Array<TravelOrderRoute>;
  vehicle: Vehicle;
  workHoursFrom: Scalars["Float"];
  workHoursTo: Scalars["Float"];
};

export type TravelOrderInput = {
  id: Scalars["Int"];
};

export type TravelOrderRoute = {
  __typename?: "TravelOrderRoute";
  accommodationCosts?: Maybe<Scalars["Float"]>;
  currency: Currency;
  dateTimeFrom: Scalars["Int"];
  dateTimeTo?: Maybe<Scalars["Int"]>;
  distance?: Maybe<Scalars["Int"]>;
  finalLocation?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  otherNecessaryExpenditures?: Maybe<Scalars["Float"]>;
  passThough?: Maybe<Scalars["String"]>;
  startLocation: Scalars["String"];
  trailer: Scalars["Boolean"];
  travelCosts?: Maybe<Scalars["Float"]>;
};

export type TravelOrderRouteOperation = ListItem & {
  __typename?: "TravelOrderRouteOperation";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type TravelOrderRouteOperationInput = {
  id: Scalars["Int"];
};

export type TravelOrderRouteType = ListItem & {
  __typename?: "TravelOrderRouteType";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type TravelOrderRouteTypeInput = {
  id: Scalars["Int"];
};

export type UpdateEventInput = {
  id: Scalars["Int"];
  dateTimeFrom: Scalars["Int"];
  dateTimeTo: Scalars["Int"];
  description: Scalars["String"];
  guests: Array<EmployeeInput>;
};

export type UpdatePayoutsTableInput = {
  bonusCR?: Maybe<Scalars["Float"]>;
  date: Scalars["Int"];
  distraintsCount?: Maybe<Scalars["Int"]>;
  employee: EmployeeInput;
  flatRate?: Maybe<Scalars["Float"]>;
  levies?: Maybe<Scalars["Float"]>;
  note: Scalars["String"];
  paid?: Maybe<Scalars["Float"]>;
  previousRest?: Maybe<Scalars["Float"]>;
  repayment?: Maybe<Scalars["Float"]>;
};

export type UpdateTaskInput = {
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isCompleted?: Maybe<Scalars["Boolean"]>;
};

export type UpdateTravelOrderRouteInput = {
  accommodationCosts?: Maybe<Scalars["Float"]>;
  dateTimeFrom: Scalars["Int"];
  dateTimeTo?: Maybe<Scalars["Int"]>;
  distance?: Maybe<Scalars["Int"]>;
  finalLocation?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  otherNecessaryExpenditures?: Maybe<Scalars["Float"]>;
  passThough?: Maybe<Scalars["String"]>;
  startLocation: Scalars["String"];
  trailer: Scalars["Boolean"];
  travelCosts?: Maybe<Scalars["Float"]>;
};

export type UpdateUserHoursInput = {
  date?: Maybe<Scalars["Int"]>;
  employee?: Maybe<EmployeeInput>;
  hoursCount?: Maybe<Scalars["Float"]>;
  hoursFrom?: Maybe<Scalars["Float"]>;
  hoursTo?: Maybe<Scalars["Float"]>;
  id: Scalars["Int"];
  order?: Maybe<OrderInput>;
  rate?: Maybe<RateInput>;
};

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["Int"];
  role: Role;
};

export type UserHours = {
  __typename?: "UserHours";
  date: Scalars["Int"];
  employee: Employee;
  hoursCount?: Maybe<Scalars["Float"]>;
  hoursFrom: Scalars["Float"];
  hoursTo?: Maybe<Scalars["Float"]>;
  id: Scalars["Int"];
  order: Order;
  rate: Rate;
};

export type Vehicle = {
  __typename?: "Vehicle";
  brand: Scalars["String"];
  codeName: Scalars["String"];
  id: Scalars["Int"];
  licensePlate: Scalars["String"];
  model: Scalars["String"];
  vehicleMode: VehicleMode;
};

export type VehicleInput = {
  id: Scalars["Int"];
};

export type VehicleMode = ListItem & {
  __typename?: "VehicleMode";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type Weather = ListItem & {
  __typename?: "Weather";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type WeatherInput = {
  id: Scalars["Int"];
};

export type WorkDescriptionReport = {
  __typename?: "WorkDescriptionReport";
  date: Scalars["Int"];
  description: Scalars["String"];
  id: Scalars["Int"];
  order: Order;
};
export type EmployeeFragment = { __typename?: "Employee" } & Pick<
  Employee,
  "id" | "fullName"
>;

export type FormResponseFragment = { __typename?: "FormResponse" } & Pick<
  FormResponse,
  "status"
> & {
    notifications: Array<
      { __typename?: "Notification" } & NotificationFragment
    >;
  };

export type ListItemFragment = {
  __typename?:
    | "Role"
    | "HotelType"
    | "Order"
    | "MaterialSupplier"
    | "TravelCostSupplier"
    | "TravelCostType"
    | "Weather"
    | "Rate"
    | "TravelOrderRouteType"
    | "MaterialSupplierGroup"
    | "State"
    | "Subscriber"
    | "TravelCostSupplierGroup"
    | "VehicleMode"
    | "TravelOrderRouteOperation";
} & Pick<ListItem, "id" | "name">;

export type NotificationFragment = { __typename?: "Notification" } & Pick<
  Notification,
  "type" | "message"
>;

export type CloseTravelOrderMutationVariables = {
  input: CloseTravelOrderInput;
};

export type CloseTravelOrderMutation = { __typename?: "Mutation" } & {
  closeTravelOrder: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateAccommodationReportMutationVariables = {
  input: CreateAccommodationReportInput;
};

export type CreateAccommodationReportMutation = { __typename?: "Mutation" } & {
  createAccommodationReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateDailyReportMutationVariables = {
  input: CreateDailyReportInput;
};

export type CreateDailyReportMutation = { __typename?: "Mutation" } & {
  createDailyReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateEmployeeLoanMutationVariables = {
  input: CreateEmployeeLoanInput;
};

export type CreateEmployeeLoanMutation = { __typename?: "Mutation" } & {
  createEmployeeLoan: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateEmployeePenaltyMutationVariables = {
  input: CreateEmployeePenaltyInput;
};

export type CreateEmployeePenaltyMutation = { __typename?: "Mutation" } & {
  createEmployeePenalty: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateEventMutationVariables = {
  input: CreateEventInput;
};

export type CreateEventMutation = { __typename?: "Mutation" } & {
  createEvent: Maybe<{ __typename?: "FormResponse" } & FormResponseFragment>;
};

export type CreateMaterialReportMutationVariables = {
  input: CreateMaterialReportInput;
};

export type CreateMaterialReportMutation = { __typename?: "Mutation" } & {
  createMaterialReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateMaterialSupplierMutationVariables = {
  input: CreateMaterialSupplierInput;
};

export type CreateMaterialSupplierMutation = { __typename?: "Mutation" } & {
  createMaterialSupplier: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateOperatingExpenditureMutationVariables = {
  input: CreateOperatingExpenditureInput;
};

export type CreateOperatingExpenditureMutation = { __typename?: "Mutation" } & {
  createOperatingExpenditure: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput;
};

export type CreateOrderMutation = { __typename?: "Mutation" } & {
  createOrder: Maybe<{ __typename?: "FormResponse" } & FormResponseFragment>;
};

export type CreatePrepaymentMutationVariables = {
  input: CreatePrepaymentInput;
};

export type CreatePrepaymentMutation = { __typename?: "Mutation" } & {
  createPrepayment: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateSubscriberMutationVariables = {
  input: CreateSubscriberInput;
};

export type CreateSubscriberMutation = { __typename?: "Mutation" } & {
  createSubscriber: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput;
};

export type CreateTaskMutation = { __typename?: "Mutation" } & {
  createTask: Maybe<{ __typename?: "FormResponse" } & FormResponseFragment>;
};

export type CreateTravelCostReportMutationVariables = {
  input: CreateTravelCostReportInput;
};

export type CreateTravelCostReportMutation = { __typename?: "Mutation" } & {
  createTravelCostReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateTravelOrderMutationVariables = {
  input: CreateTravelOrderInput;
};

export type CreateTravelOrderMutation = { __typename?: "Mutation" } & {
  createTravelOrder: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateTravelOrderRouteMutationVariables = {
  input: CreateTravelOrderRouteInput;
};

export type CreateTravelOrderRouteMutation = { __typename?: "Mutation" } & {
  createTravelOrderRoute: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateUserHoursMutationVariables = {
  input: CreateUserHoursInput;
};

export type CreateUserHoursMutation = { __typename?: "Mutation" } & {
  createUserHours: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CreateWorkDescriptionReportMutationVariables = {
  input: CreateWorkDescriptionReportInput;
};

export type CreateWorkDescriptionReportMutation = {
  __typename?: "Mutation";
} & {
  createWorkDescriptionReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteAccommodationReportMutationVariables = {
  input: DeleteAccommodationReportInput;
};

export type DeleteAccommodationReportMutation = { __typename?: "Mutation" } & {
  deleteAccommodationReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteDailyReportMutationVariables = {
  input: DeleteDailyReportInput;
};

export type DeleteDailyReportMutation = { __typename?: "Mutation" } & {
  deleteDailyReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteMaterialReportMutationVariables = {
  input: DeleteMaterialReportInput;
};

export type DeleteMaterialReportMutation = { __typename?: "Mutation" } & {
  deleteMaterialReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput;
};

export type DeleteTaskMutation = { __typename?: "Mutation" } & {
  deleteTask: Maybe<{ __typename?: "FormResponse" } & FormResponseFragment>;
};

export type DeleteTravelCostReportMutationVariables = {
  input: DeleteTravelCostReportInput;
};

export type DeleteTravelCostReportMutation = { __typename?: "Mutation" } & {
  deleteTravelCostReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteTravelOrderMutationVariables = {
  input: DeleteTravelOrderInput;
};

export type DeleteTravelOrderMutation = { __typename?: "Mutation" } & {
  deleteTravelOrder: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteTravelOrderRouteMutationVariables = {
  input: DeleteTravelOrderRouteInput;
};

export type DeleteTravelOrderRouteMutation = { __typename?: "Mutation" } & {
  deleteTravelOrderRoute: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteUserHoursMutationVariables = {
  input: DeleteUserHoursInput;
};

export type DeleteUserHoursMutation = { __typename?: "Mutation" } & {
  deleteUserHours: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type DeleteWorkDescriptionReportMutationVariables = {
  input: DeleteWorkDescriptionReportInput;
};

export type DeleteWorkDescriptionReportMutation = {
  __typename?: "Mutation";
} & {
  deleteWorkDescriptionReport: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type TokenAuthMutationVariables = {
  email: Scalars["String"];
  password: Scalars["String"];
  remember: Scalars["Boolean"];
};

export type TokenAuthMutation = { __typename?: "Mutation" } & {
  tokenAuth: Maybe<
    { __typename?: "TokenAuth" } & Pick<TokenAuth, "status" | "token"> & {
        notification: { __typename?: "Notification" } & NotificationFragment;
        user: { __typename?: "User" } & Pick<
          User,
          "id" | "fullName" | "email"
        > & {
            role: { __typename?: "Role" } & Pick<
              Role,
              "id" | "name" | "permissions"
            >;
          };
      }
  >;
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput;
};

export type UpdateEventMutation = { __typename?: "Mutation" } & {
  updateEvent: Maybe<{ __typename?: "FormResponse" } & FormResponseFragment>;
};

export type UpdatePayoutsTableMutationVariables = {
  input: UpdatePayoutsTableInput;
};

export type UpdatePayoutsTableMutation = { __typename?: "Mutation" } & {
  updatePayoutsTable: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput;
};

export type UpdateTaskMutation = { __typename?: "Mutation" } & {
  updateTask: Maybe<{ __typename?: "FormResponse" } & FormResponseFragment>;
};

export type UpdateTravelOrderRouteMutationVariables = {
  input: UpdateTravelOrderRouteInput;
};

export type UpdateTravelOrderRouteMutation = { __typename?: "Mutation" } & {
  updateTravelOrderRoute: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type UpdateUserHoursMutationVariables = {
  input: UpdateUserHoursInput;
};

export type UpdateUserHoursMutation = { __typename?: "Mutation" } & {
  updateUserHours: Maybe<
    { __typename?: "FormResponse" } & FormResponseFragment
  >;
};

export type CurrencyQueryVariables = {
  order?: Maybe<OrderInput>;
};

export type CurrencyQuery = { __typename?: "Query" } & {
  currency: Array<
    { __typename?: "Currency" } & Pick<Currency, "id" | "codeName" | "code">
  >;
};

export type CurrentLoggedUserQueryVariables = {};

export type CurrentLoggedUserQuery = { __typename?: "Query" } & {
  currentLoggedUser: { __typename?: "User" } & Pick<
    User,
    "id" | "fullName" | "email"
  > & {
      role: { __typename?: "Role" } & Pick<Role, "permissions"> &
        ListItemFragment;
    };
};

export type DailyReportQueryVariables = {
  order: OrderInput;
  date: Scalars["Int"];
};

export type DailyReportQuery = { __typename?: "Query" } & {
  dailyReport: Maybe<
    { __typename?: "DailyReport" } & {
      materialReports: Array<
        { __typename?: "MaterialReport" } & Pick<
          MaterialReport,
          "id" | "invoiceNo" | "priceIncVAT"
        > & {
            materialSupplier: {
              __typename?: "MaterialSupplier";
            } & ListItemFragment;
            currency: { __typename?: "Currency" } & Pick<
              Currency,
              "id" | "code"
            >;
          }
      >;
      travelCostReports: Array<
        { __typename?: "TravelCostReport" } & Pick<
          TravelCostReport,
          "id" | "invoiceNo" | "priceIncVAT"
        > & {
            travelCostType: {
              __typename?: "TravelCostType";
            } & ListItemFragment;
            travelCostSupplier: {
              __typename?: "TravelCostSupplier";
            } & ListItemFragment;
            currency: { __typename?: "Currency" } & Pick<
              Currency,
              "id" | "code"
            >;
          }
      >;
      accommodationReports: Array<
        { __typename?: "AccommodationReport" } & Pick<
          AccommodationReport,
          "id" | "nightsCount" | "personsCount" | "invoiceNo" | "priceIncVAT"
        > & {
            hotelType: { __typename?: "HotelType" } & ListItemFragment;
            currency: { __typename?: "Currency" } & Pick<Currency, "code">;
          }
      >;
      workDescriptionReports: Array<
        { __typename?: "WorkDescriptionReport" } & Pick<
          WorkDescriptionReport,
          "id" | "description"
        >
      >;
    }
  >;
};

export type DailyReportsQueryVariables = {
  date: Scalars["Int"];
};

export type DailyReportsQuery = { __typename?: "Query" } & {
  dailyReports: Array<
    { __typename?: "DailyReport" } & Pick<
      DailyReport,
      | "date"
      | "temperature"
      | "personsCount"
      | "workHoursFrom"
      | "workHoursTo"
      | "distance"
    > & {
        order: { __typename?: "Order" } & ListItemFragment;
        weather: { __typename?: "Weather" } & ListItemFragment;
      }
  >;
};

export type DashboardNotificationsQueryVariables = {};

export type DashboardNotificationsQuery = { __typename?: "Query" } & {
  dashboardNotifications: Array<
    { __typename?: "DashboardNotification" } & Pick<
      DashboardNotification,
      "title" | "type" | "message"
    >
  >;
};

export type DefaultCurrencyQueryVariables = {
  order: OrderInput;
};

export type DefaultCurrencyQuery = { __typename?: "Query" } & {
  defaultCurrency: { __typename?: "Currency" } & Pick<Currency, "id">;
};

export type DefaultExchangeRateEurczkQueryVariables = {};

export type DefaultExchangeRateEurczkQuery = { __typename?: "Query" } & Pick<
  Query,
  "defaultExchangeRateEURCZK"
>;

export type DefaultTravelOrderPurposeQueryVariables = {};

export type DefaultTravelOrderPurposeQuery = { __typename?: "Query" } & Pick<
  Query,
  "defaultTravelOrderPurpose"
>;

export type EconomicsTableQueryVariables = {
  order: OrderInput;
  monthTimestamp?: Maybe<Scalars["Int"]>;
  weekTimestamp?: Maybe<Scalars["Int"]>;
  margin?: Maybe<Scalars["Int"]>;
  transportCostPerUnit?: Maybe<Scalars["Int"]>;
  transportCustomerPerUnit?: Maybe<Scalars["Int"]>;
  customerHourlyRate?: Maybe<Scalars["Int"]>;
  exchangeRateEURCZK?: Maybe<Scalars["Float"]>;
};

export type EconomicsTableQuery = { __typename?: "Query" } & {
  economicsTable: Array<
    { __typename?: "EconomicsTableRow" } & Pick<
      EconomicsTableRow,
      "type" | "count" | "measurementUnit" | "costs" | "customer"
    >
  >;
};

export type EmployeesQueryVariables = {
  date?: Maybe<Scalars["Int"]>;
  orderId?: Maybe<Scalars["Int"]>;
};

export type EmployeesQuery = { __typename?: "Query" } & {
  employees: Array<{ __typename?: "Employee" } & EmployeeFragment>;
};

export type EventsQueryVariables = {
  monthTimestamp: Scalars["Int"];
};

export type EventsQuery = { __typename?: "Query" } & {
  events: Array<
    { __typename?: "Event" } & Pick<
      Event,
      "id" | "dateTimeFrom" | "dateTimeTo" | "description"
    > & {
        guests: Array<
          { __typename?: "Employee" } & Pick<
            Employee,
            "id" | "firstName" | "lastName" | "fullName"
          >
        >;
      }
  >;
};

export type HotelTypesQueryVariables = {};

export type HotelTypesQuery = { __typename?: "Query" } & {
  hotelTypes: Array<{ __typename?: "HotelType" } & ListItemFragment>;
};

export type MaterialSupplierGroupsQueryVariables = {};

export type MaterialSupplierGroupsQuery = { __typename?: "Query" } & {
  materialSupplierGroups: Array<
    { __typename?: "MaterialSupplierGroup" } & {
      materialSuppliers: Array<
        { __typename?: "MaterialSupplier" } & ListItemFragment
      >;
    } & ListItemFragment
  >;
};

export type MaxPrepaymentAmountQueryVariables = {
  employee: EmployeeInput;
  currency: CurrencyInput;
};

export type MaxPrepaymentAmountQuery = { __typename?: "Query" } & Pick<
  Query,
  "maxPrepaymentAmount"
>;

export type MyTasksQueryVariables = {};

export type MyTasksQuery = { __typename?: "Query" } & {
  myTasks: Array<
    { __typename?: "Task" } & Pick<Task, "id" | "description" | "isCompleted">
  >;
};

export type OrdersQueryVariables = {
  date?: Maybe<Scalars["Int"]>;
};

export type OrdersQuery = { __typename?: "Query" } & {
  orders: Array<{ __typename?: "Order" } & ListItemFragment>;
};

export type PavelsTableQueryVariables = {
  monthTimestamp: Scalars["Int"];
};

export type PavelsTableQuery = { __typename?: "Query" } & {
  pavelsTable: Array<
    { __typename?: "PavelsTableRow" } & Pick<
      PavelsTableRow,
      "depositSum" | "totalHours"
    > & {
        employee: { __typename?: "Employee" } & EmployeeFragment;
        userHours: Array<
          { __typename?: "UserHours" } & Pick<
            UserHours,
            "id" | "date" | "hoursCount"
          > & { rate: { __typename?: "Rate" } & ListItemFragment }
        >;
      }
  >;
};

export type PayoutsTableQueryVariables = {
  monthTimestamp: Scalars["Int"];
  exchangeRateEURCZK: Scalars["Float"];
};

export type PayoutsTableQuery = { __typename?: "Query" } & {
  payoutsTable: Array<
    { __typename?: "PayoutsTableRow" } & Pick<
      PayoutsTableRow,
      | "hoursCR"
      | "hoursEU"
      | "payoutCR"
      | "payoutEU"
      | "bonusCR"
      | "depositCR"
      | "levies"
      | "flatRate"
      | "previousRest"
      | "repayment"
      | "penaltyCR"
      | "penaltyNotesCR"
      | "distraintsCount"
      | "distraintsTax"
      | "finalSurcharge"
      | "paid"
      | "note"
    > & { employee: { __typename?: "Employee" } & EmployeeFragment }
  >;
};

export type PrepaymentsQueryVariables = {
  date: Scalars["Int"];
};

export type PrepaymentsQuery = { __typename?: "Query" } & {
  prepayments: Array<
    { __typename?: "Prepayment" } & Pick<Prepayment, "amount"> & {
        employee: { __typename?: "Employee" } & EmployeeFragment;
        currency: { __typename?: "Currency" } & Pick<
          Currency,
          "id" | "codeName" | "code"
        >;
      }
  >;
};

export type RatesQueryVariables = {
  employeeId: Scalars["Int"];
};

export type RatesQuery = { __typename?: "Query" } & {
  rates: Array<
    { __typename?: "Rate" } & Pick<Rate, "writeHours"> & ListItemFragment
  >;
  defaultRate: { __typename?: "Rate" } & Pick<Rate, "id" | "writeHours">;
};

export type StatesQueryVariables = {};

export type StatesQuery = { __typename?: "Query" } & {
  states: Array<{ __typename?: "State" } & ListItemFragment>;
};

export type SubscribersQueryVariables = {};

export type SubscribersQuery = { __typename?: "Query" } & {
  subscribers: Array<{ __typename?: "Subscriber" } & ListItemFragment>;
};

export type TasksQueryVariables = {};

export type TasksQuery = { __typename?: "Query" } & {
  tasks: Array<
    { __typename?: "Task" } & Pick<
      Task,
      "id" | "description" | "isCompleted"
    > & { employee: { __typename?: "Employee" } & EmployeeFragment }
  >;
};

export type TravelCostSupplierGroupsQueryVariables = {};

export type TravelCostSupplierGroupsQuery = { __typename?: "Query" } & {
  travelCostSupplierGroups: Array<
    { __typename?: "TravelCostSupplierGroup" } & {
      travelCostSuppliers: Array<
        { __typename?: "TravelCostSupplier" } & ListItemFragment
      >;
    } & ListItemFragment
  >;
};

export type TravelCostTypesQueryVariables = {};

export type TravelCostTypesQuery = { __typename?: "Query" } & {
  travelCostTypes: Array<{ __typename?: "TravelCostType" } & ListItemFragment>;
};

export type TravelOrderQueryVariables = {
  id: Scalars["Int"];
};

export type TravelOrderQuery = { __typename?: "Query" } & {
  travelOrder: Maybe<
    { __typename?: "TravelOrder" } & Pick<TravelOrder, "id" | "name"> & {
        travelOrderRoutes: Array<
          { __typename?: "TravelOrderRoute" } & Pick<
            TravelOrderRoute,
            | "id"
            | "dateTimeFrom"
            | "dateTimeTo"
            | "startLocation"
            | "passThough"
            | "finalLocation"
            | "trailer"
            | "distance"
            | "travelCosts"
            | "accommodationCosts"
            | "otherNecessaryExpenditures"
          > & {
              currency: { __typename?: "Currency" } & Pick<
                Currency,
                "id" | "code"
              >;
            }
        >;
      }
  >;
};

export type TravelOrderRouteOperationsQueryVariables = {};

export type TravelOrderRouteOperationsQuery = { __typename?: "Query" } & {
  travelOrderRouteOperations: Array<
    { __typename?: "TravelOrderRouteOperation" } & ListItemFragment
  >;
};

export type TravelOrderRouteTypesQueryVariables = {};

export type TravelOrderRouteTypesQuery = { __typename?: "Query" } & {
  travelOrderRouteTypes: Array<
    { __typename?: "TravelOrderRouteType" } & ListItemFragment
  >;
};

export type TravelOrdersQueryVariables = {
  monthTimestamp: Scalars["Int"];
};

export type TravelOrdersQuery = { __typename?: "Query" } & {
  travelOrders: Array<
    { __typename?: "TravelOrder" } & Pick<TravelOrder, "id" | "name">
  >;
};

export type TravelOrdersListQueryVariables = {
  monthTimestamp: Scalars["Int"];
};

export type TravelOrdersListQuery = { __typename?: "Query" } & {
  travelOrders: Array<
    { __typename?: "TravelOrder" } & Pick<
      TravelOrder,
      "id" | "label" | "cashReceipt" | "formation"
    > & {
        employee: { __typename?: "Employee" } & EmployeeFragment;
        passengers: Array<{ __typename?: "Employee" } & EmployeeFragment>;
        vehicle: { __typename?: "Vehicle" } & Pick<
          Vehicle,
          "id" | "codeName"
        > & { vehicleMode: { __typename?: "VehicleMode" } & ListItemFragment };
      }
  >;
};

export type UserHoursQueryVariables = {
  date: Scalars["Int"];
  orders?: Maybe<Array<OrderInput>>;
};

export type UserHoursQuery = { __typename?: "Query" } & {
  userHours: Array<
    { __typename?: "UserHours" } & Pick<
      UserHours,
      "id" | "hoursCount" | "hoursFrom" | "hoursTo"
    > & {
        order: { __typename?: "Order" } & ListItemFragment;
        employee: { __typename?: "Employee" } & EmployeeFragment;
        rate: { __typename?: "Rate" } & ListItemFragment;
      }
  >;
};

export type VehiclesQueryVariables = {
  order?: Maybe<OrderInput>;
};

export type VehiclesQuery = { __typename?: "Query" } & {
  vehicles: Array<
    { __typename?: "Vehicle" } & Pick<Vehicle, "id" | "codeName">
  >;
};

export type WeatherQueryVariables = {};

export type WeatherQuery = { __typename?: "Query" } & {
  weather: Array<{ __typename?: "Weather" } & ListItemFragment>;
};
export const EmployeeFragmentDoc = gql`
  fragment Employee on Employee {
    id
    fullName
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    type
    message
  }
`;
export const FormResponseFragmentDoc = gql`
  fragment FormResponse on FormResponse {
    status
    notifications {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;
export const ListItemFragmentDoc = gql`
  fragment ListItem on ListItem {
    id
    name
  }
`;
export const CloseTravelOrderDocument = gql`
  mutation CloseTravelOrder($input: CloseTravelOrderInput!) {
    closeTravelOrder(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CloseTravelOrderMutationFn = ApolloReactCommon.MutationFunction<
  CloseTravelOrderMutation,
  CloseTravelOrderMutationVariables
>;
export type CloseTravelOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CloseTravelOrderMutation,
    CloseTravelOrderMutationVariables
  >,
  "mutation"
>;

export const CloseTravelOrderComponent = (
  props: CloseTravelOrderComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CloseTravelOrderMutation,
    CloseTravelOrderMutationVariables
  >
    mutation={CloseTravelOrderDocument}
    {...props}
  />
);

export type CloseTravelOrderProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CloseTravelOrderMutation,
  CloseTravelOrderMutationVariables
> &
  TChildProps;
export function withCloseTravelOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CloseTravelOrderMutation,
    CloseTravelOrderMutationVariables,
    CloseTravelOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CloseTravelOrderMutation,
    CloseTravelOrderMutationVariables,
    CloseTravelOrderProps<TChildProps>
  >(CloseTravelOrderDocument, {
    alias: "withCloseTravelOrder",
    ...operationOptions
  });
}
export type CloseTravelOrderMutationResult = ApolloReactCommon.MutationResult<
  CloseTravelOrderMutation
>;
export type CloseTravelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CloseTravelOrderMutation,
  CloseTravelOrderMutationVariables
>;
export const CreateAccommodationReportDocument = gql`
  mutation CreateAccommodationReport($input: CreateAccommodationReportInput!) {
    createAccommodationReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateAccommodationReportMutationFn = ApolloReactCommon.MutationFunction<
  CreateAccommodationReportMutation,
  CreateAccommodationReportMutationVariables
>;
export type CreateAccommodationReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateAccommodationReportMutation,
    CreateAccommodationReportMutationVariables
  >,
  "mutation"
>;

export const CreateAccommodationReportComponent = (
  props: CreateAccommodationReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateAccommodationReportMutation,
    CreateAccommodationReportMutationVariables
  >
    mutation={CreateAccommodationReportDocument}
    {...props}
  />
);

export type CreateAccommodationReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateAccommodationReportMutation,
  CreateAccommodationReportMutationVariables
> &
  TChildProps;
export function withCreateAccommodationReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateAccommodationReportMutation,
    CreateAccommodationReportMutationVariables,
    CreateAccommodationReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateAccommodationReportMutation,
    CreateAccommodationReportMutationVariables,
    CreateAccommodationReportProps<TChildProps>
  >(CreateAccommodationReportDocument, {
    alias: "withCreateAccommodationReport",
    ...operationOptions
  });
}
export type CreateAccommodationReportMutationResult = ApolloReactCommon.MutationResult<
  CreateAccommodationReportMutation
>;
export type CreateAccommodationReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAccommodationReportMutation,
  CreateAccommodationReportMutationVariables
>;
export const CreateDailyReportDocument = gql`
  mutation CreateDailyReport($input: CreateDailyReportInput!) {
    createDailyReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateDailyReportMutationFn = ApolloReactCommon.MutationFunction<
  CreateDailyReportMutation,
  CreateDailyReportMutationVariables
>;
export type CreateDailyReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateDailyReportMutation,
    CreateDailyReportMutationVariables
  >,
  "mutation"
>;

export const CreateDailyReportComponent = (
  props: CreateDailyReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateDailyReportMutation,
    CreateDailyReportMutationVariables
  >
    mutation={CreateDailyReportDocument}
    {...props}
  />
);

export type CreateDailyReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateDailyReportMutation,
  CreateDailyReportMutationVariables
> &
  TChildProps;
export function withCreateDailyReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDailyReportMutation,
    CreateDailyReportMutationVariables,
    CreateDailyReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDailyReportMutation,
    CreateDailyReportMutationVariables,
    CreateDailyReportProps<TChildProps>
  >(CreateDailyReportDocument, {
    alias: "withCreateDailyReport",
    ...operationOptions
  });
}
export type CreateDailyReportMutationResult = ApolloReactCommon.MutationResult<
  CreateDailyReportMutation
>;
export type CreateDailyReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDailyReportMutation,
  CreateDailyReportMutationVariables
>;
export const CreateEmployeeLoanDocument = gql`
  mutation CreateEmployeeLoan($input: CreateEmployeeLoanInput!) {
    createEmployeeLoan(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateEmployeeLoanMutationFn = ApolloReactCommon.MutationFunction<
  CreateEmployeeLoanMutation,
  CreateEmployeeLoanMutationVariables
>;
export type CreateEmployeeLoanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateEmployeeLoanMutation,
    CreateEmployeeLoanMutationVariables
  >,
  "mutation"
>;

export const CreateEmployeeLoanComponent = (
  props: CreateEmployeeLoanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateEmployeeLoanMutation,
    CreateEmployeeLoanMutationVariables
  >
    mutation={CreateEmployeeLoanDocument}
    {...props}
  />
);

export type CreateEmployeeLoanProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateEmployeeLoanMutation,
  CreateEmployeeLoanMutationVariables
> &
  TChildProps;
export function withCreateEmployeeLoan<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEmployeeLoanMutation,
    CreateEmployeeLoanMutationVariables,
    CreateEmployeeLoanProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEmployeeLoanMutation,
    CreateEmployeeLoanMutationVariables,
    CreateEmployeeLoanProps<TChildProps>
  >(CreateEmployeeLoanDocument, {
    alias: "withCreateEmployeeLoan",
    ...operationOptions
  });
}
export type CreateEmployeeLoanMutationResult = ApolloReactCommon.MutationResult<
  CreateEmployeeLoanMutation
>;
export type CreateEmployeeLoanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEmployeeLoanMutation,
  CreateEmployeeLoanMutationVariables
>;
export const CreateEmployeePenaltyDocument = gql`
  mutation CreateEmployeePenalty($input: CreateEmployeePenaltyInput!) {
    createEmployeePenalty(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateEmployeePenaltyMutationFn = ApolloReactCommon.MutationFunction<
  CreateEmployeePenaltyMutation,
  CreateEmployeePenaltyMutationVariables
>;
export type CreateEmployeePenaltyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateEmployeePenaltyMutation,
    CreateEmployeePenaltyMutationVariables
  >,
  "mutation"
>;

export const CreateEmployeePenaltyComponent = (
  props: CreateEmployeePenaltyComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateEmployeePenaltyMutation,
    CreateEmployeePenaltyMutationVariables
  >
    mutation={CreateEmployeePenaltyDocument}
    {...props}
  />
);

export type CreateEmployeePenaltyProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateEmployeePenaltyMutation,
  CreateEmployeePenaltyMutationVariables
> &
  TChildProps;
export function withCreateEmployeePenalty<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEmployeePenaltyMutation,
    CreateEmployeePenaltyMutationVariables,
    CreateEmployeePenaltyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEmployeePenaltyMutation,
    CreateEmployeePenaltyMutationVariables,
    CreateEmployeePenaltyProps<TChildProps>
  >(CreateEmployeePenaltyDocument, {
    alias: "withCreateEmployeePenalty",
    ...operationOptions
  });
}
export type CreateEmployeePenaltyMutationResult = ApolloReactCommon.MutationResult<
  CreateEmployeePenaltyMutation
>;
export type CreateEmployeePenaltyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEmployeePenaltyMutation,
  CreateEmployeePenaltyMutationVariables
>;
export const CreateEventDocument = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export type CreateEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >,
  "mutation"
>;

export const CreateEventComponent = (props: CreateEventComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateEventMutation,
    CreateEventMutationVariables
  >
    mutation={CreateEventDocument}
    {...props}
  />
);

export type CreateEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateEventMutation,
  CreateEventMutationVariables
> &
  TChildProps;
export function withCreateEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEventMutation,
    CreateEventMutationVariables,
    CreateEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEventMutation,
    CreateEventMutationVariables,
    CreateEventProps<TChildProps>
  >(CreateEventDocument, {
    alias: "withCreateEvent",
    ...operationOptions
  });
}
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<
  CreateEventMutation
>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const CreateMaterialReportDocument = gql`
  mutation CreateMaterialReport($input: CreateMaterialReportInput!) {
    createMaterialReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateMaterialReportMutationFn = ApolloReactCommon.MutationFunction<
  CreateMaterialReportMutation,
  CreateMaterialReportMutationVariables
>;
export type CreateMaterialReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMaterialReportMutation,
    CreateMaterialReportMutationVariables
  >,
  "mutation"
>;

export const CreateMaterialReportComponent = (
  props: CreateMaterialReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateMaterialReportMutation,
    CreateMaterialReportMutationVariables
  >
    mutation={CreateMaterialReportDocument}
    {...props}
  />
);

export type CreateMaterialReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateMaterialReportMutation,
  CreateMaterialReportMutationVariables
> &
  TChildProps;
export function withCreateMaterialReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMaterialReportMutation,
    CreateMaterialReportMutationVariables,
    CreateMaterialReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMaterialReportMutation,
    CreateMaterialReportMutationVariables,
    CreateMaterialReportProps<TChildProps>
  >(CreateMaterialReportDocument, {
    alias: "withCreateMaterialReport",
    ...operationOptions
  });
}
export type CreateMaterialReportMutationResult = ApolloReactCommon.MutationResult<
  CreateMaterialReportMutation
>;
export type CreateMaterialReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMaterialReportMutation,
  CreateMaterialReportMutationVariables
>;
export const CreateMaterialSupplierDocument = gql`
  mutation CreateMaterialSupplier($input: CreateMaterialSupplierInput!) {
    createMaterialSupplier(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateMaterialSupplierMutationFn = ApolloReactCommon.MutationFunction<
  CreateMaterialSupplierMutation,
  CreateMaterialSupplierMutationVariables
>;
export type CreateMaterialSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMaterialSupplierMutation,
    CreateMaterialSupplierMutationVariables
  >,
  "mutation"
>;

export const CreateMaterialSupplierComponent = (
  props: CreateMaterialSupplierComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateMaterialSupplierMutation,
    CreateMaterialSupplierMutationVariables
  >
    mutation={CreateMaterialSupplierDocument}
    {...props}
  />
);

export type CreateMaterialSupplierProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateMaterialSupplierMutation,
  CreateMaterialSupplierMutationVariables
> &
  TChildProps;
export function withCreateMaterialSupplier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMaterialSupplierMutation,
    CreateMaterialSupplierMutationVariables,
    CreateMaterialSupplierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMaterialSupplierMutation,
    CreateMaterialSupplierMutationVariables,
    CreateMaterialSupplierProps<TChildProps>
  >(CreateMaterialSupplierDocument, {
    alias: "withCreateMaterialSupplier",
    ...operationOptions
  });
}
export type CreateMaterialSupplierMutationResult = ApolloReactCommon.MutationResult<
  CreateMaterialSupplierMutation
>;
export type CreateMaterialSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMaterialSupplierMutation,
  CreateMaterialSupplierMutationVariables
>;
export const CreateOperatingExpenditureDocument = gql`
  mutation CreateOperatingExpenditure(
    $input: CreateOperatingExpenditureInput!
  ) {
    createOperatingExpenditure(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateOperatingExpenditureMutationFn = ApolloReactCommon.MutationFunction<
  CreateOperatingExpenditureMutation,
  CreateOperatingExpenditureMutationVariables
>;
export type CreateOperatingExpenditureComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOperatingExpenditureMutation,
    CreateOperatingExpenditureMutationVariables
  >,
  "mutation"
>;

export const CreateOperatingExpenditureComponent = (
  props: CreateOperatingExpenditureComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateOperatingExpenditureMutation,
    CreateOperatingExpenditureMutationVariables
  >
    mutation={CreateOperatingExpenditureDocument}
    {...props}
  />
);

export type CreateOperatingExpenditureProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateOperatingExpenditureMutation,
  CreateOperatingExpenditureMutationVariables
> &
  TChildProps;
export function withCreateOperatingExpenditure<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOperatingExpenditureMutation,
    CreateOperatingExpenditureMutationVariables,
    CreateOperatingExpenditureProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOperatingExpenditureMutation,
    CreateOperatingExpenditureMutationVariables,
    CreateOperatingExpenditureProps<TChildProps>
  >(CreateOperatingExpenditureDocument, {
    alias: "withCreateOperatingExpenditure",
    ...operationOptions
  });
}
export type CreateOperatingExpenditureMutationResult = ApolloReactCommon.MutationResult<
  CreateOperatingExpenditureMutation
>;
export type CreateOperatingExpenditureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOperatingExpenditureMutation,
  CreateOperatingExpenditureMutationVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export type CreateOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >,
  "mutation"
>;

export const CreateOrderComponent = (props: CreateOrderComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >
    mutation={CreateOrderDocument}
    {...props}
  />
);

export type CreateOrderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateOrderMutation,
  CreateOrderMutationVariables
> &
  TChildProps;
export function withCreateOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrderMutation,
    CreateOrderMutationVariables,
    CreateOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrderMutation,
    CreateOrderMutationVariables,
    CreateOrderProps<TChildProps>
  >(CreateOrderDocument, {
    alias: "withCreateOrder",
    ...operationOptions
  });
}
export type CreateOrderMutationResult = ApolloReactCommon.MutationResult<
  CreateOrderMutation
>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const CreatePrepaymentDocument = gql`
  mutation CreatePrepayment($input: CreatePrepaymentInput!) {
    createPrepayment(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreatePrepaymentMutationFn = ApolloReactCommon.MutationFunction<
  CreatePrepaymentMutation,
  CreatePrepaymentMutationVariables
>;
export type CreatePrepaymentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePrepaymentMutation,
    CreatePrepaymentMutationVariables
  >,
  "mutation"
>;

export const CreatePrepaymentComponent = (
  props: CreatePrepaymentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreatePrepaymentMutation,
    CreatePrepaymentMutationVariables
  >
    mutation={CreatePrepaymentDocument}
    {...props}
  />
);

export type CreatePrepaymentProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreatePrepaymentMutation,
  CreatePrepaymentMutationVariables
> &
  TChildProps;
export function withCreatePrepayment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePrepaymentMutation,
    CreatePrepaymentMutationVariables,
    CreatePrepaymentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePrepaymentMutation,
    CreatePrepaymentMutationVariables,
    CreatePrepaymentProps<TChildProps>
  >(CreatePrepaymentDocument, {
    alias: "withCreatePrepayment",
    ...operationOptions
  });
}
export type CreatePrepaymentMutationResult = ApolloReactCommon.MutationResult<
  CreatePrepaymentMutation
>;
export type CreatePrepaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePrepaymentMutation,
  CreatePrepaymentMutationVariables
>;
export const CreateSubscriberDocument = gql`
  mutation createSubscriber($input: CreateSubscriberInput!) {
    createSubscriber(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateSubscriberMutationFn = ApolloReactCommon.MutationFunction<
  CreateSubscriberMutation,
  CreateSubscriberMutationVariables
>;
export type CreateSubscriberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateSubscriberMutation,
    CreateSubscriberMutationVariables
  >,
  "mutation"
>;

export const CreateSubscriberComponent = (
  props: CreateSubscriberComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateSubscriberMutation,
    CreateSubscriberMutationVariables
  >
    mutation={CreateSubscriberDocument}
    {...props}
  />
);

export type CreateSubscriberProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateSubscriberMutation,
  CreateSubscriberMutationVariables
> &
  TChildProps;
export function withCreateSubscriber<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSubscriberMutation,
    CreateSubscriberMutationVariables,
    CreateSubscriberProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSubscriberMutation,
    CreateSubscriberMutationVariables,
    CreateSubscriberProps<TChildProps>
  >(CreateSubscriberDocument, {
    alias: "withCreateSubscriber",
    ...operationOptions
  });
}
export type CreateSubscriberMutationResult = ApolloReactCommon.MutationResult<
  CreateSubscriberMutation
>;
export type CreateSubscriberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSubscriberMutation,
  CreateSubscriberMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateTaskMutationFn = ApolloReactCommon.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export type CreateTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >,
  "mutation"
>;

export const CreateTaskComponent = (props: CreateTaskComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >
    mutation={CreateTaskDocument}
    {...props}
  />
);

export type CreateTaskProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTaskMutation,
  CreateTaskMutationVariables
> &
  TChildProps;
export function withCreateTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTaskMutation,
    CreateTaskMutationVariables,
    CreateTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTaskMutation,
    CreateTaskMutationVariables,
    CreateTaskProps<TChildProps>
  >(CreateTaskDocument, {
    alias: "withCreateTask",
    ...operationOptions
  });
}
export type CreateTaskMutationResult = ApolloReactCommon.MutationResult<
  CreateTaskMutation
>;
export type CreateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateTravelCostReportDocument = gql`
  mutation CreateTravelCostReport($input: CreateTravelCostReportInput!) {
    createTravelCostReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateTravelCostReportMutationFn = ApolloReactCommon.MutationFunction<
  CreateTravelCostReportMutation,
  CreateTravelCostReportMutationVariables
>;
export type CreateTravelCostReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTravelCostReportMutation,
    CreateTravelCostReportMutationVariables
  >,
  "mutation"
>;

export const CreateTravelCostReportComponent = (
  props: CreateTravelCostReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateTravelCostReportMutation,
    CreateTravelCostReportMutationVariables
  >
    mutation={CreateTravelCostReportDocument}
    {...props}
  />
);

export type CreateTravelCostReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateTravelCostReportMutation,
  CreateTravelCostReportMutationVariables
> &
  TChildProps;
export function withCreateTravelCostReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTravelCostReportMutation,
    CreateTravelCostReportMutationVariables,
    CreateTravelCostReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTravelCostReportMutation,
    CreateTravelCostReportMutationVariables,
    CreateTravelCostReportProps<TChildProps>
  >(CreateTravelCostReportDocument, {
    alias: "withCreateTravelCostReport",
    ...operationOptions
  });
}
export type CreateTravelCostReportMutationResult = ApolloReactCommon.MutationResult<
  CreateTravelCostReportMutation
>;
export type CreateTravelCostReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTravelCostReportMutation,
  CreateTravelCostReportMutationVariables
>;
export const CreateTravelOrderDocument = gql`
  mutation CreateTravelOrder($input: CreateTravelOrderInput!) {
    createTravelOrder(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateTravelOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateTravelOrderMutation,
  CreateTravelOrderMutationVariables
>;
export type CreateTravelOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTravelOrderMutation,
    CreateTravelOrderMutationVariables
  >,
  "mutation"
>;

export const CreateTravelOrderComponent = (
  props: CreateTravelOrderComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateTravelOrderMutation,
    CreateTravelOrderMutationVariables
  >
    mutation={CreateTravelOrderDocument}
    {...props}
  />
);

export type CreateTravelOrderProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateTravelOrderMutation,
  CreateTravelOrderMutationVariables
> &
  TChildProps;
export function withCreateTravelOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTravelOrderMutation,
    CreateTravelOrderMutationVariables,
    CreateTravelOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTravelOrderMutation,
    CreateTravelOrderMutationVariables,
    CreateTravelOrderProps<TChildProps>
  >(CreateTravelOrderDocument, {
    alias: "withCreateTravelOrder",
    ...operationOptions
  });
}
export type CreateTravelOrderMutationResult = ApolloReactCommon.MutationResult<
  CreateTravelOrderMutation
>;
export type CreateTravelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTravelOrderMutation,
  CreateTravelOrderMutationVariables
>;
export const CreateTravelOrderRouteDocument = gql`
  mutation CreateTravelOrderRoute($input: CreateTravelOrderRouteInput!) {
    createTravelOrderRoute(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateTravelOrderRouteMutationFn = ApolloReactCommon.MutationFunction<
  CreateTravelOrderRouteMutation,
  CreateTravelOrderRouteMutationVariables
>;
export type CreateTravelOrderRouteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTravelOrderRouteMutation,
    CreateTravelOrderRouteMutationVariables
  >,
  "mutation"
>;

export const CreateTravelOrderRouteComponent = (
  props: CreateTravelOrderRouteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateTravelOrderRouteMutation,
    CreateTravelOrderRouteMutationVariables
  >
    mutation={CreateTravelOrderRouteDocument}
    {...props}
  />
);

export type CreateTravelOrderRouteProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateTravelOrderRouteMutation,
  CreateTravelOrderRouteMutationVariables
> &
  TChildProps;
export function withCreateTravelOrderRoute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTravelOrderRouteMutation,
    CreateTravelOrderRouteMutationVariables,
    CreateTravelOrderRouteProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTravelOrderRouteMutation,
    CreateTravelOrderRouteMutationVariables,
    CreateTravelOrderRouteProps<TChildProps>
  >(CreateTravelOrderRouteDocument, {
    alias: "withCreateTravelOrderRoute",
    ...operationOptions
  });
}
export type CreateTravelOrderRouteMutationResult = ApolloReactCommon.MutationResult<
  CreateTravelOrderRouteMutation
>;
export type CreateTravelOrderRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTravelOrderRouteMutation,
  CreateTravelOrderRouteMutationVariables
>;
export const CreateUserHoursDocument = gql`
  mutation CreateUserHours($input: CreateUserHoursInput!) {
    createUserHours(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateUserHoursMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserHoursMutation,
  CreateUserHoursMutationVariables
>;
export type CreateUserHoursComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateUserHoursMutation,
    CreateUserHoursMutationVariables
  >,
  "mutation"
>;

export const CreateUserHoursComponent = (
  props: CreateUserHoursComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateUserHoursMutation,
    CreateUserHoursMutationVariables
  >
    mutation={CreateUserHoursDocument}
    {...props}
  />
);

export type CreateUserHoursProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserHoursMutation,
  CreateUserHoursMutationVariables
> &
  TChildProps;
export function withCreateUserHours<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserHoursMutation,
    CreateUserHoursMutationVariables,
    CreateUserHoursProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserHoursMutation,
    CreateUserHoursMutationVariables,
    CreateUserHoursProps<TChildProps>
  >(CreateUserHoursDocument, {
    alias: "withCreateUserHours",
    ...operationOptions
  });
}
export type CreateUserHoursMutationResult = ApolloReactCommon.MutationResult<
  CreateUserHoursMutation
>;
export type CreateUserHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserHoursMutation,
  CreateUserHoursMutationVariables
>;
export const CreateWorkDescriptionReportDocument = gql`
  mutation CreateWorkDescriptionReport(
    $input: CreateWorkDescriptionReportInput!
  ) {
    createWorkDescriptionReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type CreateWorkDescriptionReportMutationFn = ApolloReactCommon.MutationFunction<
  CreateWorkDescriptionReportMutation,
  CreateWorkDescriptionReportMutationVariables
>;
export type CreateWorkDescriptionReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateWorkDescriptionReportMutation,
    CreateWorkDescriptionReportMutationVariables
  >,
  "mutation"
>;

export const CreateWorkDescriptionReportComponent = (
  props: CreateWorkDescriptionReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateWorkDescriptionReportMutation,
    CreateWorkDescriptionReportMutationVariables
  >
    mutation={CreateWorkDescriptionReportDocument}
    {...props}
  />
);

export type CreateWorkDescriptionReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateWorkDescriptionReportMutation,
  CreateWorkDescriptionReportMutationVariables
> &
  TChildProps;
export function withCreateWorkDescriptionReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateWorkDescriptionReportMutation,
    CreateWorkDescriptionReportMutationVariables,
    CreateWorkDescriptionReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateWorkDescriptionReportMutation,
    CreateWorkDescriptionReportMutationVariables,
    CreateWorkDescriptionReportProps<TChildProps>
  >(CreateWorkDescriptionReportDocument, {
    alias: "withCreateWorkDescriptionReport",
    ...operationOptions
  });
}
export type CreateWorkDescriptionReportMutationResult = ApolloReactCommon.MutationResult<
  CreateWorkDescriptionReportMutation
>;
export type CreateWorkDescriptionReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateWorkDescriptionReportMutation,
  CreateWorkDescriptionReportMutationVariables
>;
export const DeleteAccommodationReportDocument = gql`
  mutation DeleteAccommodationReport($input: DeleteAccommodationReportInput!) {
    deleteAccommodationReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteAccommodationReportMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAccommodationReportMutation,
  DeleteAccommodationReportMutationVariables
>;
export type DeleteAccommodationReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteAccommodationReportMutation,
    DeleteAccommodationReportMutationVariables
  >,
  "mutation"
>;

export const DeleteAccommodationReportComponent = (
  props: DeleteAccommodationReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteAccommodationReportMutation,
    DeleteAccommodationReportMutationVariables
  >
    mutation={DeleteAccommodationReportDocument}
    {...props}
  />
);

export type DeleteAccommodationReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteAccommodationReportMutation,
  DeleteAccommodationReportMutationVariables
> &
  TChildProps;
export function withDeleteAccommodationReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteAccommodationReportMutation,
    DeleteAccommodationReportMutationVariables,
    DeleteAccommodationReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteAccommodationReportMutation,
    DeleteAccommodationReportMutationVariables,
    DeleteAccommodationReportProps<TChildProps>
  >(DeleteAccommodationReportDocument, {
    alias: "withDeleteAccommodationReport",
    ...operationOptions
  });
}
export type DeleteAccommodationReportMutationResult = ApolloReactCommon.MutationResult<
  DeleteAccommodationReportMutation
>;
export type DeleteAccommodationReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteAccommodationReportMutation,
  DeleteAccommodationReportMutationVariables
>;
export const DeleteDailyReportDocument = gql`
  mutation DeleteDailyReport($input: DeleteDailyReportInput!) {
    deleteDailyReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteDailyReportMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDailyReportMutation,
  DeleteDailyReportMutationVariables
>;
export type DeleteDailyReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteDailyReportMutation,
    DeleteDailyReportMutationVariables
  >,
  "mutation"
>;

export const DeleteDailyReportComponent = (
  props: DeleteDailyReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteDailyReportMutation,
    DeleteDailyReportMutationVariables
  >
    mutation={DeleteDailyReportDocument}
    {...props}
  />
);

export type DeleteDailyReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteDailyReportMutation,
  DeleteDailyReportMutationVariables
> &
  TChildProps;
export function withDeleteDailyReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteDailyReportMutation,
    DeleteDailyReportMutationVariables,
    DeleteDailyReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteDailyReportMutation,
    DeleteDailyReportMutationVariables,
    DeleteDailyReportProps<TChildProps>
  >(DeleteDailyReportDocument, {
    alias: "withDeleteDailyReport",
    ...operationOptions
  });
}
export type DeleteDailyReportMutationResult = ApolloReactCommon.MutationResult<
  DeleteDailyReportMutation
>;
export type DeleteDailyReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDailyReportMutation,
  DeleteDailyReportMutationVariables
>;
export const DeleteMaterialReportDocument = gql`
  mutation DeleteMaterialReport($input: DeleteMaterialReportInput!) {
    deleteMaterialReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteMaterialReportMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMaterialReportMutation,
  DeleteMaterialReportMutationVariables
>;
export type DeleteMaterialReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMaterialReportMutation,
    DeleteMaterialReportMutationVariables
  >,
  "mutation"
>;

export const DeleteMaterialReportComponent = (
  props: DeleteMaterialReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteMaterialReportMutation,
    DeleteMaterialReportMutationVariables
  >
    mutation={DeleteMaterialReportDocument}
    {...props}
  />
);

export type DeleteMaterialReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteMaterialReportMutation,
  DeleteMaterialReportMutationVariables
> &
  TChildProps;
export function withDeleteMaterialReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMaterialReportMutation,
    DeleteMaterialReportMutationVariables,
    DeleteMaterialReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMaterialReportMutation,
    DeleteMaterialReportMutationVariables,
    DeleteMaterialReportProps<TChildProps>
  >(DeleteMaterialReportDocument, {
    alias: "withDeleteMaterialReport",
    ...operationOptions
  });
}
export type DeleteMaterialReportMutationResult = ApolloReactCommon.MutationResult<
  DeleteMaterialReportMutation
>;
export type DeleteMaterialReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMaterialReportMutation,
  DeleteMaterialReportMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation DeleteTask($input: DeleteTaskInput!) {
    deleteTask(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteTaskMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export type DeleteTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >,
  "mutation"
>;

export const DeleteTaskComponent = (props: DeleteTaskComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >
    mutation={DeleteTaskDocument}
    {...props}
  />
);

export type DeleteTaskProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
> &
  TChildProps;
export function withDeleteTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTaskMutation,
    DeleteTaskMutationVariables,
    DeleteTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTaskMutation,
    DeleteTaskMutationVariables,
    DeleteTaskProps<TChildProps>
  >(DeleteTaskDocument, {
    alias: "withDeleteTask",
    ...operationOptions
  });
}
export type DeleteTaskMutationResult = ApolloReactCommon.MutationResult<
  DeleteTaskMutation
>;
export type DeleteTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const DeleteTravelCostReportDocument = gql`
  mutation DeleteTravelCostReport($input: DeleteTravelCostReportInput!) {
    deleteTravelCostReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteTravelCostReportMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTravelCostReportMutation,
  DeleteTravelCostReportMutationVariables
>;
export type DeleteTravelCostReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTravelCostReportMutation,
    DeleteTravelCostReportMutationVariables
  >,
  "mutation"
>;

export const DeleteTravelCostReportComponent = (
  props: DeleteTravelCostReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteTravelCostReportMutation,
    DeleteTravelCostReportMutationVariables
  >
    mutation={DeleteTravelCostReportDocument}
    {...props}
  />
);

export type DeleteTravelCostReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteTravelCostReportMutation,
  DeleteTravelCostReportMutationVariables
> &
  TChildProps;
export function withDeleteTravelCostReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTravelCostReportMutation,
    DeleteTravelCostReportMutationVariables,
    DeleteTravelCostReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTravelCostReportMutation,
    DeleteTravelCostReportMutationVariables,
    DeleteTravelCostReportProps<TChildProps>
  >(DeleteTravelCostReportDocument, {
    alias: "withDeleteTravelCostReport",
    ...operationOptions
  });
}
export type DeleteTravelCostReportMutationResult = ApolloReactCommon.MutationResult<
  DeleteTravelCostReportMutation
>;
export type DeleteTravelCostReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTravelCostReportMutation,
  DeleteTravelCostReportMutationVariables
>;
export const DeleteTravelOrderDocument = gql`
  mutation DeleteTravelOrder($input: DeleteTravelOrderInput!) {
    deleteTravelOrder(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteTravelOrderMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTravelOrderMutation,
  DeleteTravelOrderMutationVariables
>;
export type DeleteTravelOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTravelOrderMutation,
    DeleteTravelOrderMutationVariables
  >,
  "mutation"
>;

export const DeleteTravelOrderComponent = (
  props: DeleteTravelOrderComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteTravelOrderMutation,
    DeleteTravelOrderMutationVariables
  >
    mutation={DeleteTravelOrderDocument}
    {...props}
  />
);

export type DeleteTravelOrderProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteTravelOrderMutation,
  DeleteTravelOrderMutationVariables
> &
  TChildProps;
export function withDeleteTravelOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTravelOrderMutation,
    DeleteTravelOrderMutationVariables,
    DeleteTravelOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTravelOrderMutation,
    DeleteTravelOrderMutationVariables,
    DeleteTravelOrderProps<TChildProps>
  >(DeleteTravelOrderDocument, {
    alias: "withDeleteTravelOrder",
    ...operationOptions
  });
}
export type DeleteTravelOrderMutationResult = ApolloReactCommon.MutationResult<
  DeleteTravelOrderMutation
>;
export type DeleteTravelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTravelOrderMutation,
  DeleteTravelOrderMutationVariables
>;
export const DeleteTravelOrderRouteDocument = gql`
  mutation DeleteTravelOrderRoute($input: DeleteTravelOrderRouteInput!) {
    deleteTravelOrderRoute(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteTravelOrderRouteMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTravelOrderRouteMutation,
  DeleteTravelOrderRouteMutationVariables
>;
export type DeleteTravelOrderRouteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTravelOrderRouteMutation,
    DeleteTravelOrderRouteMutationVariables
  >,
  "mutation"
>;

export const DeleteTravelOrderRouteComponent = (
  props: DeleteTravelOrderRouteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteTravelOrderRouteMutation,
    DeleteTravelOrderRouteMutationVariables
  >
    mutation={DeleteTravelOrderRouteDocument}
    {...props}
  />
);

export type DeleteTravelOrderRouteProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteTravelOrderRouteMutation,
  DeleteTravelOrderRouteMutationVariables
> &
  TChildProps;
export function withDeleteTravelOrderRoute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTravelOrderRouteMutation,
    DeleteTravelOrderRouteMutationVariables,
    DeleteTravelOrderRouteProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTravelOrderRouteMutation,
    DeleteTravelOrderRouteMutationVariables,
    DeleteTravelOrderRouteProps<TChildProps>
  >(DeleteTravelOrderRouteDocument, {
    alias: "withDeleteTravelOrderRoute",
    ...operationOptions
  });
}
export type DeleteTravelOrderRouteMutationResult = ApolloReactCommon.MutationResult<
  DeleteTravelOrderRouteMutation
>;
export type DeleteTravelOrderRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTravelOrderRouteMutation,
  DeleteTravelOrderRouteMutationVariables
>;
export const DeleteUserHoursDocument = gql`
  mutation DeleteUserHours($input: DeleteUserHoursInput!) {
    deleteUserHours(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteUserHoursMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserHoursMutation,
  DeleteUserHoursMutationVariables
>;
export type DeleteUserHoursComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteUserHoursMutation,
    DeleteUserHoursMutationVariables
  >,
  "mutation"
>;

export const DeleteUserHoursComponent = (
  props: DeleteUserHoursComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteUserHoursMutation,
    DeleteUserHoursMutationVariables
  >
    mutation={DeleteUserHoursDocument}
    {...props}
  />
);

export type DeleteUserHoursProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserHoursMutation,
  DeleteUserHoursMutationVariables
> &
  TChildProps;
export function withDeleteUserHours<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserHoursMutation,
    DeleteUserHoursMutationVariables,
    DeleteUserHoursProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserHoursMutation,
    DeleteUserHoursMutationVariables,
    DeleteUserHoursProps<TChildProps>
  >(DeleteUserHoursDocument, {
    alias: "withDeleteUserHours",
    ...operationOptions
  });
}
export type DeleteUserHoursMutationResult = ApolloReactCommon.MutationResult<
  DeleteUserHoursMutation
>;
export type DeleteUserHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserHoursMutation,
  DeleteUserHoursMutationVariables
>;
export const DeleteWorkDescriptionReportDocument = gql`
  mutation DeleteWorkDescriptionReport(
    $input: DeleteWorkDescriptionReportInput!
  ) {
    deleteWorkDescriptionReport(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type DeleteWorkDescriptionReportMutationFn = ApolloReactCommon.MutationFunction<
  DeleteWorkDescriptionReportMutation,
  DeleteWorkDescriptionReportMutationVariables
>;
export type DeleteWorkDescriptionReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteWorkDescriptionReportMutation,
    DeleteWorkDescriptionReportMutationVariables
  >,
  "mutation"
>;

export const DeleteWorkDescriptionReportComponent = (
  props: DeleteWorkDescriptionReportComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteWorkDescriptionReportMutation,
    DeleteWorkDescriptionReportMutationVariables
  >
    mutation={DeleteWorkDescriptionReportDocument}
    {...props}
  />
);

export type DeleteWorkDescriptionReportProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteWorkDescriptionReportMutation,
  DeleteWorkDescriptionReportMutationVariables
> &
  TChildProps;
export function withDeleteWorkDescriptionReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteWorkDescriptionReportMutation,
    DeleteWorkDescriptionReportMutationVariables,
    DeleteWorkDescriptionReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteWorkDescriptionReportMutation,
    DeleteWorkDescriptionReportMutationVariables,
    DeleteWorkDescriptionReportProps<TChildProps>
  >(DeleteWorkDescriptionReportDocument, {
    alias: "withDeleteWorkDescriptionReport",
    ...operationOptions
  });
}
export type DeleteWorkDescriptionReportMutationResult = ApolloReactCommon.MutationResult<
  DeleteWorkDescriptionReportMutation
>;
export type DeleteWorkDescriptionReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteWorkDescriptionReportMutation,
  DeleteWorkDescriptionReportMutationVariables
>;
export const TokenAuthDocument = gql`
  mutation TokenAuth($email: String!, $password: String!, $remember: Boolean!) {
    tokenAuth(email: $email, password: $password, remember: $remember) {
      status
      notification {
        ...Notification
      }
      token
      user {
        id
        fullName
        email
        role {
          id
          name
          permissions
        }
      }
    }
  }
  ${NotificationFragmentDoc}
`;
export type TokenAuthMutationFn = ApolloReactCommon.MutationFunction<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;
export type TokenAuthComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    TokenAuthMutation,
    TokenAuthMutationVariables
  >,
  "mutation"
>;

export const TokenAuthComponent = (props: TokenAuthComponentProps) => (
  <ApolloReactComponents.Mutation<TokenAuthMutation, TokenAuthMutationVariables>
    mutation={TokenAuthDocument}
    {...props}
  />
);

export type TokenAuthProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  TokenAuthMutation,
  TokenAuthMutationVariables
> &
  TChildProps;
export function withTokenAuth<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TokenAuthMutation,
    TokenAuthMutationVariables,
    TokenAuthProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    TokenAuthMutation,
    TokenAuthMutationVariables,
    TokenAuthProps<TChildProps>
  >(TokenAuthDocument, {
    alias: "withTokenAuth",
    ...operationOptions
  });
}
export type TokenAuthMutationResult = ApolloReactCommon.MutationResult<
  TokenAuthMutation
>;
export type TokenAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type UpdateEventMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export type UpdateEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateEventMutation,
    UpdateEventMutationVariables
  >,
  "mutation"
>;

export const UpdateEventComponent = (props: UpdateEventComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateEventMutation,
    UpdateEventMutationVariables
  >
    mutation={UpdateEventDocument}
    {...props}
  />
);

export type UpdateEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateEventMutation,
  UpdateEventMutationVariables
> &
  TChildProps;
export function withUpdateEvent<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventMutation,
    UpdateEventMutationVariables,
    UpdateEventProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventMutation,
    UpdateEventMutationVariables,
    UpdateEventProps<TChildProps>
  >(UpdateEventDocument, {
    alias: "withUpdateEvent",
    ...operationOptions
  });
}
export type UpdateEventMutationResult = ApolloReactCommon.MutationResult<
  UpdateEventMutation
>;
export type UpdateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const UpdatePayoutsTableDocument = gql`
  mutation UpdatePayoutsTable($input: UpdatePayoutsTableInput!) {
    updatePayoutsTable(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type UpdatePayoutsTableMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePayoutsTableMutation,
  UpdatePayoutsTableMutationVariables
>;
export type UpdatePayoutsTableComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePayoutsTableMutation,
    UpdatePayoutsTableMutationVariables
  >,
  "mutation"
>;

export const UpdatePayoutsTableComponent = (
  props: UpdatePayoutsTableComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePayoutsTableMutation,
    UpdatePayoutsTableMutationVariables
  >
    mutation={UpdatePayoutsTableDocument}
    {...props}
  />
);

export type UpdatePayoutsTableProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdatePayoutsTableMutation,
  UpdatePayoutsTableMutationVariables
> &
  TChildProps;
export function withUpdatePayoutsTable<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePayoutsTableMutation,
    UpdatePayoutsTableMutationVariables,
    UpdatePayoutsTableProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePayoutsTableMutation,
    UpdatePayoutsTableMutationVariables,
    UpdatePayoutsTableProps<TChildProps>
  >(UpdatePayoutsTableDocument, {
    alias: "withUpdatePayoutsTable",
    ...operationOptions
  });
}
export type UpdatePayoutsTableMutationResult = ApolloReactCommon.MutationResult<
  UpdatePayoutsTableMutation
>;
export type UpdatePayoutsTableMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePayoutsTableMutation,
  UpdatePayoutsTableMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export type UpdateTaskComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >,
  "mutation"
>;

export const UpdateTaskComponent = (props: UpdateTaskComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >
    mutation={UpdateTaskDocument}
    {...props}
  />
);

export type UpdateTaskProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
> &
  TChildProps;
export function withUpdateTask<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTaskMutation,
    UpdateTaskMutationVariables,
    UpdateTaskProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTaskMutation,
    UpdateTaskMutationVariables,
    UpdateTaskProps<TChildProps>
  >(UpdateTaskDocument, {
    alias: "withUpdateTask",
    ...operationOptions
  });
}
export type UpdateTaskMutationResult = ApolloReactCommon.MutationResult<
  UpdateTaskMutation
>;
export type UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateTravelOrderRouteDocument = gql`
  mutation UpdateTravelOrderRoute($input: UpdateTravelOrderRouteInput!) {
    updateTravelOrderRoute(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type UpdateTravelOrderRouteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTravelOrderRouteMutation,
  UpdateTravelOrderRouteMutationVariables
>;
export type UpdateTravelOrderRouteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTravelOrderRouteMutation,
    UpdateTravelOrderRouteMutationVariables
  >,
  "mutation"
>;

export const UpdateTravelOrderRouteComponent = (
  props: UpdateTravelOrderRouteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateTravelOrderRouteMutation,
    UpdateTravelOrderRouteMutationVariables
  >
    mutation={UpdateTravelOrderRouteDocument}
    {...props}
  />
);

export type UpdateTravelOrderRouteProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateTravelOrderRouteMutation,
  UpdateTravelOrderRouteMutationVariables
> &
  TChildProps;
export function withUpdateTravelOrderRoute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTravelOrderRouteMutation,
    UpdateTravelOrderRouteMutationVariables,
    UpdateTravelOrderRouteProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTravelOrderRouteMutation,
    UpdateTravelOrderRouteMutationVariables,
    UpdateTravelOrderRouteProps<TChildProps>
  >(UpdateTravelOrderRouteDocument, {
    alias: "withUpdateTravelOrderRoute",
    ...operationOptions
  });
}
export type UpdateTravelOrderRouteMutationResult = ApolloReactCommon.MutationResult<
  UpdateTravelOrderRouteMutation
>;
export type UpdateTravelOrderRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTravelOrderRouteMutation,
  UpdateTravelOrderRouteMutationVariables
>;
export const UpdateUserHoursDocument = gql`
  mutation UpdateUserHours($input: UpdateUserHoursInput!) {
    updateUserHours(input: $input) {
      ...FormResponse
    }
  }
  ${FormResponseFragmentDoc}
`;
export type UpdateUserHoursMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserHoursMutation,
  UpdateUserHoursMutationVariables
>;
export type UpdateUserHoursComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserHoursMutation,
    UpdateUserHoursMutationVariables
  >,
  "mutation"
>;

export const UpdateUserHoursComponent = (
  props: UpdateUserHoursComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserHoursMutation,
    UpdateUserHoursMutationVariables
  >
    mutation={UpdateUserHoursDocument}
    {...props}
  />
);

export type UpdateUserHoursProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserHoursMutation,
  UpdateUserHoursMutationVariables
> &
  TChildProps;
export function withUpdateUserHours<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserHoursMutation,
    UpdateUserHoursMutationVariables,
    UpdateUserHoursProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserHoursMutation,
    UpdateUserHoursMutationVariables,
    UpdateUserHoursProps<TChildProps>
  >(UpdateUserHoursDocument, {
    alias: "withUpdateUserHours",
    ...operationOptions
  });
}
export type UpdateUserHoursMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserHoursMutation
>;
export type UpdateUserHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserHoursMutation,
  UpdateUserHoursMutationVariables
>;
export const CurrencyDocument = gql`
  query Currency($order: OrderInput) {
    currency(order: $order) {
      id
      codeName
      code
    }
  }
`;
export type CurrencyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CurrencyQuery,
    CurrencyQueryVariables
  >,
  "query"
>;

export const CurrencyComponent = (props: CurrencyComponentProps) => (
  <ApolloReactComponents.Query<CurrencyQuery, CurrencyQueryVariables>
    query={CurrencyDocument}
    {...props}
  />
);

export type CurrencyProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CurrencyQuery,
  CurrencyQueryVariables
> &
  TChildProps;
export function withCurrency<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrencyQuery,
    CurrencyQueryVariables,
    CurrencyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrencyQuery,
    CurrencyQueryVariables,
    CurrencyProps<TChildProps>
  >(CurrencyDocument, {
    alias: "withCurrency",
    ...operationOptions
  });
}
export type CurrencyQueryResult = ApolloReactCommon.QueryResult<
  CurrencyQuery,
  CurrencyQueryVariables
>;
export const CurrentLoggedUserDocument = gql`
  query CurrentLoggedUser {
    currentLoggedUser {
      id
      fullName
      email
      role {
        ...ListItem
        permissions
      }
    }
  }
  ${ListItemFragmentDoc}
`;
export type CurrentLoggedUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CurrentLoggedUserQuery,
    CurrentLoggedUserQueryVariables
  >,
  "query"
>;

export const CurrentLoggedUserComponent = (
  props: CurrentLoggedUserComponentProps
) => (
  <ApolloReactComponents.Query<
    CurrentLoggedUserQuery,
    CurrentLoggedUserQueryVariables
  >
    query={CurrentLoggedUserDocument}
    {...props}
  />
);

export type CurrentLoggedUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CurrentLoggedUserQuery,
  CurrentLoggedUserQueryVariables
> &
  TChildProps;
export function withCurrentLoggedUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentLoggedUserQuery,
    CurrentLoggedUserQueryVariables,
    CurrentLoggedUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentLoggedUserQuery,
    CurrentLoggedUserQueryVariables,
    CurrentLoggedUserProps<TChildProps>
  >(CurrentLoggedUserDocument, {
    alias: "withCurrentLoggedUser",
    ...operationOptions
  });
}
export type CurrentLoggedUserQueryResult = ApolloReactCommon.QueryResult<
  CurrentLoggedUserQuery,
  CurrentLoggedUserQueryVariables
>;
export const DailyReportDocument = gql`
  query DailyReport($order: OrderInput!, $date: Int!) {
    dailyReport(order: $order, date: $date) {
      materialReports {
        id
        materialSupplier {
          ...ListItem
        }
        invoiceNo
        priceIncVAT
        currency {
          id
          code
        }
      }
      travelCostReports {
        id
        travelCostType {
          ...ListItem
        }
        travelCostSupplier {
          ...ListItem
        }
        invoiceNo
        currency {
          id
          code
        }
        priceIncVAT
      }
      accommodationReports {
        id
        hotelType {
          ...ListItem
        }
        nightsCount
        personsCount
        invoiceNo
        priceIncVAT
        currency {
          code
        }
      }
      workDescriptionReports {
        id
        description
      }
    }
  }
  ${ListItemFragmentDoc}
`;
export type DailyReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DailyReportQuery,
    DailyReportQueryVariables
  >,
  "query"
> &
  (
    | { variables: DailyReportQueryVariables; skip?: boolean }
    | { skip: boolean });

export const DailyReportComponent = (props: DailyReportComponentProps) => (
  <ApolloReactComponents.Query<DailyReportQuery, DailyReportQueryVariables>
    query={DailyReportDocument}
    {...props}
  />
);

export type DailyReportProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DailyReportQuery,
  DailyReportQueryVariables
> &
  TChildProps;
export function withDailyReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DailyReportQuery,
    DailyReportQueryVariables,
    DailyReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DailyReportQuery,
    DailyReportQueryVariables,
    DailyReportProps<TChildProps>
  >(DailyReportDocument, {
    alias: "withDailyReport",
    ...operationOptions
  });
}
export type DailyReportQueryResult = ApolloReactCommon.QueryResult<
  DailyReportQuery,
  DailyReportQueryVariables
>;
export const DailyReportsDocument = gql`
  query DailyReports($date: Int!) {
    dailyReports(date: $date) {
      date
      order {
        ...ListItem
      }
      weather {
        ...ListItem
      }
      temperature
      personsCount
      workHoursFrom
      workHoursTo
      distance
    }
  }
  ${ListItemFragmentDoc}
`;
export type DailyReportsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DailyReportsQuery,
    DailyReportsQueryVariables
  >,
  "query"
> &
  (
    | { variables: DailyReportsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const DailyReportsComponent = (props: DailyReportsComponentProps) => (
  <ApolloReactComponents.Query<DailyReportsQuery, DailyReportsQueryVariables>
    query={DailyReportsDocument}
    {...props}
  />
);

export type DailyReportsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DailyReportsQuery,
  DailyReportsQueryVariables
> &
  TChildProps;
export function withDailyReports<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DailyReportsQuery,
    DailyReportsQueryVariables,
    DailyReportsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DailyReportsQuery,
    DailyReportsQueryVariables,
    DailyReportsProps<TChildProps>
  >(DailyReportsDocument, {
    alias: "withDailyReports",
    ...operationOptions
  });
}
export type DailyReportsQueryResult = ApolloReactCommon.QueryResult<
  DailyReportsQuery,
  DailyReportsQueryVariables
>;
export const DashboardNotificationsDocument = gql`
  query DashboardNotifications {
    dashboardNotifications {
      title
      type
      message
    }
  }
`;
export type DashboardNotificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DashboardNotificationsQuery,
    DashboardNotificationsQueryVariables
  >,
  "query"
>;

export const DashboardNotificationsComponent = (
  props: DashboardNotificationsComponentProps
) => (
  <ApolloReactComponents.Query<
    DashboardNotificationsQuery,
    DashboardNotificationsQueryVariables
  >
    query={DashboardNotificationsDocument}
    {...props}
  />
);

export type DashboardNotificationsProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  DashboardNotificationsQuery,
  DashboardNotificationsQueryVariables
> &
  TChildProps;
export function withDashboardNotifications<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DashboardNotificationsQuery,
    DashboardNotificationsQueryVariables,
    DashboardNotificationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DashboardNotificationsQuery,
    DashboardNotificationsQueryVariables,
    DashboardNotificationsProps<TChildProps>
  >(DashboardNotificationsDocument, {
    alias: "withDashboardNotifications",
    ...operationOptions
  });
}
export type DashboardNotificationsQueryResult = ApolloReactCommon.QueryResult<
  DashboardNotificationsQuery,
  DashboardNotificationsQueryVariables
>;
export const DefaultCurrencyDocument = gql`
  query DefaultCurrency($order: OrderInput!) {
    defaultCurrency(order: $order) {
      id
    }
  }
`;
export type DefaultCurrencyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DefaultCurrencyQuery,
    DefaultCurrencyQueryVariables
  >,
  "query"
> &
  (
    | { variables: DefaultCurrencyQueryVariables; skip?: boolean }
    | { skip: boolean });

export const DefaultCurrencyComponent = (
  props: DefaultCurrencyComponentProps
) => (
  <ApolloReactComponents.Query<
    DefaultCurrencyQuery,
    DefaultCurrencyQueryVariables
  >
    query={DefaultCurrencyDocument}
    {...props}
  />
);

export type DefaultCurrencyProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DefaultCurrencyQuery,
  DefaultCurrencyQueryVariables
> &
  TChildProps;
export function withDefaultCurrency<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DefaultCurrencyQuery,
    DefaultCurrencyQueryVariables,
    DefaultCurrencyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DefaultCurrencyQuery,
    DefaultCurrencyQueryVariables,
    DefaultCurrencyProps<TChildProps>
  >(DefaultCurrencyDocument, {
    alias: "withDefaultCurrency",
    ...operationOptions
  });
}
export type DefaultCurrencyQueryResult = ApolloReactCommon.QueryResult<
  DefaultCurrencyQuery,
  DefaultCurrencyQueryVariables
>;
export const DefaultExchangeRateEurczkDocument = gql`
  query DefaultExchangeRateEURCZK {
    defaultExchangeRateEURCZK
  }
`;
export type DefaultExchangeRateEurczkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables
  >,
  "query"
>;

export const DefaultExchangeRateEurczkComponent = (
  props: DefaultExchangeRateEurczkComponentProps
) => (
  <ApolloReactComponents.Query<
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables
  >
    query={DefaultExchangeRateEurczkDocument}
    {...props}
  />
);

export type DefaultExchangeRateEurczkProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  DefaultExchangeRateEurczkQuery,
  DefaultExchangeRateEurczkQueryVariables
> &
  TChildProps;
export function withDefaultExchangeRateEurczk<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables,
    DefaultExchangeRateEurczkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DefaultExchangeRateEurczkQuery,
    DefaultExchangeRateEurczkQueryVariables,
    DefaultExchangeRateEurczkProps<TChildProps>
  >(DefaultExchangeRateEurczkDocument, {
    alias: "withDefaultExchangeRateEurczk",
    ...operationOptions
  });
}
export type DefaultExchangeRateEurczkQueryResult = ApolloReactCommon.QueryResult<
  DefaultExchangeRateEurczkQuery,
  DefaultExchangeRateEurczkQueryVariables
>;
export const DefaultTravelOrderPurposeDocument = gql`
  query DefaultTravelOrderPurpose {
    defaultTravelOrderPurpose
  }
`;
export type DefaultTravelOrderPurposeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DefaultTravelOrderPurposeQuery,
    DefaultTravelOrderPurposeQueryVariables
  >,
  "query"
>;

export const DefaultTravelOrderPurposeComponent = (
  props: DefaultTravelOrderPurposeComponentProps
) => (
  <ApolloReactComponents.Query<
    DefaultTravelOrderPurposeQuery,
    DefaultTravelOrderPurposeQueryVariables
  >
    query={DefaultTravelOrderPurposeDocument}
    {...props}
  />
);

export type DefaultTravelOrderPurposeProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  DefaultTravelOrderPurposeQuery,
  DefaultTravelOrderPurposeQueryVariables
> &
  TChildProps;
export function withDefaultTravelOrderPurpose<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DefaultTravelOrderPurposeQuery,
    DefaultTravelOrderPurposeQueryVariables,
    DefaultTravelOrderPurposeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DefaultTravelOrderPurposeQuery,
    DefaultTravelOrderPurposeQueryVariables,
    DefaultTravelOrderPurposeProps<TChildProps>
  >(DefaultTravelOrderPurposeDocument, {
    alias: "withDefaultTravelOrderPurpose",
    ...operationOptions
  });
}
export type DefaultTravelOrderPurposeQueryResult = ApolloReactCommon.QueryResult<
  DefaultTravelOrderPurposeQuery,
  DefaultTravelOrderPurposeQueryVariables
>;
export const EconomicsTableDocument = gql`
  query EconomicsTable(
    $order: OrderInput!
    $monthTimestamp: Int
    $weekTimestamp: Int
    $margin: Int
    $transportCostPerUnit: Int
    $transportCustomerPerUnit: Int
    $customerHourlyRate: Int
    $exchangeRateEURCZK: Float
  ) {
    economicsTable(
      order: $order
      monthTimestamp: $monthTimestamp
      weekTimestamp: $weekTimestamp
      margin: $margin
      transportCostPerUnit: $transportCostPerUnit
      transportCustomerPerUnit: $transportCustomerPerUnit
      customerHourlyRate: $customerHourlyRate
      exchangeRateEURCZK: $exchangeRateEURCZK
    ) {
      type
      count
      measurementUnit
      costs
      customer
    }
  }
`;
export type EconomicsTableComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EconomicsTableQuery,
    EconomicsTableQueryVariables
  >,
  "query"
> &
  (
    | { variables: EconomicsTableQueryVariables; skip?: boolean }
    | { skip: boolean });

export const EconomicsTableComponent = (
  props: EconomicsTableComponentProps
) => (
  <ApolloReactComponents.Query<
    EconomicsTableQuery,
    EconomicsTableQueryVariables
  >
    query={EconomicsTableDocument}
    {...props}
  />
);

export type EconomicsTableProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EconomicsTableQuery,
  EconomicsTableQueryVariables
> &
  TChildProps;
export function withEconomicsTable<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EconomicsTableQuery,
    EconomicsTableQueryVariables,
    EconomicsTableProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EconomicsTableQuery,
    EconomicsTableQueryVariables,
    EconomicsTableProps<TChildProps>
  >(EconomicsTableDocument, {
    alias: "withEconomicsTable",
    ...operationOptions
  });
}
export type EconomicsTableQueryResult = ApolloReactCommon.QueryResult<
  EconomicsTableQuery,
  EconomicsTableQueryVariables
>;
export const EmployeesDocument = gql`
  query Employees($date: Int, $orderId: Int) {
    employees(date: $date, orderId: $orderId) {
      ...Employee
    }
  }
  ${EmployeeFragmentDoc}
`;
export type EmployeesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EmployeesQuery,
    EmployeesQueryVariables
  >,
  "query"
>;

export const EmployeesComponent = (props: EmployeesComponentProps) => (
  <ApolloReactComponents.Query<EmployeesQuery, EmployeesQueryVariables>
    query={EmployeesDocument}
    {...props}
  />
);

export type EmployeesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EmployeesQuery,
  EmployeesQueryVariables
> &
  TChildProps;
export function withEmployees<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EmployeesQuery,
    EmployeesQueryVariables,
    EmployeesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EmployeesQuery,
    EmployeesQueryVariables,
    EmployeesProps<TChildProps>
  >(EmployeesDocument, {
    alias: "withEmployees",
    ...operationOptions
  });
}
export type EmployeesQueryResult = ApolloReactCommon.QueryResult<
  EmployeesQuery,
  EmployeesQueryVariables
>;
export const EventsDocument = gql`
  query Events($monthTimestamp: Int!) {
    events(monthTimestamp: $monthTimestamp) {
      id
      dateTimeFrom
      dateTimeTo
      description
      guests {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`;
export type EventsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EventsQuery,
    EventsQueryVariables
  >,
  "query"
> &
  ({ variables: EventsQueryVariables; skip?: boolean } | { skip: boolean });

export const EventsComponent = (props: EventsComponentProps) => (
  <ApolloReactComponents.Query<EventsQuery, EventsQueryVariables>
    query={EventsDocument}
    {...props}
  />
);

export type EventsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  EventsQuery,
  EventsQueryVariables
> &
  TChildProps;
export function withEvents<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EventsQuery,
    EventsQueryVariables,
    EventsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EventsQuery,
    EventsQueryVariables,
    EventsProps<TChildProps>
  >(EventsDocument, {
    alias: "withEvents",
    ...operationOptions
  });
}
export type EventsQueryResult = ApolloReactCommon.QueryResult<
  EventsQuery,
  EventsQueryVariables
>;
export const HotelTypesDocument = gql`
  query HotelTypes {
    hotelTypes {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type HotelTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HotelTypesQuery,
    HotelTypesQueryVariables
  >,
  "query"
>;

export const HotelTypesComponent = (props: HotelTypesComponentProps) => (
  <ApolloReactComponents.Query<HotelTypesQuery, HotelTypesQueryVariables>
    query={HotelTypesDocument}
    {...props}
  />
);

export type HotelTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HotelTypesQuery,
  HotelTypesQueryVariables
> &
  TChildProps;
export function withHotelTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HotelTypesQuery,
    HotelTypesQueryVariables,
    HotelTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HotelTypesQuery,
    HotelTypesQueryVariables,
    HotelTypesProps<TChildProps>
  >(HotelTypesDocument, {
    alias: "withHotelTypes",
    ...operationOptions
  });
}
export type HotelTypesQueryResult = ApolloReactCommon.QueryResult<
  HotelTypesQuery,
  HotelTypesQueryVariables
>;
export const MaterialSupplierGroupsDocument = gql`
  query MaterialSupplierGroups {
    materialSupplierGroups {
      ...ListItem
      materialSuppliers {
        ...ListItem
      }
    }
  }
  ${ListItemFragmentDoc}
`;
export type MaterialSupplierGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MaterialSupplierGroupsQuery,
    MaterialSupplierGroupsQueryVariables
  >,
  "query"
>;

export const MaterialSupplierGroupsComponent = (
  props: MaterialSupplierGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    MaterialSupplierGroupsQuery,
    MaterialSupplierGroupsQueryVariables
  >
    query={MaterialSupplierGroupsDocument}
    {...props}
  />
);

export type MaterialSupplierGroupsProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  MaterialSupplierGroupsQuery,
  MaterialSupplierGroupsQueryVariables
> &
  TChildProps;
export function withMaterialSupplierGroups<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MaterialSupplierGroupsQuery,
    MaterialSupplierGroupsQueryVariables,
    MaterialSupplierGroupsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MaterialSupplierGroupsQuery,
    MaterialSupplierGroupsQueryVariables,
    MaterialSupplierGroupsProps<TChildProps>
  >(MaterialSupplierGroupsDocument, {
    alias: "withMaterialSupplierGroups",
    ...operationOptions
  });
}
export type MaterialSupplierGroupsQueryResult = ApolloReactCommon.QueryResult<
  MaterialSupplierGroupsQuery,
  MaterialSupplierGroupsQueryVariables
>;
export const MaxPrepaymentAmountDocument = gql`
  query MaxPrepaymentAmount(
    $employee: EmployeeInput!
    $currency: CurrencyInput!
  ) {
    maxPrepaymentAmount(employee: $employee, currency: $currency)
  }
`;
export type MaxPrepaymentAmountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MaxPrepaymentAmountQuery,
    MaxPrepaymentAmountQueryVariables
  >,
  "query"
> &
  (
    | { variables: MaxPrepaymentAmountQueryVariables; skip?: boolean }
    | { skip: boolean });

export const MaxPrepaymentAmountComponent = (
  props: MaxPrepaymentAmountComponentProps
) => (
  <ApolloReactComponents.Query<
    MaxPrepaymentAmountQuery,
    MaxPrepaymentAmountQueryVariables
  >
    query={MaxPrepaymentAmountDocument}
    {...props}
  />
);

export type MaxPrepaymentAmountProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  MaxPrepaymentAmountQuery,
  MaxPrepaymentAmountQueryVariables
> &
  TChildProps;
export function withMaxPrepaymentAmount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MaxPrepaymentAmountQuery,
    MaxPrepaymentAmountQueryVariables,
    MaxPrepaymentAmountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MaxPrepaymentAmountQuery,
    MaxPrepaymentAmountQueryVariables,
    MaxPrepaymentAmountProps<TChildProps>
  >(MaxPrepaymentAmountDocument, {
    alias: "withMaxPrepaymentAmount",
    ...operationOptions
  });
}
export type MaxPrepaymentAmountQueryResult = ApolloReactCommon.QueryResult<
  MaxPrepaymentAmountQuery,
  MaxPrepaymentAmountQueryVariables
>;
export const MyTasksDocument = gql`
  query MyTasks {
    myTasks {
      id
      description
      isCompleted
    }
  }
`;
export type MyTasksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MyTasksQuery,
    MyTasksQueryVariables
  >,
  "query"
>;

export const MyTasksComponent = (props: MyTasksComponentProps) => (
  <ApolloReactComponents.Query<MyTasksQuery, MyTasksQueryVariables>
    query={MyTasksDocument}
    {...props}
  />
);

export type MyTasksProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MyTasksQuery,
  MyTasksQueryVariables
> &
  TChildProps;
export function withMyTasks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MyTasksQuery,
    MyTasksQueryVariables,
    MyTasksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MyTasksQuery,
    MyTasksQueryVariables,
    MyTasksProps<TChildProps>
  >(MyTasksDocument, {
    alias: "withMyTasks",
    ...operationOptions
  });
}
export type MyTasksQueryResult = ApolloReactCommon.QueryResult<
  MyTasksQuery,
  MyTasksQueryVariables
>;
export const OrdersDocument = gql`
  query Orders($date: Int) {
    orders(date: $date) {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type OrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OrdersQuery,
    OrdersQueryVariables
  >,
  "query"
>;

export const OrdersComponent = (props: OrdersComponentProps) => (
  <ApolloReactComponents.Query<OrdersQuery, OrdersQueryVariables>
    query={OrdersDocument}
    {...props}
  />
);

export type OrdersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  OrdersQuery,
  OrdersQueryVariables
> &
  TChildProps;
export function withOrders<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrdersQuery,
    OrdersQueryVariables,
    OrdersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrdersQuery,
    OrdersQueryVariables,
    OrdersProps<TChildProps>
  >(OrdersDocument, {
    alias: "withOrders",
    ...operationOptions
  });
}
export type OrdersQueryResult = ApolloReactCommon.QueryResult<
  OrdersQuery,
  OrdersQueryVariables
>;
export const PavelsTableDocument = gql`
  query PavelsTable($monthTimestamp: Int!) {
    pavelsTable(monthTimestamp: $monthTimestamp) {
      employee {
        ...Employee
      }
      depositSum
      totalHours
      userHours {
        id
        date
        hoursCount
        rate {
          ...ListItem
        }
      }
    }
  }
  ${EmployeeFragmentDoc}
  ${ListItemFragmentDoc}
`;
export type PavelsTableComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PavelsTableQuery,
    PavelsTableQueryVariables
  >,
  "query"
> &
  (
    | { variables: PavelsTableQueryVariables; skip?: boolean }
    | { skip: boolean });

export const PavelsTableComponent = (props: PavelsTableComponentProps) => (
  <ApolloReactComponents.Query<PavelsTableQuery, PavelsTableQueryVariables>
    query={PavelsTableDocument}
    {...props}
  />
);

export type PavelsTableProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PavelsTableQuery,
  PavelsTableQueryVariables
> &
  TChildProps;
export function withPavelsTable<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PavelsTableQuery,
    PavelsTableQueryVariables,
    PavelsTableProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PavelsTableQuery,
    PavelsTableQueryVariables,
    PavelsTableProps<TChildProps>
  >(PavelsTableDocument, {
    alias: "withPavelsTable",
    ...operationOptions
  });
}
export type PavelsTableQueryResult = ApolloReactCommon.QueryResult<
  PavelsTableQuery,
  PavelsTableQueryVariables
>;
export const PayoutsTableDocument = gql`
  query PayoutsTable($monthTimestamp: Int!, $exchangeRateEURCZK: Float!) {
    payoutsTable(
      monthTimestamp: $monthTimestamp
      exchangeRateEURCZK: $exchangeRateEURCZK
    ) {
      employee {
        ...Employee
      }
      hoursCR
      hoursEU
      payoutCR
      payoutEU
      bonusCR
      depositCR
      levies
      flatRate
      previousRest
      repayment
      penaltyCR
      penaltyNotesCR
      distraintsCount
      distraintsTax
      finalSurcharge
      paid
      note
    }
  }
  ${EmployeeFragmentDoc}
`;
export type PayoutsTableComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PayoutsTableQuery,
    PayoutsTableQueryVariables
  >,
  "query"
> &
  (
    | { variables: PayoutsTableQueryVariables; skip?: boolean }
    | { skip: boolean });

export const PayoutsTableComponent = (props: PayoutsTableComponentProps) => (
  <ApolloReactComponents.Query<PayoutsTableQuery, PayoutsTableQueryVariables>
    query={PayoutsTableDocument}
    {...props}
  />
);

export type PayoutsTableProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PayoutsTableQuery,
  PayoutsTableQueryVariables
> &
  TChildProps;
export function withPayoutsTable<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PayoutsTableQuery,
    PayoutsTableQueryVariables,
    PayoutsTableProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PayoutsTableQuery,
    PayoutsTableQueryVariables,
    PayoutsTableProps<TChildProps>
  >(PayoutsTableDocument, {
    alias: "withPayoutsTable",
    ...operationOptions
  });
}
export type PayoutsTableQueryResult = ApolloReactCommon.QueryResult<
  PayoutsTableQuery,
  PayoutsTableQueryVariables
>;
export const PrepaymentsDocument = gql`
  query Prepayments($date: Int!) {
    prepayments(date: $date) {
      employee {
        ...Employee
      }
      currency {
        id
        codeName
        code
      }
      amount
    }
  }
  ${EmployeeFragmentDoc}
`;
export type PrepaymentsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PrepaymentsQuery,
    PrepaymentsQueryVariables
  >,
  "query"
> &
  (
    | { variables: PrepaymentsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const PrepaymentsComponent = (props: PrepaymentsComponentProps) => (
  <ApolloReactComponents.Query<PrepaymentsQuery, PrepaymentsQueryVariables>
    query={PrepaymentsDocument}
    {...props}
  />
);

export type PrepaymentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PrepaymentsQuery,
  PrepaymentsQueryVariables
> &
  TChildProps;
export function withPrepayments<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PrepaymentsQuery,
    PrepaymentsQueryVariables,
    PrepaymentsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PrepaymentsQuery,
    PrepaymentsQueryVariables,
    PrepaymentsProps<TChildProps>
  >(PrepaymentsDocument, {
    alias: "withPrepayments",
    ...operationOptions
  });
}
export type PrepaymentsQueryResult = ApolloReactCommon.QueryResult<
  PrepaymentsQuery,
  PrepaymentsQueryVariables
>;
export const RatesDocument = gql`
  query Rates($employeeId: Int!) {
    rates(employeeId: $employeeId) {
      ...ListItem
      writeHours
    }
    defaultRate(employeeId: $employeeId) {
      id
      writeHours
    }
  }
  ${ListItemFragmentDoc}
`;
export type RatesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<RatesQuery, RatesQueryVariables>,
  "query"
> &
  ({ variables: RatesQueryVariables; skip?: boolean } | { skip: boolean });

export const RatesComponent = (props: RatesComponentProps) => (
  <ApolloReactComponents.Query<RatesQuery, RatesQueryVariables>
    query={RatesDocument}
    {...props}
  />
);

export type RatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  RatesQuery,
  RatesQueryVariables
> &
  TChildProps;
export function withRates<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RatesQuery,
    RatesQueryVariables,
    RatesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    RatesQuery,
    RatesQueryVariables,
    RatesProps<TChildProps>
  >(RatesDocument, {
    alias: "withRates",
    ...operationOptions
  });
}
export type RatesQueryResult = ApolloReactCommon.QueryResult<
  RatesQuery,
  RatesQueryVariables
>;
export const StatesDocument = gql`
  query States {
    states {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type StatesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StatesQuery,
    StatesQueryVariables
  >,
  "query"
>;

export const StatesComponent = (props: StatesComponentProps) => (
  <ApolloReactComponents.Query<StatesQuery, StatesQueryVariables>
    query={StatesDocument}
    {...props}
  />
);

export type StatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StatesQuery,
  StatesQueryVariables
> &
  TChildProps;
export function withStates<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StatesQuery,
    StatesQueryVariables,
    StatesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StatesQuery,
    StatesQueryVariables,
    StatesProps<TChildProps>
  >(StatesDocument, {
    alias: "withStates",
    ...operationOptions
  });
}
export type StatesQueryResult = ApolloReactCommon.QueryResult<
  StatesQuery,
  StatesQueryVariables
>;
export const SubscribersDocument = gql`
  query Subscribers {
    subscribers {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type SubscribersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SubscribersQuery,
    SubscribersQueryVariables
  >,
  "query"
>;

export const SubscribersComponent = (props: SubscribersComponentProps) => (
  <ApolloReactComponents.Query<SubscribersQuery, SubscribersQueryVariables>
    query={SubscribersDocument}
    {...props}
  />
);

export type SubscribersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SubscribersQuery,
  SubscribersQueryVariables
> &
  TChildProps;
export function withSubscribers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscribersQuery,
    SubscribersQueryVariables,
    SubscribersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SubscribersQuery,
    SubscribersQueryVariables,
    SubscribersProps<TChildProps>
  >(SubscribersDocument, {
    alias: "withSubscribers",
    ...operationOptions
  });
}
export type SubscribersQueryResult = ApolloReactCommon.QueryResult<
  SubscribersQuery,
  SubscribersQueryVariables
>;
export const TasksDocument = gql`
  query Tasks {
    tasks {
      id
      employee {
        ...Employee
      }
      description
      isCompleted
    }
  }
  ${EmployeeFragmentDoc}
`;
export type TasksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<TasksQuery, TasksQueryVariables>,
  "query"
>;

export const TasksComponent = (props: TasksComponentProps) => (
  <ApolloReactComponents.Query<TasksQuery, TasksQueryVariables>
    query={TasksDocument}
    {...props}
  />
);

export type TasksProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TasksQuery,
  TasksQueryVariables
> &
  TChildProps;
export function withTasks<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TasksQuery,
    TasksQueryVariables,
    TasksProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TasksQuery,
    TasksQueryVariables,
    TasksProps<TChildProps>
  >(TasksDocument, {
    alias: "withTasks",
    ...operationOptions
  });
}
export type TasksQueryResult = ApolloReactCommon.QueryResult<
  TasksQuery,
  TasksQueryVariables
>;
export const TravelCostSupplierGroupsDocument = gql`
  query TravelCostSupplierGroups {
    travelCostSupplierGroups {
      ...ListItem
      travelCostSuppliers {
        ...ListItem
      }
    }
  }
  ${ListItemFragmentDoc}
`;
export type TravelCostSupplierGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TravelCostSupplierGroupsQuery,
    TravelCostSupplierGroupsQueryVariables
  >,
  "query"
>;

export const TravelCostSupplierGroupsComponent = (
  props: TravelCostSupplierGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    TravelCostSupplierGroupsQuery,
    TravelCostSupplierGroupsQueryVariables
  >
    query={TravelCostSupplierGroupsDocument}
    {...props}
  />
);

export type TravelCostSupplierGroupsProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  TravelCostSupplierGroupsQuery,
  TravelCostSupplierGroupsQueryVariables
> &
  TChildProps;
export function withTravelCostSupplierGroups<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TravelCostSupplierGroupsQuery,
    TravelCostSupplierGroupsQueryVariables,
    TravelCostSupplierGroupsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TravelCostSupplierGroupsQuery,
    TravelCostSupplierGroupsQueryVariables,
    TravelCostSupplierGroupsProps<TChildProps>
  >(TravelCostSupplierGroupsDocument, {
    alias: "withTravelCostSupplierGroups",
    ...operationOptions
  });
}
export type TravelCostSupplierGroupsQueryResult = ApolloReactCommon.QueryResult<
  TravelCostSupplierGroupsQuery,
  TravelCostSupplierGroupsQueryVariables
>;
export const TravelCostTypesDocument = gql`
  query TravelCostTypes {
    travelCostTypes {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type TravelCostTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TravelCostTypesQuery,
    TravelCostTypesQueryVariables
  >,
  "query"
>;

export const TravelCostTypesComponent = (
  props: TravelCostTypesComponentProps
) => (
  <ApolloReactComponents.Query<
    TravelCostTypesQuery,
    TravelCostTypesQueryVariables
  >
    query={TravelCostTypesDocument}
    {...props}
  />
);

export type TravelCostTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TravelCostTypesQuery,
  TravelCostTypesQueryVariables
> &
  TChildProps;
export function withTravelCostTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TravelCostTypesQuery,
    TravelCostTypesQueryVariables,
    TravelCostTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TravelCostTypesQuery,
    TravelCostTypesQueryVariables,
    TravelCostTypesProps<TChildProps>
  >(TravelCostTypesDocument, {
    alias: "withTravelCostTypes",
    ...operationOptions
  });
}
export type TravelCostTypesQueryResult = ApolloReactCommon.QueryResult<
  TravelCostTypesQuery,
  TravelCostTypesQueryVariables
>;
export const TravelOrderDocument = gql`
  query TravelOrder($id: Int!) {
    travelOrder(id: $id) {
      id
      name
      travelOrderRoutes {
        id
        dateTimeFrom
        dateTimeTo
        startLocation
        passThough
        finalLocation
        trailer
        distance
        travelCosts
        accommodationCosts
        otherNecessaryExpenditures
        currency {
          id
          code
        }
      }
    }
  }
`;
export type TravelOrderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TravelOrderQuery,
    TravelOrderQueryVariables
  >,
  "query"
> &
  (
    | { variables: TravelOrderQueryVariables; skip?: boolean }
    | { skip: boolean });

export const TravelOrderComponent = (props: TravelOrderComponentProps) => (
  <ApolloReactComponents.Query<TravelOrderQuery, TravelOrderQueryVariables>
    query={TravelOrderDocument}
    {...props}
  />
);

export type TravelOrderProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TravelOrderQuery,
  TravelOrderQueryVariables
> &
  TChildProps;
export function withTravelOrder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TravelOrderQuery,
    TravelOrderQueryVariables,
    TravelOrderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TravelOrderQuery,
    TravelOrderQueryVariables,
    TravelOrderProps<TChildProps>
  >(TravelOrderDocument, {
    alias: "withTravelOrder",
    ...operationOptions
  });
}
export type TravelOrderQueryResult = ApolloReactCommon.QueryResult<
  TravelOrderQuery,
  TravelOrderQueryVariables
>;
export const TravelOrderRouteOperationsDocument = gql`
  query TravelOrderRouteOperations {
    travelOrderRouteOperations {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type TravelOrderRouteOperationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TravelOrderRouteOperationsQuery,
    TravelOrderRouteOperationsQueryVariables
  >,
  "query"
>;

export const TravelOrderRouteOperationsComponent = (
  props: TravelOrderRouteOperationsComponentProps
) => (
  <ApolloReactComponents.Query<
    TravelOrderRouteOperationsQuery,
    TravelOrderRouteOperationsQueryVariables
  >
    query={TravelOrderRouteOperationsDocument}
    {...props}
  />
);

export type TravelOrderRouteOperationsProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  TravelOrderRouteOperationsQuery,
  TravelOrderRouteOperationsQueryVariables
> &
  TChildProps;
export function withTravelOrderRouteOperations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TravelOrderRouteOperationsQuery,
    TravelOrderRouteOperationsQueryVariables,
    TravelOrderRouteOperationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TravelOrderRouteOperationsQuery,
    TravelOrderRouteOperationsQueryVariables,
    TravelOrderRouteOperationsProps<TChildProps>
  >(TravelOrderRouteOperationsDocument, {
    alias: "withTravelOrderRouteOperations",
    ...operationOptions
  });
}
export type TravelOrderRouteOperationsQueryResult = ApolloReactCommon.QueryResult<
  TravelOrderRouteOperationsQuery,
  TravelOrderRouteOperationsQueryVariables
>;
export const TravelOrderRouteTypesDocument = gql`
  query TravelOrderRouteTypes {
    travelOrderRouteTypes {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type TravelOrderRouteTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TravelOrderRouteTypesQuery,
    TravelOrderRouteTypesQueryVariables
  >,
  "query"
>;

export const TravelOrderRouteTypesComponent = (
  props: TravelOrderRouteTypesComponentProps
) => (
  <ApolloReactComponents.Query<
    TravelOrderRouteTypesQuery,
    TravelOrderRouteTypesQueryVariables
  >
    query={TravelOrderRouteTypesDocument}
    {...props}
  />
);

export type TravelOrderRouteTypesProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  TravelOrderRouteTypesQuery,
  TravelOrderRouteTypesQueryVariables
> &
  TChildProps;
export function withTravelOrderRouteTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TravelOrderRouteTypesQuery,
    TravelOrderRouteTypesQueryVariables,
    TravelOrderRouteTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TravelOrderRouteTypesQuery,
    TravelOrderRouteTypesQueryVariables,
    TravelOrderRouteTypesProps<TChildProps>
  >(TravelOrderRouteTypesDocument, {
    alias: "withTravelOrderRouteTypes",
    ...operationOptions
  });
}
export type TravelOrderRouteTypesQueryResult = ApolloReactCommon.QueryResult<
  TravelOrderRouteTypesQuery,
  TravelOrderRouteTypesQueryVariables
>;
export const TravelOrdersDocument = gql`
  query TravelOrders($monthTimestamp: Int!) {
    travelOrders(monthTimestamp: $monthTimestamp) {
      id
      name
    }
  }
`;
export type TravelOrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TravelOrdersQuery,
    TravelOrdersQueryVariables
  >,
  "query"
> &
  (
    | { variables: TravelOrdersQueryVariables; skip?: boolean }
    | { skip: boolean });

export const TravelOrdersComponent = (props: TravelOrdersComponentProps) => (
  <ApolloReactComponents.Query<TravelOrdersQuery, TravelOrdersQueryVariables>
    query={TravelOrdersDocument}
    {...props}
  />
);

export type TravelOrdersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TravelOrdersQuery,
  TravelOrdersQueryVariables
> &
  TChildProps;
export function withTravelOrders<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TravelOrdersQuery,
    TravelOrdersQueryVariables,
    TravelOrdersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TravelOrdersQuery,
    TravelOrdersQueryVariables,
    TravelOrdersProps<TChildProps>
  >(TravelOrdersDocument, {
    alias: "withTravelOrders",
    ...operationOptions
  });
}
export type TravelOrdersQueryResult = ApolloReactCommon.QueryResult<
  TravelOrdersQuery,
  TravelOrdersQueryVariables
>;
export const TravelOrdersListDocument = gql`
  query TravelOrdersList($monthTimestamp: Int!) {
    travelOrders(monthTimestamp: $monthTimestamp) {
      id
      label
      cashReceipt
      employee {
        ...Employee
      }
      passengers {
        ...Employee
      }
      formation
      vehicle {
        id
        codeName
        vehicleMode {
          ...ListItem
        }
      }
    }
  }
  ${EmployeeFragmentDoc}
  ${ListItemFragmentDoc}
`;
export type TravelOrdersListComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TravelOrdersListQuery,
    TravelOrdersListQueryVariables
  >,
  "query"
> &
  (
    | { variables: TravelOrdersListQueryVariables; skip?: boolean }
    | { skip: boolean });

export const TravelOrdersListComponent = (
  props: TravelOrdersListComponentProps
) => (
  <ApolloReactComponents.Query<
    TravelOrdersListQuery,
    TravelOrdersListQueryVariables
  >
    query={TravelOrdersListDocument}
    {...props}
  />
);

export type TravelOrdersListProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TravelOrdersListQuery,
  TravelOrdersListQueryVariables
> &
  TChildProps;
export function withTravelOrdersList<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TravelOrdersListQuery,
    TravelOrdersListQueryVariables,
    TravelOrdersListProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TravelOrdersListQuery,
    TravelOrdersListQueryVariables,
    TravelOrdersListProps<TChildProps>
  >(TravelOrdersListDocument, {
    alias: "withTravelOrdersList",
    ...operationOptions
  });
}
export type TravelOrdersListQueryResult = ApolloReactCommon.QueryResult<
  TravelOrdersListQuery,
  TravelOrdersListQueryVariables
>;
export const UserHoursDocument = gql`
  query UserHours($date: Int!, $orders: [OrderInput!]) {
    userHours(date: $date, orders: $orders) {
      id
      order {
        ...ListItem
      }
      employee {
        ...Employee
      }
      rate {
        ...ListItem
      }
      hoursCount
      hoursFrom
      hoursTo
    }
  }
  ${ListItemFragmentDoc}
  ${EmployeeFragmentDoc}
`;
export type UserHoursComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UserHoursQuery,
    UserHoursQueryVariables
  >,
  "query"
> &
  ({ variables: UserHoursQueryVariables; skip?: boolean } | { skip: boolean });

export const UserHoursComponent = (props: UserHoursComponentProps) => (
  <ApolloReactComponents.Query<UserHoursQuery, UserHoursQueryVariables>
    query={UserHoursDocument}
    {...props}
  />
);

export type UserHoursProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserHoursQuery,
  UserHoursQueryVariables
> &
  TChildProps;
export function withUserHours<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserHoursQuery,
    UserHoursQueryVariables,
    UserHoursProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserHoursQuery,
    UserHoursQueryVariables,
    UserHoursProps<TChildProps>
  >(UserHoursDocument, {
    alias: "withUserHours",
    ...operationOptions
  });
}
export type UserHoursQueryResult = ApolloReactCommon.QueryResult<
  UserHoursQuery,
  UserHoursQueryVariables
>;
export const VehiclesDocument = gql`
  query Vehicles($order: OrderInput) {
    vehicles(order: $order) {
      id
      codeName
    }
  }
`;
export type VehiclesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    VehiclesQuery,
    VehiclesQueryVariables
  >,
  "query"
>;

export const VehiclesComponent = (props: VehiclesComponentProps) => (
  <ApolloReactComponents.Query<VehiclesQuery, VehiclesQueryVariables>
    query={VehiclesDocument}
    {...props}
  />
);

export type VehiclesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  VehiclesQuery,
  VehiclesQueryVariables
> &
  TChildProps;
export function withVehicles<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    VehiclesQuery,
    VehiclesQueryVariables,
    VehiclesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    VehiclesQuery,
    VehiclesQueryVariables,
    VehiclesProps<TChildProps>
  >(VehiclesDocument, {
    alias: "withVehicles",
    ...operationOptions
  });
}
export type VehiclesQueryResult = ApolloReactCommon.QueryResult<
  VehiclesQuery,
  VehiclesQueryVariables
>;
export const WeatherDocument = gql`
  query Weather {
    weather {
      ...ListItem
    }
  }
  ${ListItemFragmentDoc}
`;
export type WeatherComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WeatherQuery,
    WeatherQueryVariables
  >,
  "query"
>;

export const WeatherComponent = (props: WeatherComponentProps) => (
  <ApolloReactComponents.Query<WeatherQuery, WeatherQueryVariables>
    query={WeatherDocument}
    {...props}
  />
);

export type WeatherProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WeatherQuery,
  WeatherQueryVariables
> &
  TChildProps;
export function withWeather<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WeatherQuery,
    WeatherQueryVariables,
    WeatherProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WeatherQuery,
    WeatherQueryVariables,
    WeatherProps<TChildProps>
  >(WeatherDocument, {
    alias: "withWeather",
    ...operationOptions
  });
}
export type WeatherQueryResult = ApolloReactCommon.QueryResult<
  WeatherQuery,
  WeatherQueryVariables
>;
