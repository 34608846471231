import * as React from "react";
import {
  Layout,
  Icon,
  Avatar,
  Row,
  Col,
  Typography,
  Menu,
  Dropdown,
  Spin
} from "antd";
import { AuthContext, AuthContextState } from "../../contexts";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { Logo } from "../Logo";

export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = (
  props: HeaderProps
): React.ReactElement => {
  const { Header } = Layout;
  const { Text, Title } = Typography;
  const { setAuthenticated, setToken, user } = React.useContext<
    AuthContextState
  >(AuthContext);

  const theme: string = `dark`;

  return (
    <Header
      className={`no-print`}
      style={{
        background: theme === `dark` ? `#001529` : `#fff`,
        position: `fixed`,
        width: `100%`,
        zIndex: 997
      }}
    >
      <Row type={`flex`} justify={`start`} align={`middle`}>
        <Col md={{ span: 20 }} xs={{ span: 0 }}>
          <Link to={ROUTES.DASHBOARD}>
            <Logo
              theme={theme === `dark` ? `light` : `dark`}
              width={150}
              height={25}
            />
          </Link>
          {/* TODO: DEPLOY + make backup */}
          {/*<Text*/}
          {/*  style={{ color: theme === `dark` ? `#fff` : `#000` }}*/}
          {/*>| TESTOVACÍ VERZE</Text>*/}
        </Col>

        <Col lg={{ span: 4 }}>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => {
                    setAuthenticated(false);
                    setToken(null);
                    localStorage.removeItem(`token`);
                  }}
                >
                  <Link to={ROUTES.LOGIN}>
                    <Icon type={`logout`} />
                    {` Odhlásit se`}
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <a className="ant-dropdown-link" href="#">
              {user ? (
                <>
                  <Avatar
                    icon={`user`}
                    style={{
                      background: theme === `dark` ? `#002140` : `#e3e3e3`
                    }}
                  />

                  <Text
                    style={{ color: theme === `dark` ? `#fff` : `#000` }}
                  >{` ${user.fullName}`}</Text>
                </>
              ) : (
                <>
                  <Spin />
                  <Text
                    style={{
                      color: theme === `dark` ? `#fff` : `#000`,
                      marginLeft: 10
                    }}
                  >{`Načítání...`}</Text>
                </>
              )}
            </a>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};
