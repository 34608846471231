import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { CreateSubscriberForm } from "../../../forms";

export interface CreateSubscriberViewProps {}

export const CreateSubscriberView: React.FC<CreateSubscriberViewProps> = (
  props: CreateSubscriberViewProps
): React.ReactElement => {
  const title: string = `Vložení odběratele`;
  const { Title } = Typography;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <CreateSubscriberForm />
          </Col>
        </Row>
      </div>
    </>
  );
};
