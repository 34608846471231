import * as React from "react";
import { User, Notification } from "../../graphql/generated";

export interface AuthContextState {
  token: string | null;
  setToken: (token: string | null) => void;
  isAuthenticated: boolean;
  setAuthenticated: (value: boolean) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  dashboardNotifications: Notification[];
  setDashboardNotifications: (Notifications: Notification[]) => void;
}

const defaultValue: AuthContextState = {
  isAuthenticated: false,
  setAuthenticated: () => null,
  token: null,
  setToken: () => null,
  user: null,
  setUser: () => null,
  dashboardNotifications: [],
  setDashboardNotifications: () => null
};

export const AuthContext = React.createContext<AuthContextState>(defaultValue);

export const AuthContextProvider: React.FC<React.PropsWithChildren<unknown>> = (
  props: React.PropsWithChildren<unknown>
): React.ReactElement => {
  const { children } = props;
  const [token, setToken] = React.useState<string | null>(
    localStorage.getItem(`token`)
  );

  const [user, setUser] = React.useState<User | null>(null);

  const [isAuthenticated, setAuthenticated] = React.useState<boolean>(
    !!localStorage.getItem(`token`)
  );

  const [dashboardNotifications, setDashboardNotifications] = React.useState<
    Notification[]
  >([]);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        isAuthenticated,
        setAuthenticated,
        user,
        setUser,
        dashboardNotifications,
        setDashboardNotifications
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
