import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Row,
  Col,
  Table,
  Divider,
  Skeleton,
  Radio,
  Tooltip
} from "antd";
// TODO: TravelOrderFilterForm?
import { TravelOrderFilterForm } from "../../forms";
import { useQuery } from "react-apollo-hooks";
import {
  PavelsTableDocument,
  PavelsTableQuery,
  PavelsTableQueryVariables,
  PavelsTableRow
} from "../../graphql/generated";
import moment, { Moment } from "moment";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";

interface PavelsRow {
  key: number;
  employee: string;
  depositSum?: number | null;
  data: PavelsTableRow;
  totalHours: number;
  [key: string]: unknown;
}

export interface PavelsProps {}

type tab = "hours" | "rates";

export const Pavels: React.FC<PavelsProps> = (
  // TODO: remove props?
  props: PavelsProps
): React.ReactElement => {
  const title: string = `Pavlovka`;
  const { Title } = Typography;
  const { Button, Group } = Radio;

  const [date, setDate] = React.useState<number>(moment().unix());
  const [tab, setTab] = React.useState<tab>(`hours`);
  const _moment = moment.unix(date);

  const getMomentDaysInMonth = (arg: Moment): Moment[] => {
    const arr: Moment[] = [];
    const month = arg.month() + 1;
    const year = arg.year();
    const count: number = arg.daysInMonth();

    for (let i = 1; i <= count; i++) {
      const day: Moment = moment(`${i}.${month}.${year}`, `DD.MM.YYYY`);
      arr.push(day);
    }

    return arr;
  };

  const getDayColumns = (days: Moment[]): any[] => {
    const columns: any[] = days.map(day => ({
      title: day.format(`D dd`),
      dataIndex:
        tab === "hours" ? `hours_${day.unix()}` : `rates_${day.unix()}`,
      id: tab === "hours" ? `hours_${day.unix()}` : `rates_${day.unix()}`,
      width: 50,
      className: (day.day() === 0 || day.day() === 6) && `weekend`,
      render: (value: string, record: PavelsRow) => {
        console.log(record); // TODO: remove
        return (
          <Tooltip
            trigger={`hover`}
            title={
              tab === "hours"
                ? (record[`rates_${day.unix()}`] as string)
                : (record[`hours_${day.unix()}`] as string)
            }
          >
            <td>{value}</td>
          </Tooltip>
        );
      }
    }));

    return columns;
  };

  const pavels = useQuery<PavelsTableQuery, PavelsTableQueryVariables>(
    PavelsTableDocument,
    {
      variables: { monthTimestamp: date },
      fetchPolicy: `no-cache`
    }
  );

  const dataSource: PavelsRow[] = [];
  const columns = [
    {
      title: "Zaměstnanec",
      dataIndex: "employee",
      id: "employee",
      width: 107,
      fixed: "left",
    },
    {
      title: "Záloha",
      dataIndex: "depositSum",
      id: "depositSum",
      width: 62
    },
    ...getDayColumns(getMomentDaysInMonth(_moment))
  ];

  if (tab === "hours") {
    columns.push({
      title: "Celkem",
      dataIndex: "totalHours",
      id: "totalHours",
      width: 66,
      fixed: `right`
    });
  }

  if (pavels.data) {
    pavels.data.pavelsTable.map((item, key) => {
      const days: any = {};
      item.userHours.map(hours => {
        if (hours.date) {
          const date = moment.unix(hours.date);
          const newDate = moment(date.format(`DD.MM.YYYY`), `DD.MM.YYYY`);
          days[`hours_${newDate.unix()}`] = hours.hoursCount;
          days[`rates_${newDate.unix()}`] = hours.rate.name;
        }
      });

      dataSource.push({
        key,
        employee: item.employee.fullName,
        depositSum: item.depositSum,
        data: item as PavelsTableRow,
        totalHours: item.totalHours,
        ...days
      });
    });
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <TravelOrderFilterForm
              onDateChange={(date: Moment | null) => {
                if (date) {
                  setDate(date.unix());
                  pavels.refetch({ monthTimestamp: date.unix() });
                }
              }}
            />
          </Col>
        </Row>
        <Row
          type={`flex`}
          justify={`end`}
          align={`middle`}
          style={{ marginBottom: 30 }}
        >
          <Col>
            <Group
              onChange={e => setTab(e.target.value)}
              value={tab}
              buttonStyle="solid"
            >
              <Button value="hours">Hodiny</Button>
              <Button value="rates">Tarify</Button>
            </Group>
          </Col>
        </Row>
        {pavels.loading ? (
          <>
            <Skeleton active loading />
            <Skeleton active loading />
            <Skeleton active loading />
          </>
        ) : (
          <PavelsTableView
            key={tab}
            dataSource={dataSource}
            refetch={pavels.refetch}
            date={date}
            columns={columns}
          />
        )}
      </div>
    </>
  );
};

const PavelsTableView: React.FC<{
  dataSource: Array<PavelsRow>;
  refetch: () => void;
  date: number;
  columns: any[];
}> = props => {
  const { dataSource, refetch, date, columns } = props;

  return (
    <>
      {dataSource.length > 0 && (
        <Row>
          <Col>
            <PavelsTable dataSource={dataSource} columns={columns} />
          </Col>
        </Row>
      )}
    </>
  );
};

const PavelsTable: React.FC<{
  dataSource: PavelsRow[];
  columns: any[];
}> = props => {
  const { dataSource, columns } = props;
  const [data, setData] = React.useState<PavelsRow[]>(dataSource);

  // TODO: pushNotification remove?
  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  return (
    <Table
      size={`small`}
      bordered
      pagination={false}
      dataSource={data}
      columns={columns}
      scroll={{ x: true, y: 'calc(100vh - 250px)' }}
    />
  );
};
