import * as React from "react";
import { Form, DatePicker } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment, { Moment } from "moment";

export interface TravelOrderFilterFormProps extends FormComponentProps {
  onDateChange: (date: Moment | null) => void;
}

export const TravelOrderFilterForm = Form.create<TravelOrderFilterFormProps>({
  name: `TravelOrderFilterForm`
})(
  (props: TravelOrderFilterFormProps): React.ReactElement => {
    const { form, onDateChange } = props;
    const { getFieldDecorator } = form;
    const { MonthPicker } = DatePicker;

    return (
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(<MonthPicker format={`MM.YYYY (MMMM)`} onChange={onDateChange} />)}
        </Form.Item>
      </Form>
    );
  }
);
