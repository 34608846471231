import * as React from "react";
import { Form, Button, DatePicker, Select, InputNumber, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation, QueryHookResult } from "react-apollo-hooks";
import moment from "moment";
import {
  CreateTravelOrderRouteDocument,
  CreateTravelOrderRouteMutation,
  CreateTravelOrderRouteMutationVariables,
  Notification,
  TravelOrderQuery,
  TravelOrderQueryVariables,
  TravelOrderRouteOperationsQuery,
  TravelOrderRouteOperationsQueryVariables,
  TravelOrderRouteTypesQuery,
  TravelOrderRouteTypesQueryVariables,
  TravelOrdersQuery,
  TravelOrdersQueryVariables
} from "../../../../graphql/generated";
import {
  NotificationContext,
  NotificationContextState
} from "../../../../contexts/NotificationContext";
import { getValidateStatus } from "../../../../utils";

export interface TravelOrderRouteCreateFormProps extends FormComponentProps {
  travelOrder?: QueryHookResult<TravelOrderQuery, TravelOrderQueryVariables>;
  travelOrders: QueryHookResult<TravelOrdersQuery, TravelOrdersQueryVariables>;
  travelOrderRouteOperations: QueryHookResult<
    TravelOrderRouteOperationsQuery,
    TravelOrderRouteOperationsQueryVariables
  >;
  travelOrderRouteTypes: QueryHookResult<
    TravelOrderRouteTypesQuery,
    TravelOrderRouteTypesQueryVariables
  >;
}

export const TravelOrderRouteCreateForm = Form.create<
  TravelOrderRouteCreateFormProps
>({
  name: `TravelOrderRouteCreateForm`
})(
  (props: TravelOrderRouteCreateFormProps): React.ReactElement => {
    const {
      form,
      travelOrders,
      travelOrderRouteOperations,
      travelOrderRouteTypes,
      travelOrder
    } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      CreateTravelOrderRouteMutation,
      CreateTravelOrderRouteMutationVariables
    >(CreateTravelOrderRouteDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateTravelOrderRouteMutationVariables = {
            input: {
              dateTime: values.datetime.unix(),
              currentLocation: values.currentLocation,
              mileage: values.mileage,
              passThrough: values.passThrough,
              travelOrder: { id: values.travelOrder },
              travelOrderRouteOperation: {
                id: values.travelOrderRouteOperation
              },
              travelOrderRouteType: { id: values.travelOrderRouteType }
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (
            response &&
            response.data &&
            response.data.createTravelOrderRoute
          ) {
            response.data.createTravelOrderRoute.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        {travelOrder && (travelOrder.loading || travelOrder.data) ? (
          <Form.Item label={`Cestovní příkaz`}>
            {getFieldDecorator("travelOrder", {
              initialValue:
                travelOrder.data &&
                travelOrder.data.travelOrder &&
                travelOrder.data.travelOrder.id,
              rules: [
                {
                  required: true,
                  message: `Vyberte cestovní příkaz`
                }
              ]
            })(
              <Select
                placeholder={travelOrder.loading && `Načítání...`}
                loading={travelOrder.loading}
                disabled
              >
                {travelOrder.data && travelOrder.data.travelOrder && (
                  <Option
                    key={travelOrder.data.travelOrder.id}
                    value={travelOrder.data.travelOrder.id}
                  >
                    {travelOrder.data.travelOrder.name}
                  </Option>
                )}
              </Select>
            )}
          </Form.Item>
        ) : (
          <Form.Item label={`Cestovní příkaz`}>
            {getFieldDecorator("travelOrder", {
              rules: [
                {
                  required: true,
                  message: `Vyberte cestovní příkaz`
                }
              ]
            })(
              <Select
                placeholder={`Vyberte cestovní příkaz`}
                loading={travelOrders.loading}
                disabled={
                  !(
                    travelOrders.data &&
                    travelOrders.data.travelOrders.length > 0
                  )
                }
              >
                {travelOrders.data &&
                  travelOrders.data.travelOrders.map(travelOrder => (
                    <Option key={travelOrder.id} value={travelOrder.id}>
                      {travelOrder.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}

        <Form.Item label={`Operace`}>
          {getFieldDecorator("travelOrderRouteOperation", {
            rules: [
              {
                required: true,
                message: `Vyberte operaci`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte operaci`}
              loading={travelOrderRouteOperations.loading}
              disabled={
                !(
                  travelOrderRouteOperations.data &&
                  travelOrderRouteOperations.data.travelOrderRouteOperations
                    .length > 0
                )
              }
            >
              {travelOrderRouteOperations.data &&
                travelOrderRouteOperations.data.travelOrderRouteOperations.map(
                  travelOrder => (
                    <Option key={travelOrder.id} value={travelOrder.id}>
                      {travelOrder.name}
                    </Option>
                  )
                )}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Datum a čas">
          {getFieldDecorator("datetime", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum a čas"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY HH:mm`}
              showTime={{ format: `HH: mm` }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Typ cesty`}>
          {getFieldDecorator("travelOrderRouteType", {
            rules: [
              {
                required: true,
                message: `Vyberte typ cesty`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte typ cesty`}
              loading={travelOrderRouteTypes.loading}
              disabled={
                !(
                  travelOrderRouteTypes.data &&
                  travelOrderRouteTypes.data.travelOrderRouteTypes.length > 0
                )
              }
            >
              {travelOrderRouteTypes.data &&
                travelOrderRouteTypes.data.travelOrderRouteTypes.map(
                  travelOrder => (
                    <Option key={travelOrder.id} value={travelOrder.id}>
                      {travelOrder.name}
                    </Option>
                  )
                )}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Stav tachometru (km)">
          {getFieldDecorator("mileage", {
            rules: [
              {
                required: true,
                message: `Zadejte stav tachometru`
              }
            ]
          })(<InputNumber min={0} decimalSeparator={`,`} />)}
        </Form.Item>

        <Form.Item label={`Průjezd přes místo`}>
          {getFieldDecorator("passThrough")(<Input />)}
        </Form.Item>

        <Form.Item label={`Aktuální místo`}>
          {getFieldDecorator("currentLocation", {
            rules: [
              {
                required: true,
                message: `Zadejte aktuální místo`
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
