export enum ROUTES {
  DASHBOARD = "/",
  LOGIN = "/login",
  HOURS_WRITING = "/hours-writing",
  DAILY_LISTING = "/daily-listing",
  DAILY_REPORT = "/daily-report",
  MATERIAL_REPORT = "/material-report",
  ACCOMMODATION_REPORT = "/accommodation-report",
  TRAVEL_COSTS_REPORT = "/travel-costs-report",
  WORK_DESCRIPTION = "/work-description",
  PREPAYMENT = "/prepayment",
  PREPAYMENTS = "/prepayments",
  OPERATING_EXPENDITURE = "/operating-expenditure",
  TRAVEL_ORDER = "/travel-order",
  TRAVEL_ORDERS = "/travel-orders",
  TRAVEL_ORDER_ROUTE = "/travel-order-route",
  TRAVEL_ORDER_ROUTE_WITH_PARAMS = "/travel-order-route/:travelOrderId?",
  TASKS = "/tasks",
  CALENDAR = "/calendar",
  ACTIVITY_HISTORY = "/activity-history",
  EMPLOYEES_LOANS = "/employees-loans",
  CREATE_SUBSCRIBER = "/create-subscriber",
  CREATE_MATERIAL_SUPPLIER = "/create-material-supplier",
  CREATE_ORDER = "/create-order",
  PENALTY = "/penalty",
  DAILY_REPORTS = "/daily-reports",
  PAVELS = "/pavels",
  PAYOUTS = "/payouts",
  ECONOMICS = "/economics",
  ERROR = "/error"
}
