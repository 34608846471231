import * as React from "react";
import {
  Form,
  Button,
  InputNumber,
  TimePicker,
  DatePicker,
  Input,
  Select
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation } from "react-apollo-hooks";
import moment, { Moment } from "moment";
import {
  NotificationContext,
  NotificationContextState
} from "../../../../contexts/NotificationContext";
import {
  Notification,
  TravelOrderRoute,
  UpdateTravelOrderRouteDocument,
  UpdateTravelOrderRouteMutation,
  UpdateTravelOrderRouteMutationVariables
} from "../../../../graphql/generated";

export interface TravelOrderRouteEditFormProps extends FormComponentProps {
  travelOrderRoute: TravelOrderRoute;
  onSubmit: () => void;
}

export const TravelOrderRouteEditForm = Form.create<
  TravelOrderRouteEditFormProps
>({
  name: `TravelOrderRouteEditForm`
})(
  (props: TravelOrderRouteEditFormProps): React.ReactElement => {
    const { form, travelOrderRoute } = props;

    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;

    const { Option } = Select;

    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      UpdateTravelOrderRouteMutation,
      UpdateTravelOrderRouteMutationVariables
    >(UpdateTravelOrderRouteDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {

          const variables: UpdateTravelOrderRouteMutationVariables = {
            input: {
              id: travelOrderRoute.id,
              dateTimeFrom: values.dateTimeFrom && values.dateTimeFrom.unix(),
              dateTimeTo: values.dateTimeTo && values.dateTimeTo.unix(),
              startLocation: values.startLocation,
              passThough: values.passThough,
              finalLocation: values.finalLocation,
              trailer: !!values.trailer,
              distance: values.distance,
              travelCosts: values.travelCosts,
              accommodationCosts: values.accommodationCosts,
              otherNecessaryExpenditures: values.otherNecessaryExpenditures
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (
            response &&
            response.data &&
            response.data.updateTravelOrderRoute
          ) {
            response.data.updateTravelOrderRoute.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );
          }

          props.onSubmit();
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum a čas výjezdu">
          {getFieldDecorator("dateTimeFrom", {
            initialValue: moment.unix(travelOrderRoute.dateTimeFrom),
            rules: [
              {
                required: true,
                message: "Zadejte datum a čas výjezdu"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY HH:mm`}
              showTime={{ format: `HH: mm` }}
            />
          )}
        </Form.Item>

        <Form.Item label="Datum a čas příjezdu">
          {getFieldDecorator("dateTimeTo", {
            initialValue:
              travelOrderRoute.dateTimeTo &&
              moment.unix(travelOrderRoute.dateTimeTo)
          })(
            <DatePicker
              format={`DD.MM.YYYY HH:mm`}
              showTime={{ format: `HH: mm` }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Odkud`}>
          {getFieldDecorator("startLocation", {
            initialValue: travelOrderRoute.startLocation,
            rules: [
              {
                required: true,
                message: `Zadejte místo`
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Přes`}>
          {getFieldDecorator("passThrough", {
            initialValue: travelOrderRoute.passThough
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Kam`}>
          {getFieldDecorator("finalLocation", {
            initialValue: travelOrderRoute.finalLocation
          })(<Input />)}
        </Form.Item>

        <Form.Item label={`Přívěs`}>
          {getFieldDecorator("trailer", {
            initialValue: travelOrderRoute.trailer ? 1 : 0,
            rules: [
              {
                required: true,
                message: `Povinné pole`
              }
            ]
          })(
            <Select placeholder={`Vyberte hodnotu`}>
              <Option key={1} value={1}>
                {`Ano`}
              </Option>
              <Option key={0} value={0}>
                {`Ne`}
              </Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Vzdálenost (km)">
          {getFieldDecorator("distance", {
            initialValue: travelOrderRoute.distance
          })(<InputNumber min={0} decimalSeparator={`,`} />)}
        </Form.Item>

        <Form.Item label={`Jízdné (${travelOrderRoute.currency.code})`}>
          {getFieldDecorator("travelCosts", {
            initialValue: travelOrderRoute.travelCosts
          })(<InputNumber decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item label={`Nocležné (${travelOrderRoute.currency.code})`}>
          {getFieldDecorator("accommodationCosts", {
            initialValue: travelOrderRoute.accommodationCosts
          })(<InputNumber decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item label={`Nutné vedlejší výdaje (${travelOrderRoute.currency.code})`}>
          {getFieldDecorator("otherNecessaryExpenditures", {
            initialValue: travelOrderRoute.otherNecessaryExpenditures
          })(<InputNumber decimalSeparator={`,`} precision={2} />)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
