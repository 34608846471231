import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Divider, Row, Typography, Table } from "antd";
import { ActivityHistoryFilterForm } from "../../forms";

export const ActivityHistory: React.FC = (): React.ReactElement => {
  const title: string = `Log aktivit`;
  const { Title } = Typography;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <ActivityHistoryFilterForm />
          </Col>
        </Row>
        <ActivityHistoryTableView />
      </div>
    </>
  );
};

const ActivityHistoryTableView: React.FC = props => {
// const ActivityHistoryTableView: React.FC<{}> = props => {
  // const { dataSource, refetch, date } = props;
  // const [isDrawerVisible, setDrawerVisibility] = React.useState<boolean>(false);
  // const [activeItem, setActiveItem] = React.useState<DailyReport>();
  // const { isBroken } = React.useContext<ThemeContextState>(ThemeContext);
  //
  // const onClose = () => {
  //   setDrawerVisibility(false);
  //   setTimeout(() => setActiveItem(undefined), 500);
  // };

  return (
    <>
      <Row>
        <Col>
          <ActivityHistoryTable
            // dataSource={dataSource}
          />
        </Col>
      </Row>
    </>
  );
};

const ActivityHistoryTable: React.FC = () => {
// const ActivityHistoryTable: React.FC<{
  // dataSource: DailyReportRow[];
  // onEditClick: (item: DailyReport) => void;
// }> = (props: {
  // dataSource: DailyReportRow[];
  // onEditClick: (item: DailyReport) => void;
// }) => {
  // const { dataSource, onEditClick } = props;
  // const [data, setData] = React.useState<DailyReportRow[]>(dataSource);
  const data = [
    {
      'date': '15.11.2020',
      'time': '23:41',
      'user': 'Marek Hanuš',
      'action': 'Editace Tabulky',
    },
    {
      'date': '15.11.2020',
      'time': '23:38',
      'user': 'Marek Hanuš',
      'action': 'Zápis docházky',
    }
  ];

  const columns = [
    {
      title: "Datum",
      dataIndex: "date",
      id: "date" // TODO: datum a čas sloučit
    },
    {
      title: "Čas",
      dataIndex: "time",
      id: "time"
    },
    {
      title: "Uživatel",
      dataIndex: "user",
      id: "user"
    },
    {
      title: "Akce",
      dataIndex: "action",
      id: "action" // TODO: rename
    },
  ];

  return <Table dataSource={data} columns={columns} scroll={{ x: true }} />;
};
