import * as React from "react";
import {
  Form,
  Button,
  DatePicker,
  Select,
  TimePicker,
  InputNumber
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation, QueryHookResult } from "react-apollo-hooks";
import moment, { Moment } from "moment";
import {
  CreateUserHoursDocument,
  CreateUserHoursMutation,
  CreateUserHoursMutationVariables,
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables,
  Notification,
  OrdersQuery,
  OrdersQueryVariables,
  RatesDocument,
  RatesQuery,
  RatesQueryVariables
} from "../../graphql/generated";
import { getNumberFromMomentHours } from "../../utils";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext";

export interface HoursWritingFormProps extends FormComponentProps {
  orders: QueryHookResult<OrdersQuery, OrdersQueryVariables>;
  // employees: QueryHookResult<EmployeesQuery, EmployeesQueryVariables>;
}

export const HoursWritingForm = Form.create<HoursWritingFormProps>({
  name: `HoursWritingForm`
})(
  (props: HoursWritingFormProps): React.ReactElement => {
    // const { form, orders, employees } = props;
    const { form, orders } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;
    const { Option } = Select;
    const [writeHours, setWriteHours] = React.useState<boolean>(false);
    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [
      getEmployees,
      // { loading: employeesLoading, error: employeesError, data: employees2 }
      { loading: employeesLoading, error: employeesError, data: employees }
    ] = useMutation<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument);

    const [
      getRates,
      { loading: ratesLoading, error: ratesError, data: rates }
    ] = useMutation<RatesQuery, RatesQueryVariables>(RatesDocument, {
      update: (cache, response) => {
        if (
          response.data &&
          response.data.defaultRate &&
          response.data.defaultRate.writeHours
        ) {
          setWriteHours(true);
        }
      }
    });

    const [submit] = useMutation<
      CreateUserHoursMutation,
      CreateUserHoursMutationVariables
    >(CreateUserHoursDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const hoursFrom =
            values.from && getNumberFromMomentHours(values.from);

          const hoursTo = values.to && getNumberFromMomentHours(values.to);

          const variables: CreateUserHoursMutationVariables = {
            input: {
              date: values.date.unix(),
              employee: {
                id: values.employee
              },
              hoursFrom,
              hoursTo,
              order: {
                id: values.order
              },
              rate: {
                id: values.rate
              },
              hoursCount: values.hoursCount
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createUserHours) {
            response.data.createUserHours.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );
            resetFields();
            setWriteHours(false);
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [
              {
                required: true,
                message: "Zadejte datum"
              }
            ]
          })(
            <DatePicker
              format={`DD.MM.YYYY`}
              onChange={async (date: Moment | null) => {
                if (date) {
                  orders.refetch({ date: date.unix() });
                  // TODO: orderId: 1
                  // await getEmployees({ variables: { date: date.unix() } });
                }
              }}
            />
          )}
        </Form.Item>

        <Form.Item label={`Zakázka`}>
          {getFieldDecorator("order", {
            rules: [
              {
                required: true,
                message: `Vyberte zakázku`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zakázku`}
              loading={orders.loading}
              disabled={!(orders.data && orders.data.orders.length > 0)}
              onChange={async (orderId: number) => {
                await getEmployees({ variables: { date: moment().unix(), orderId } });
              }}
                // employees.refetch({ orderId });
                // await getEmployees({ variables: { date: date.unix(), orderId } });
            >
              {orders.data &&
                orders.data.orders.map(order => (
                  <Option key={order.id} value={order.id}>
                    {order.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Zaměstnanec`}>
          {getFieldDecorator("employee", {
            rules: [
              {
                required: true,
                message: `Vyberte zaměstnance`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte zaměstnance`}
              loading={employeesLoading}
              // disabled={!(employees.data && employees.data.employees.length > 0)}
              disabled={!(employees && employees.employees.length > 0)}
              onChange={async (employeeId: number) => {
                await getRates({ variables: { employeeId } });
              }}
            >
              {/*{employees.data &&*/}
              {/*  employees.data.employees.map(employee => (*/}
              {employees &&
                employees.employees.map(employee => (
                  <Option key={employee.id} value={employee.id}>
                    {employee.fullName}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={`Tarif`}>
          {getFieldDecorator("rate", {
            initialValue:
              getFieldValue(`employee`) && rates && rates.defaultRate.id,
            rules: [
              {
                required: true,
                message: `Vyberte tarif`
              }
            ]
          })(
            <Select
              placeholder={`Vyberte tarif`}
              loading={ratesLoading}
              disabled={!(rates && rates.rates.length > 0)}
              onChange={async (rateId: number, option: any) => {
                const { key } = option;

                if (rates && rates.rates[key]) {
                  setWriteHours(rates.rates[key].writeHours);
                }
              }}
            >
              {rates &&
                rates.rates.map((rate, index) => (
                  <Option key={index} value={rate.id}>
                    {rate.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        {writeHours && (
          <>
            <Form.Item label="Hodiny od">
              {getFieldDecorator("from", {
                initialValue: moment(`06:00`, `HH:mm`),
                rules: [
                  {
                    required: true,
                    message: `Zadejte čas`
                  }
                ]
              })(
                <TimePicker
                  minuteStep={30}
                  format={`HH:mm`}
                  placeholder={`Zadejte čas`}
                />
              )}
            </Form.Item>

            <Form.Item label="Počet hodin">
              {getFieldDecorator("hoursCount")(
                <InputNumber
                  min={0}
                  max={24}
                  step={0.5}
                  decimalSeparator={`,`}
                  precision={1}
                  onChange={value => {
                    const duration = moment.duration(value, `hours`);
                    const from: Moment = getFieldValue(`from`);
                    const to: Moment = moment(
                      `${duration.hours()}:${duration.minutes()}`,
                      `HH:mm`
                    );
                    const fromHours: number = from.hours();
                    const fromMinutes: number = from.minutes();
                    const toHours: number = to.hours();
                    const toMinutes: number = to.minutes();

                    const hours: number = fromHours + toHours;
                    const minutes: number = fromMinutes + toMinutes;
                    if (
                      value &&
                      value < 24 &&
                      hours >= 0 &&
                      hours < 24 &&
                      minutes >= 0 &&
                      minutes <= 60
                    ) {
                      if (minutes === 60) {
                        setFieldsValue({
                          to: moment(`${hours + 1}:00`, `HH:mm`)
                        });
                      } else {
                        setFieldsValue({
                          to: moment(`${hours}:${minutes}`, `HH:mm`)
                        });
                      }
                    } else {
                      setFieldsValue({
                        to: null
                      });
                    }
                  }}
                />
              )}
            </Form.Item>

            <Form.Item label="Hodiny do">
              {getFieldDecorator("to")(
                <TimePicker
                  minuteStep={30}
                  format={`HH:mm`}
                  placeholder={`Zadejte čas`}
                  onChange={time => {
                    let fromHours: number = 0;
                    let fromMinutes: number = 0;
                    let toHours: number = 0;
                    let toMinutes: number = 0;

                    const fromVal = getFieldValue(`from`);

                    if (fromVal) {
                      fromHours = parseInt(fromVal.format(`HH`));
                      fromMinutes = parseInt(fromVal.format(`mm`));
                    }
                    if (time) {
                      toHours = parseInt(time.format(`HH`));
                      toMinutes = parseInt(time.format(`mm`));
                    }

                    const hours = toHours - fromHours;
                    const minutes = toMinutes - fromMinutes;

                    if (hours > 0 || minutes > 0) {
                      const val = moment.duration(`${hours}:${minutes}`);

                      setFieldsValue({
                        [`hoursCount`]: val.asHours()
                      });
                    } else {
                      resetFields([`hoursCount`]);
                    }
                  }}
                />
              )}
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
