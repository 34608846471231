import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables,
  TravelCostSupplierGroupsDocument,
  TravelCostSupplierGroupsQuery,
  TravelCostSupplierGroupsQueryVariables,
  TravelCostTypesDocument,
  TravelCostTypesQuery,
  TravelCostTypesQueryVariables
} from "../../../graphql/generated";
import moment from "moment";
import { TravelCostReportForm } from "../../../forms";
export interface CreateTravelCostReportViewProps {}

export const CreateTravelCostReportView: React.FC<
  CreateTravelCostReportViewProps
> = (props: CreateTravelCostReportViewProps): React.ReactElement => {
  const title: string = `Výkaz cestovních nákladů`;
  const { Title } = Typography;
  const [date, setDate] = React.useState<number>(moment().unix());

  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    variables: { date }
  });

  const travelCostTypes = useQuery<
    TravelCostTypesQuery,
    TravelCostTypesQueryVariables
  >(TravelCostTypesDocument);

  const travelCostSupplierGroups = useQuery<
    TravelCostSupplierGroupsQuery,
    TravelCostSupplierGroupsQueryVariables
  >(TravelCostSupplierGroupsDocument);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <TravelCostReportForm
              orders={orders}
              travelCostTypes={travelCostTypes}
              travelCostSupplierGroups={travelCostSupplierGroups}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
