import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { HoursWritingForm } from "../../../forms/HoursWritingForm";
import { useQuery } from "react-apollo-hooks";
import {
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables,
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables
} from "../../../graphql/generated";
import moment from "moment";
import {
  NotificationContext,
  NotificationContextState
} from "../../../contexts";
import { CreateEmployeeLoanForm } from "../../../forms";
export interface CreateEmployeesLoansViewProps {}

export const CreateEmployeeLoanView: React.FC<CreateEmployeesLoansViewProps> = (
  props: CreateEmployeesLoansViewProps
): React.ReactElement => {
  const title: string = `Půjčky zaměstnancům`;
  const { Title } = Typography;

  const employees = useQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <CreateEmployeeLoanForm employees={employees} />
          </Col>
        </Row>
      </div>
    </>
  );
};
