import * as React from "react";
import { Form, Button, Input, Checkbox } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useMutation } from "react-apollo-hooks";
import {
  CreateSubscriberDocument,
  CreateSubscriberMutation,
  CreateSubscriberMutationVariables,
  Notification
} from "../../graphql/generated";
import {
  NotificationContext,
  NotificationContextState
} from "../../contexts/NotificationContext"; // TODO: Import can be shortened

export interface CreateSubscriberFormProps extends FormComponentProps {}

export const CreateSubscriberForm = Form.create<CreateSubscriberFormProps>({
  name: `CreateSubscriberForm`
})(
  (props: CreateSubscriberFormProps): React.ReactElement => {
    const { form } = props;
    const {
      getFieldDecorator,
      validateFields,
      getFieldValue,
      setFieldsValue,
      resetFields
    } = form;

    const { pushNotification } = React.useContext<NotificationContextState>(
      NotificationContext
    );

    const [submit] = useMutation<
      CreateSubscriberMutation,
      CreateSubscriberMutationVariables
    >(CreateSubscriberDocument);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();

      validateFields(async (err, values) => {
        if (!err) {
          const variables: CreateSubscriberMutationVariables = {
            input: {
              name: values.name,
              payerOfVAT: values.payerOfVAT
            }
          };

          const response = await submit({ variables }).catch(console.error);

          if (response && response.data && response.data.createSubscriber) {
            response.data.createSubscriber.notifications.map(
              (notification: Notification) => pushNotification(notification)
            );

            resetFields();
          }
        }
      });
    };

    return (
      <Form
        onSubmit={onSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Název odběratele">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                validator: (rule, value, callback) => {
                  if (!(value && value.trim().length > 0)) {
                    callback("Zadejte název odběratele");
                  } else {
                    callback();
                  }
                }
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Plátce DPH">
          {getFieldDecorator("payerOfVAT", { initialValue: true })(
            <Checkbox defaultChecked />
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
