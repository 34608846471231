import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import {
  MaterialSupplierGroupsDocument,
  MaterialSupplierGroupsQuery,
  MaterialSupplierGroupsQueryVariables,
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables
} from "../../../graphql/generated";
import moment from "moment";
import { MaterialReportForm } from "../../../forms";
export interface CreateMaterialReportViewProps {}

export const CreateMaterialReportView: React.FC<
  CreateMaterialReportViewProps
> = (props: CreateMaterialReportViewProps): React.ReactElement => {
  const title: string = `Výkaz materiálu`;
  const { Title } = Typography;

  const [date, setDate] = React.useState<number>(moment().unix());

  const orders = useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    variables: { date }
  });

  const materialSupplierGroups = useQuery<
    MaterialSupplierGroupsQuery,
    MaterialSupplierGroupsQueryVariables
  >(MaterialSupplierGroupsDocument);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <MaterialReportForm
              orders={orders}
              materialSupplierGroups={materialSupplierGroups}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
