import * as React from "react";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch
} from "react-router-dom";
import "./App.css";
import {
  CreateAccommodationReportView,
  CreateEmployeeLoanView,
  CreateMaterialReportView,
  CreateMaterialSupplierView,
  CreateOperatingExpenditureView,
  CreateOrderView,
  CreatePenaltyView,
  CreatePrepaymentView,
  CreateSubscriberView,
  CreateTravelCostReportView,
  CreateTravelOrderView,
  CreateWorkDescriptionReportView,
  DailyListing,
  DailyReport,
  DailyReports,
  Dashboard,
  HoursWriting,
  Login,
  TravelOrders,
  Pavels,
  Payouts,
  Economics,
  Tasks,
  Calendar,
  ActivityHistory,
  ListPrepaymentsView
} from "../views";
import { Button, Layout, Result, Spin } from "antd";
import { Footer, Header, Logo, MainMenu } from "../components";
import { ROUTES } from "../routes";
import { AuthContext, AuthContextState } from "../contexts";
import Helmet from "react-helmet";
import {
  CurrentLoggedUserDocument,
  CurrentLoggedUserQuery,
  CurrentLoggedUserQueryVariables,
  DashboardNotificationsDocument,
  DashboardNotificationsQuery,
  DashboardNotificationsQueryVariables,
  Permission
} from "../graphql/generated";
import { useQuery } from "react-apollo-hooks";
import { CreateTravelOrderRouteView } from "../views/TravelOrderRouteView";

export const App: React.FC = (): React.ReactElement => {
  const { Content } = Layout;
  const {
    isAuthenticated,
    setUser,
    user,
    setAuthenticated,
    setToken,
    setDashboardNotifications
  } = React.useContext<AuthContextState>(AuthContext);

  const { data, refetch } = useQuery<
    CurrentLoggedUserQuery,
    CurrentLoggedUserQueryVariables
  >(CurrentLoggedUserDocument);

  const { data: notifications, loading: notificationsLoading } = useQuery<
    DashboardNotificationsQuery,
    DashboardNotificationsQueryVariables
  >(DashboardNotificationsDocument, {
    skip: !isAuthenticated
  });

  if (!user && isAuthenticated) {
    refetch();

    if (data && data.currentLoggedUser) {
      setUser(data.currentLoggedUser);
    }
  }

  // TODO: kouknout na refreshování notifikací
  if (notifications) {
    setDashboardNotifications(notifications.dashboardNotifications);
  }

  const PrivateRoute = ({
    component,
    ...rest
  }: {
    component: any;
    path: ROUTES;
    exact?: boolean;
  }) => {
    const Component: any = component;

    return (
      <Route
        {...rest}
        render={(props: RouteComponentProps) => {
          if (isAuthenticated) {
            if (user) {
              const dashboard =
                props.location.pathname === ROUTES.DASHBOARD &&
                user.role.permissions.includes(Permission.Dashboard);
              const hoursWriting =
                props.location.pathname === ROUTES.HOURS_WRITING &&
                user.role.permissions.includes(Permission.HoursWriting);
              const dailyListing =
                props.location.pathname === ROUTES.DAILY_LISTING &&
                user.role.permissions.includes(Permission.DailyListing);
              const dailyReport =
                props.location.pathname === ROUTES.DAILY_REPORT &&
                user.role.permissions.includes(Permission.DailyReport);
              const materialReport =
                props.location.pathname === ROUTES.MATERIAL_REPORT &&
                user.role.permissions.includes(Permission.MaterialReport);
              const travelCostsReport =
                props.location.pathname === ROUTES.TRAVEL_COSTS_REPORT &&
                user.role.permissions.includes(Permission.TravelCostsReport);
              const accommodationReport =
                props.location.pathname === ROUTES.ACCOMMODATION_REPORT &&
                user.role.permissions.includes(Permission.AccommodationReport);
              const workDescription =
                props.location.pathname === ROUTES.WORK_DESCRIPTION &&
                user.role.permissions.includes(Permission.WorkDescription);
              const dailyReports =
                props.location.pathname === ROUTES.DAILY_REPORTS &&
                user.role.permissions.includes(Permission.DailyReports);
              const travelOrder =
                props.location.pathname === ROUTES.TRAVEL_ORDER &&
                user.role.permissions.includes(Permission.TravelOrder);
              const travelOrderRoute =
                props.location.pathname.includes(ROUTES.TRAVEL_ORDER_ROUTE) &&
                user.role.permissions.includes(Permission.TravelOrderRoute);
              const travelOrders =
                props.location.pathname === ROUTES.TRAVEL_ORDERS &&
                user.role.permissions.includes(Permission.TravelOrders);
              const tasks =
                props.location.pathname === ROUTES.TASKS &&
                user.role.permissions.includes(Permission.Tasks);
              const calendar =
                props.location.pathname === ROUTES.CALENDAR &&
                user.role.permissions.includes(Permission.Calendar);
              const activityHistory =
                props.location.pathname === ROUTES.ACTIVITY_HISTORY &&
                user.role.permissions.includes(Permission.ActivityHistory);
              const prepayment =
                props.location.pathname === ROUTES.PREPAYMENT &&
                user.role.permissions.includes(Permission.Prepayment);
              const prepayments =
                props.location.pathname === ROUTES.PREPAYMENTS &&
                user.role.permissions.includes(Permission.Prepayments);
              const operatingExpenditure =
                props.location.pathname === ROUTES.OPERATING_EXPENDITURE &&
                user.role.permissions.includes(Permission.OperatingExpenditure);
              const createOrder =
                props.location.pathname === ROUTES.CREATE_ORDER &&
                user.role.permissions.includes(Permission.CreateOrder);
              const createMaterialSupplier =
                props.location.pathname === ROUTES.CREATE_MATERIAL_SUPPLIER &&
                user.role.permissions.includes(
                  Permission.CreateMaterialSupplier
                );
              const createSubscriber =
                props.location.pathname === ROUTES.CREATE_SUBSCRIBER &&
                user.role.permissions.includes(Permission.CreateSubscriber);
              const employeesLoans =
                props.location.pathname === ROUTES.EMPLOYEES_LOANS &&
                user.role.permissions.includes(Permission.EmployeesLoans);
              const penalty =
                props.location.pathname === ROUTES.PENALTY &&
                user.role.permissions.includes(Permission.Penalty);
              const pavels =
                props.location.pathname === ROUTES.PAVELS &&
                user.role.permissions.includes(Permission.Pavels);
              const payouts =
                props.location.pathname === ROUTES.PAYOUTS &&
                user.role.permissions.includes(Permission.Payouts);
              const economics =
                props.location.pathname === ROUTES.ECONOMICS &&
                user.role.permissions.includes(Permission.Economics);

            // TODO: remove unnecessary (View suffix)
            // export const Economics: React.FC = (): React.ReactElement => {

              if (
                dashboard ||
                hoursWriting ||
                dailyListing ||
                dailyReport ||
                materialReport ||
                travelCostsReport ||
                accommodationReport ||
                workDescription ||
                dailyReports ||
                travelOrder ||
                travelOrderRoute ||
                travelOrders ||
                tasks ||
                calendar ||
                activityHistory ||
                prepayment ||
                prepayments ||
                operatingExpenditure ||
                createOrder ||
                createMaterialSupplier ||
                createSubscriber ||
                employeesLoans ||
                penalty ||
                pavels ||
                payouts ||
                economics
              ) {
                try {
                  return (
                    <Layout>
                      <Header />

                      <Layout style={{ overflow: `hidden` }}>
                        {isAuthenticated && <MainMenu />}
                        <Layout>
                          <Content
                            className={`MainSection`}
                            style={{
                              background: "#fff",
                              minHeight: `calc(100vh - 70px - 64px)`,
                              marginTop: 64
                            }}
                          >
                            <Component {...props} />
                          </Content>
                          <Footer />
                        </Layout>
                      </Layout>
                    </Layout>
                  );
                } catch (e) {
                  console.error(e);
                  setUser(null);
                  setAuthenticated(false);
                  setToken(null);
                  localStorage.clear();
                }
              }

              return (
                <Route
                  exact
                  component={() => {
                    return (
                      <Result
                        status={`403`}
                        title={`Nemáte oprávnění pro přístup na tuto stránku!`}
                        extra={
                          <Link to={ROUTES.LOGIN}>
                            <Button type="primary">{`Přihlásit se`}</Button>
                          </Link>
                        }
                      />
                    );
                  }}
                />
              );
            } else {
              return (
                <Layout>
                  <Content
                    style={{
                      width: `100vw`,
                      height: `100vh`,
                      display: `flex`,
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center"
                    }}
                  >
                    <Result
                      status={`info`}
                      icon={() => null}
                      extra={<Logo theme={`dark`} width={300} height={55} />}
                    />
                  </Content>
                </Layout>
              );
            }
          }

          if (props.location.pathname === ROUTES.DASHBOARD) {
            return (
              <Redirect
                to={{
                  pathname: ROUTES.LOGIN,
                  state: { from: props.location }
                }}
              />
            );
          }

          return (
            <Route
              exact
              component={() => {
                return (
                  <Result
                    status={`403`}
                    title={`Nemáte oprávnění pro přístup na tuto stránku!`}
                    extra={
                      <Link to={ROUTES.LOGIN}>
                        <Button type="primary">{`Přihlásit se`}</Button>
                      </Link>
                    }
                  />
                );
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <Router>
      <Helmet defaultTitle={`Adam Epox`} titleTemplate="%s - Adam Epox" />

      <Switch>
        <Route
          exact
          path={ROUTES.LOGIN}
          component={() => <Login isAuthenticated={isAuthenticated} />}
        />

        <Route
          exact
          path={ROUTES.ERROR}
          component={() => (
            <Result
              status={`500`}
              title={`Oops... Něco se pokazilo :(`}
              subTitle={`Prosím, Kontaktujte administrátora.`}
              extra={
                <Link to={ROUTES.DASHBOARD}>
                  <Button type="primary">{`Zpět na hlavní stránku`}</Button>
                </Link>
              }
            />
          )}
        />
        <PrivateRoute
          exact
          path={ROUTES.DASHBOARD}
          component={() => (
            <Dashboard
              user={user}
              notificationsLoading={notificationsLoading}
            />
          )}
        />
        <PrivateRoute
          exact
          path={ROUTES.HOURS_WRITING}
          component={() => <HoursWriting />}
        />
        <PrivateRoute
          exact
          path={ROUTES.DAILY_LISTING}
          component={() => <DailyListing />}
        />
        <PrivateRoute
          exact
          path={ROUTES.DAILY_REPORT}
          component={() => <DailyReport />}
        />
        <PrivateRoute
          exact
          path={ROUTES.DAILY_REPORTS}
          component={() => <DailyReports />}
        />
        <PrivateRoute
          exact
          path={ROUTES.MATERIAL_REPORT}
          component={() => <CreateMaterialReportView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.TRAVEL_COSTS_REPORT}
          component={() => <CreateTravelCostReportView />}
        />
        <PrivateRoute
          exact
          path={ROUTES.ACCOMMODATION_REPORT}
          component={() => <CreateAccommodationReportView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.PREPAYMENT}
          component={() => <CreatePrepaymentView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.PREPAYMENTS}
          component={() => <ListPrepaymentsView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.OPERATING_EXPENDITURE}
          component={() => <CreateOperatingExpenditureView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.WORK_DESCRIPTION}
          component={() => <CreateWorkDescriptionReportView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.TRAVEL_ORDER}
          component={() => <CreateTravelOrderView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.TRAVEL_ORDERS}
          component={() => <TravelOrders />}
        />

        <PrivateRoute
          exact
          path={ROUTES.TRAVEL_ORDER_ROUTE_WITH_PARAMS}
          component={() => <CreateTravelOrderRouteView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.CREATE_ORDER}
          component={() => <CreateOrderView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.EMPLOYEES_LOANS}
          component={() => <CreateEmployeeLoanView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.PENALTY}
          component={() => <CreatePenaltyView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.CREATE_MATERIAL_SUPPLIER}
          component={() => <CreateMaterialSupplierView />}
        />
        <PrivateRoute
          exact
          path={ROUTES.CREATE_SUBSCRIBER}
          component={() => <CreateSubscriberView />}
        />

        <PrivateRoute
          exact
          path={ROUTES.TRAVEL_ORDERS}
          component={() => <TravelOrders />}
        />

        <PrivateRoute
          exact
          path={ROUTES.TASKS}
          component={() => <Tasks />}
        />

        <PrivateRoute
          exact
          path={ROUTES.CALENDAR}
          component={() => <Calendar />}
        />

        <PrivateRoute
          exact
          path={ROUTES.ACTIVITY_HISTORY}
          component={() => <ActivityHistory />}
        />

        <PrivateRoute
          exact
          path={ROUTES.PAVELS}
          component={() => <Pavels />}
        />

        <PrivateRoute
          exact
          path={ROUTES.PAYOUTS}
          component={() => <Payouts />}
        />

        <PrivateRoute
          exact
          path={ROUTES.ECONOMICS}
          component={() => <Economics />}
        />

        <Route
          exact
          component={() => {
            return (
              <Result
                status={`404`}
                title={`Stránka nenalezena`}
                subTitle={`Omlouváme se, ale stránka neexistuje.`}
                extra={
                  <Link to={ROUTES.DASHBOARD}>
                    <Button type="primary">{`Zpět na hlavní stránku`}</Button>
                  </Link>
                }
              />
            );
          }}
        />
      </Switch>
    </Router>
  );
};
