import * as React from "react";
import {
  Row,
  Col,
  Typography,
  Skeleton,
  Divider,
  Table,
  Tooltip,
  Button,
  Icon,
  Drawer,
  Descriptions
} from "antd";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  NotificationContext,
  NotificationContextState
} from "../../../contexts/NotificationContext";
import {
  CloseTravelOrderDocument,
  CloseTravelOrderMutation,
  CloseTravelOrderMutationVariables,
  DeleteTravelOrderRouteDocument,
  DeleteTravelOrderRouteMutation,
  DeleteTravelOrderRouteMutationVariables,
  Notification,
  TravelOrder,
  TravelOrderDocument,
  TravelOrderQuery,
  TravelOrderQueryVariables,
  TravelOrderRoute
} from "../../../graphql/generated";
import { PopConfirm } from "../../../components/PopConfirm";
import {
  ThemeContext,
  ThemeContextState
} from "../../../contexts/ThemeContext";
import { TravelOrderRouteEditForm } from "../Route/Edit";
import moment from "moment";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";

export interface TravelOrderEditFormProps {
  travelOrder: TravelOrder;
}

interface TravelOrderRouteRow {
  id: number;
  dateTimeFrom: string;
  dateTimeTo?: string;
  startLocation: string;
  passThough?: string;
  finalLocation?: string;
  trailer: string;
  distance: string;
  travelCosts: string;
  accommodationCosts: string;
  otherNecessaryExpenditures: string;
  data: TravelOrderRoute;
}

export const TravelOrderEditForm: React.FC<TravelOrderEditFormProps> = (
  props: TravelOrderEditFormProps
): React.ReactElement => {
  const { travelOrder } = props;
  const { Text } = Typography;
  const { isBroken } = React.useContext<ThemeContextState>(ThemeContext);
  const [isDrawerVisible, setDrawerVisibility] = React.useState<boolean>(false);
  const [activeItem, setActiveItem] = React.useState<TravelOrderRoute>();

  const { pushNotification } = React.useContext<NotificationContextState>(
    NotificationContext
  );

  let travelOrderRoute:
    | { dataSource: TravelOrderRouteRow[]; columns: any[] }
    | undefined;

  const { data, loading, refetch } = useQuery<
    TravelOrderQuery,
    TravelOrderQueryVariables
  >(TravelOrderDocument, {
    variables: { id: travelOrder.id }
  });

  const [deleteTravelOrderRoute] = useMutation<
    DeleteTravelOrderRouteMutation,
    DeleteTravelOrderRouteMutationVariables
  >(DeleteTravelOrderRouteDocument);

  const [closeTravelOrder] = useMutation<
    CloseTravelOrderMutation,
    CloseTravelOrderMutationVariables
  >(CloseTravelOrderDocument);

  if (data && data.travelOrder) {
    travelOrderRoute = {
      dataSource: data.travelOrder.travelOrderRoutes.map(
        (item: any, key: number) => ({
          key,
          id: item.id,
          dateTimeFrom: moment
            .unix(item.dateTimeFrom)
            .format(`DD.MM.YYYY HH:mm`),
          dateTimeTo: item.dateTimeTo
            ? moment.unix(item.dateTimeTo).format(`DD.MM.YYYY HH:mm`)
            : ``,
          startLocation: item.startLocation,
          passThough: item.passThough,
          finalLocation: item.finalLocation,
          trailer: item.trailer ? `Ano` : `Ne`,
          distance: item.distance ? `${item.distance} km` : ``,
          travelCosts: item.travelCosts
            ? `${item.travelCosts} ${item.currency.code}`
            : ``,
          accommodationCosts: item.accommodationCosts
            ? `${item.accommodationCosts} ${item.currency.code}`
            : ``,
          otherNecessaryExpenditures: item.otherNecessaryExpenditures
            ? `${item.otherNecessaryExpenditures} ${item.currency.code}`
            : ``,
          data: item
        })
      ),
      columns: [
        {
          title: "Datum a čas výjezdu",
          dataIndex: "dateTimeFrom",
          id: "dateTimeFrom"
        },
        {
          title: "Datum a čas příjezdu",
          dataIndex: "dateTimeTo",
          id: "dateTimeTo"
        },
        {
          title: "Odkud",
          dataIndex: "startLocation",
          id: "startLocation"
        },
        {
          title: "Přes",
          dataIndex: "passThough",
          id: "passThough"
        },
        {
          title: "Kam",
          dataIndex: "finalLocation",
          id: "finalLocation"
        },
        {
          title: "Přívěs",
          dataIndex: "trailer",
          id: "trailer"
        },
        {
          title: "Vzdálenost",
          dataIndex: "distance",
          id: "distance"
        },
        {
          title: "Jízdné",
          dataIndex: "travelCosts",
          id: "travelCosts"
        },
        {
          title: "Nocležné",
          dataIndex: "accommodationCosts",
          id: "accommodationCosts"
        },
        {
          title: "Nutné vedlejší výdaje",
          dataIndex: "otherNecessaryExpenditures",
          id: "otherNecessaryExpenditures"
        },
        {
          title: "Akce",
          key: "action",
          align: `right`,
          className: `column__text--right`,
          render: (text: string, record: TravelOrderRouteRow) => {
            return (
              <span>
                <Tooltip trigger={`hover`} title={`Editovat`}>
                  <Button
                    type={"primary"}
                    size={`small`}
                    onClick={() => {
                      setActiveItem(record.data);
                      setDrawerVisibility(true);
                    }}
                  >
                    <Icon type="edit" />
                  </Button>
                </Tooltip>

                <Divider type={`vertical`} />
                <PopConfirm
                  onConfirm={async () => {
                    const response = await deleteTravelOrderRoute({
                      variables: {
                        input: {
                          id: record.id
                        }
                      }
                    });

                    if (
                      response &&
                      response.data &&
                      response.data.deleteTravelOrderRoute
                    ) {
                      if (response.data.deleteTravelOrderRoute.status) {
                        refetch();
                      }

                      response.data.deleteTravelOrderRoute.notifications.map(
                        (notification: Notification) =>
                          pushNotification(notification)
                      );
                    }
                  }}
                >
                  <Tooltip trigger={`hover`} title={`Odstranit`}>
                    <Button type={"danger"} size={`small`}>
                      <Icon type="delete" />
                    </Button>
                  </Tooltip>
                </PopConfirm>
              </span>
            );
          }
        }
      ]
    };
  }

  return (
    <>
      <>
        <Row>
          <Col>
            <Descriptions column={{ lg: 4, xs: 2 }}>
              <Descriptions.Item label={<Text strong>{`Označení`}</Text>}>
                <Text>{travelOrder.label}</Text>
              </Descriptions.Item>

              <Descriptions.Item
                label={<Text strong>{`Pokladní doklad`}</Text>}
              >
                <Text> {travelOrder.cashReceipt}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Zaměstnanec`}</Text>}>
                <Text> {travelOrder.employee.fullName}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Spolucestující`}</Text>}>
                <Text>
                  {travelOrder.passengers
                    .map(passenger => passenger.fullName)
                    .join(`, `)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Útvar`}</Text>}>
                <Text> {travelOrder.formation}</Text>
              </Descriptions.Item>
              <Descriptions.Item
                label={<Text strong>{`Dopravní prostředek`}</Text>}
              >
                <Text> {travelOrder.vehicle.codeName}</Text>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{`Režim`}</Text>}>
                <Text> {travelOrder.vehicle.vehicleMode.name}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </>

      <Divider />

      <Row>
        <Col>
          {loading && <Skeleton active loading />}
          {travelOrderRoute && (
            <Table
              dataSource={travelOrderRoute.dataSource}
              columns={travelOrderRoute.columns}
              scroll={{ x: true }}
              pagination={false}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col>
          <Link to={`${ROUTES.TRAVEL_ORDER_ROUTE}/${travelOrder.id}`}>
            <Button type={`primary`}>{`Přidat cestu`}</Button>
          </Link>
          <Divider type={"vertical"} />
          <Button
            href={`/documents/travel_orders/get/${travelOrder.id}`}
            target={`_blank`}
          >
            <Icon type="download" />
            {`Stáhnout cestovní příkaz`}
          </Button>{" "}
          <Divider type={"vertical"} />
          <Button
            type={`danger`}
            onClick={async () => {
              const response = await closeTravelOrder({
                variables: { input: { id: travelOrder.id } }
              });

              if (response && response.data && response.data.closeTravelOrder) {
                response.data.closeTravelOrder.notifications.map(
                  (notification: Notification) => pushNotification(notification)
                );
              }
            }}
          >{`Uzavřít`}</Button>
        </Col>
      </Row>

      <Drawer
        title="Editace cesty"
        placement="right"
        closable
        onClose={() => setDrawerVisibility(false)}
        visible={isDrawerVisible}
        width={isBroken ? window.innerWidth : 420}
      >
        {activeItem && (
          <TravelOrderRouteEditForm
            onSubmit={() => {
              refetch();
              setDrawerVisibility(false);
            }}
            travelOrderRoute={activeItem}
          />
        )}
      </Drawer>
    </>
  );
};
