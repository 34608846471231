import * as React from "react";
import { Form, Button, DatePicker, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";

export const ActivityHistoryFilterForm = Form.create<FormComponentProps>({})(
  (): React.ReactElement => {
    return (
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 0 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 8 }
        }}
      >
        <Form.Item label="Datum">
          <DatePicker
            format={`DD.MM.YYYY`}
          />
        </Form.Item>

        <Form.Item label={`Zaměstnanec`}>
          <Select
            placeholder={`Vyberte zaměstnance`}
          >
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {`Zapsat`}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
