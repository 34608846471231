import * as React from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Typography, Divider } from "antd";
import { useQuery } from "react-apollo-hooks";
import { TravelOrderRouteCreateForm } from "../../../forms";
import {
  TravelOrderDocument,
  TravelOrderQuery,
  TravelOrderQueryVariables,
  TravelOrderRouteOperationsDocument,
  TravelOrderRouteOperationsQuery,
  TravelOrderRouteOperationsQueryVariables,
  TravelOrderRouteTypesDocument,
  TravelOrderRouteTypesQuery,
  TravelOrderRouteTypesQueryVariables,
  TravelOrdersDocument,
  TravelOrdersQuery,
  TravelOrdersQueryVariables
} from "../../../graphql/generated";
import moment from "moment";
import { withRouter, RouteComponentProps } from "react-router-dom";
export interface CreateTravelOrderRouteViewProps extends RouteComponentProps {}

export const CreateTravelOrderRouteView = withRouter(
  (props: CreateTravelOrderRouteViewProps): React.ReactElement => {
    const { match } = props;
    const { params } = match;
    const title: string = `Zápis cesty`;
    const { Title } = Typography;

    const travelOrder = useQuery<TravelOrderQuery, TravelOrderQueryVariables>(
      TravelOrderDocument,
      {
        variables: { id: (params as any).travelOrderId },
        skip: !(params && (params as any).travelOrderId)
      }
    );

    const travelOrders = useQuery<
      TravelOrdersQuery,
      TravelOrdersQueryVariables
    >(TravelOrdersDocument, {
      variables: { monthTimestamp: moment().unix() },
      skip: !!(params && (params as any).travelOrderId) && !travelOrder.error
    });

    const travelOrderRouteOperations = useQuery<
      TravelOrderRouteOperationsQuery,
      TravelOrderRouteOperationsQueryVariables
    >(TravelOrderRouteOperationsDocument);

    const travelOrderRouteTypes = useQuery<
      TravelOrderRouteTypesQuery,
      TravelOrderRouteTypesQueryVariables
    >(TravelOrderRouteTypesDocument);

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div>
          <Row>
            <Col>
              <Title>{title}</Title> <Divider />
            </Col>
          </Row>
          <Row>
            <Col>
              <TravelOrderRouteCreateForm
                  travelOrder={travelOrder}
                travelOrders={travelOrders}
                travelOrderRouteOperations={travelOrderRouteOperations}
                travelOrderRouteTypes={travelOrderRouteTypes}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
);
