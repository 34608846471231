import * as React from "react";
import { Col, Divider, Row, Typography, Calendar as CalendarComponent, Badge, Alert, Drawer, Button } from "antd";
import { Helmet } from "react-helmet";
import moment, { Moment } from "moment";
import { ThemeContext, ThemeContextState } from "../../contexts/ThemeContext";
import { CreateCalendarEventForm } from "../../forms";
import { useQuery } from "react-apollo-hooks";
import {
  EventsDocument,
  EventsQuery,
  EventsQueryVariables,
  Event as EventAttribute
} from "../../graphql/generated";
import {UpdateCalendarEventForm} from "../../forms/UpdateCalendarEventForm";

interface EventRow {
  key: number;
  id: number;
  dateTimeFrom: number;
  dateTimeTo: number;
  description: string;
  guests: any;
  data: EventAttribute;
}

export const Calendar: React.FC = (): React.ReactElement => {
  const title: string = `Kalendář`;
  const { Title } = Typography;

  const [isDrawerVisible, setDrawerVisibility] = React.useState<boolean>(false);
  const [isDrawer2Visible, setDrawer2Visibility] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<number>(moment().unix());
  const [values, setValues] = React.useState<any>(moment().unix());
  const { isBroken } = React.useContext<ThemeContextState>(ThemeContext);

  const events = useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    {
      variables: { monthTimestamp: date },
      fetchPolicy: `no-cache`
    }
  );

  const dataSource: EventRow[] = [];

  if (events.data) {
    events.data.events.map((item, key) =>
      dataSource.push({
        key,
        // key: item.id,
        id: item.id,
        dateTimeFrom: item.dateTimeFrom,
        dateTimeTo: item.dateTimeTo,
        description: item.description,
        guests: item.guests,
        data: item as EventAttribute
      })
    );
  }

  const getListData = (value: Moment) => {
    let listData: any[] = []; // TODO: type
    // listData = [
    //   { type: 'warning', content: 'Poslat fakturu za Adam epox IS SLA' },
    // ];

    dataSource.forEach(element => {
      // console.log(moment.unix(element.dateTimeFrom).format('l'));
      // console.log(value.format('l'));
      // console.log(element.dateTimeTo);
      if (
        (element.dateTimeFrom <= value.unix() && value.unix() <= element.dateTimeTo) ||
        moment.unix(element.dateTimeFrom).format('l') == value.format('l')
        // moment.unix(element.dateTimeTo).format('l') == value.format('l')
      ) {
          let guests
          if (element.guests.length > 0) {
            guests = ' (' + element.guests.map((g: any) => {return g.firstName}).join(',') + ')'
          } else {
            guests = ''
          }
        if (moment.unix(element.dateTimeFrom).format('LT') == '1:00' && moment.unix(element.dateTimeTo).format('LT') == '1:00')
          listData.push(
            // TODO: time
            { type: 'success', content: element.description + guests
, 'dateTimeFrom': element.dateTimeFrom, 'dateTimeTo': element.dateTimeTo, 'description': element.description, 'id': element.id, 'guests': element.guests,
            }
          )
        else
          listData.push(
            // TODO: time
            { type: 'success', content: moment.unix(element.dateTimeFrom).format('LT') + '–' + moment.unix(element.dateTimeTo).format('LT') + ' ' + element.description + guests
              , 'dateTimeFrom': element.dateTimeFrom, 'dateTimeTo': element.dateTimeTo, 'description': element.description, 'id': element.id, 'guests': element.guests,
            }
          )
      }
      console.log(listData);
    })
    // switch (value.date()) {
    //   case 18:
    //     listData = [
    //       { type: 'error', content: 'Daňové přiznání' },
    //     ];
    //     break;
    //   case 21:
    //     listData = [
    //       { type: 'warning', content: 'Poslat fakturu za Adam epox IS SLA' },
    //     ];
    //     break;
    //   case 23:
    //     listData = [
    //       { type: 'success', content: '8.00–9.00 Plánovaný výpadek O2' },
    //     ];
    //     break;
    //   default:
    // }
    return listData || [];
  }

  const dateCellRender = (value: Moment) => {
    const listData = getListData(value);

    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.content}>
            {/*<a href="#" onClick={() => {*/}
            <a onClick={() => {
              setValues(item);
              setDrawer2Visibility(true);
            }}>
              <Badge status={"default"} text={item.content} />
            </a>
          </li>
        ))}
      </ul>
    );
  }

  const onSelect = (value: Moment | undefined) => {
    setDate(value ? value.unix() : moment().unix());
  };

  const onPanelChange = (value: Moment | undefined) => {
    setDate(value ? value.unix() : moment().unix());
  };

  const onClose = () => {
    setDrawerVisibility(false);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Row>
          <Col>
            <Title>{title}</Title> <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert message={
              <>
                Vybrali jste datum: {date && moment.unix(date).format('D.M.YYYY')}&nbsp;&nbsp;
                <Button type={"primary"} onClick={() => setDrawerVisibility(true)} size={`small`}>Vytvořit událost</Button>
              </>
            } />
            <CalendarComponent className={`calendar`} value={moment.unix(date)} onSelect={onSelect} onPanelChange={onPanelChange} dateCellRender={dateCellRender} />
          </Col>
        </Row>
      </div>
      <Drawer
        title="Nová událost"
        placement="right"
        closable
        onClose={onClose}
        visible={isDrawerVisible}
        width={isBroken ? window.innerWidth : 500}
      >
        <CreateCalendarEventForm date={date} />
      </Drawer>
      <Drawer
        title="Editovat událost"
        placement="right"
        closable
        onClose={onClose}
        visible={isDrawer2Visible}
        width={isBroken ? window.innerWidth : 500}
      >
        {values && (
          <UpdateCalendarEventForm item={values} />
        )}
      </Drawer>
    </>
  );
};
