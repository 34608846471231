import moment, { Moment } from "moment";

export const getValidateStatus = ({
  loading,
  error
}: {
  loading: boolean;
  error: boolean;
}): "validating" | "error" | "" => {
  if (loading) {
    return `validating`;
  } else if (error) {
    return `error`;
  }

  return ``;
};

export const getNumberFromMomentHours = (obj: Moment): number =>
  moment.duration(obj.format(`HH:mm`)).asHours();

export const getHoursAndMinutesFromNumber = (number: number): string => {
  const duration = moment.duration(number, `hours`);
  return moment(`${duration.hours()}:${duration.minutes()}`,`HH:mm`).format(`HH:mm`);
};

/**
 * Convert plain text links to clickable links.
 *
 * @remarks
 * Source: https://stackoverflow.com/a/49634926/14548073
 *
 * @param inputText
 * @returns Text with HTML links
 */
export const linkify = (inputText: string): string => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

export const createMarkup = (value: string): {__html: string} => {
  return {__html: value};
}

export const formatLargeNumbers = (num: number, fractionDigits: number): string => {
  return num.toFixed(fractionDigits).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
